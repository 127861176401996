import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers/AuthProvider";
import NetZeroChart from "./NetZeroChart";
import ReductionStrategies from "./ReductionStrategies";
import axios from "axios";
import Modal from "../Containers/Modal";
import { ReactComponent as BarGraphArrowIcon } from "./BarGraphArrow.svg";
import { ReactComponent as ContractIcon } from "../../assets/Contract.svg";
import { ReactComponent as ExpandIcon } from "../../assets/Expand.svg";

import Switch from "@mui/material/Switch";
import LabeledField from "../Forms/LabeledField";

export default function NetZero(props) {
  const { userInfo, setUserInfo } = useAuth();
  const [chartIsReady, setChartIsReady] = useState(null);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false)
  const [reductionForecastKgs, setReductionForecastKgs] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.onresize = () => {
      setWindowWidth(window.innerWidth)
    }
    return () => {
      window.onresize = null
    }
  })

  const defaultNetZeroSettings = {
    totalYears: 10,
    projectedRevGrowthPercent:
      userInfo.settings?.netZeroSettings?.projectedRevGrowthPercent || "",
    targetReductionPercent:
      userInfo.settings?.netZeroSettings?.targetReductionPercent || "",
    reductionTargetYear:
      userInfo.settings?.netZeroSettings?.reductionTargetYear || "",
    netZeroTargetYear:
      userInfo.settings?.netZeroSettings?.netZeroTargetYear || "",
  };

  const [netZeroSettings, setNetZeroSettings] = useState(
    defaultNetZeroSettings
  );
  const [modalNetZeroSettings, setModalNetZeroSettings] = useState(
    defaultNetZeroSettings
  );

  useEffect(() => {
    setChartIsReady(isSettingValid(netZeroSettings));
  }, [userInfo]);

  const isSettingValid = (netZeroSettings) => {
    const areSet =
      netZeroSettings.totalYears &&
      netZeroSettings.projectedRevGrowthPercent &&
      netZeroSettings.targetReductionPercent &&
      netZeroSettings.reductionTargetYear &&
      netZeroSettings.netZeroTargetYear;
    if (!areSet) {
      return false;
    }
    const areSetCorrectly =
      netZeroSettings.totalYears > 0 &&
      isValidPercent(netZeroSettings.projectedRevGrowthPercent) &&
      isValidPercent(netZeroSettings.targetReductionPercent) &&
      isValidYear(netZeroSettings.reductionTargetYear) &&
      isValidYear(netZeroSettings.netZeroTargetYear);
    return areSetCorrectly;
  };

  function isValidPercent(per) {
    return per && parseInt(per) > 0 && parseInt(per) <= 100;
  }

  function isValidYear(year) {
    return year && parseInt(year) > 2022 && parseInt(year) <= 2080;
  }

  const updateSettings = (e) => {
    const match = e.target.value.match(/^\d*$/);
    if (!match || match.length === 0) {
      return;
    }
    const newSettings = { ...modalNetZeroSettings };
    newSettings[e.target.name] = e.target.value;
    setModalNetZeroSettings(newSettings);
  };

  const updateUserSettings = () => {
    axios
      .put("/api/users/me/settings", {
        settings: { netZeroSettings: modalNetZeroSettings },
      })
      .then((res) => {
        setUserInfo(res.data);
        setNetZeroSettings(res.data.settings.netZeroSettings);
      });
  };

  const openModifyModal = () => {
    setSettingsModalOpen(true);
  };

  const saveModal = () => {
    updateUserSettings();
    setSettingsModalOpen(false);
  };

  const isMobile = windowWidth <= 767;

  const Switches = (
    <div className="netZeroSwitches">
      <span className="body3 title">Projection adjustments</span>
      <div className="switchesContainer">
        <div className="body3">
          <Switch />
          Total Revenue
        </div>
        <div className="body3">
          <Switch />
          Total cost of carbon credits
        </div>
        <div className="body3">
          <Switch />% of total revenue
        </div>
      </div>
    </div>
  )

  const keyList = [
    { name: "Business as usual forecast", type: "Swatch", color: "#ABABB5" },
    { name: "Forecast w/ initiatives", type: "Swatch", color: "#FBE74D" },
    { name: "Carbon offset", type: "Swatch", color: "#329E33" },
    { name: "Baseline emissions", type: "Dots" },
    { name: "Target emissions", type: "Line" },
    { name: "Net emissions", type: "Swatch", color: "#FFFFFF" },
    { name: "Historical emissions", type: "Swatch", color: "#000000" },
  ];

  function sliceArrayByLength(arrOfObjects, arrOfNumbers) {
      // Calculate the maximum length required for subarrays
      const maxLength = Math.max(...arrOfNumbers)

      // Create an array of dummy objects
      const dummyObjects = new Array(maxLength).fill({ type: 'none' })

      const result = []

      let currentIndex = 0

      for (const num of arrOfNumbers) {
          // Slice the array and pad with dummy objects if needed
          const slicedArray = arrOfObjects.slice(currentIndex, currentIndex + num)
              .concat(dummyObjects.slice(num))

          result.push(slicedArray)
          currentIndex += num
      }

      return result
  }

  const rows = sliceArrayByLength(keyList, windowWidth > 1150 ? [2, 2, 2, 1] : [3, 2, 2])

  const renderLegendTable = (rows) => {
    return (
      <table className="legend netZeroLegend">
        <tbody>
          {rows.map((row, idx) => {
            const { type, name, color } = row;
            if (type === "none") {
              return (
                <tr key={type}>
                  <td>
                    <span></span>
                  </td>
                </tr>
              );
            }
            return (
              <tr key={name}>
                <td>
                  <span
                    className={"key" + type}
                    style={{ backgroundColor: color }}
                  ></span>
                  <span>{name}</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const chart = (
    <div className="netZeroChart">
      <div className="key">
        <h3>Projected Emissions</h3>
        <div className="legendTables">
          {rows.map( row => renderLegendTable(row))}
        </div>
        {false && Switches}
      </div>
      <NetZeroChart
        totalYears={netZeroSettings.totalYears}
        projectedRevGrowthPercent={netZeroSettings.projectedRevGrowthPercent}
        targetReductionPercent={netZeroSettings.targetReductionPercent}
        reductionTargetYear={netZeroSettings.reductionTargetYear}
        netZeroTargetYear={netZeroSettings.netZeroTargetYear}
        goneNeutral={userInfo.gone_neutral}
        historicalEmissions={{
          year: (userInfo.most_recent_calc_year - 1) || 2023,
          kgCO2e: userInfo.first_years_emissions_kgco2e || 11000,
        }}
        reductionForecastKgs={reductionForecastKgs}
        expanded={expanded}
      />
      <div className="expandContract">{expanded ? <ContractIcon onClick={() => setExpanded(false)}/> : <ExpandIcon onClick={() => setExpanded(true)} />}</div>
    </div>
  );

  const mobileChartDisplay = (
    <div className="mobileChartDisplay">
      <h3>Projected Emissions</h3>
      <div className="body3">
        <BarGraphArrowIcon />
        To view your projected emissions graph, please open ClimateHound in a tablet or desktop device.
      </div>
    </div>
  )

  const growthInput = (
    <label className="percent">
      <LabeledField
        type="text"
        style={{ width: 65 }}
        unit
        values={modalNetZeroSettings}
        name="projectedRevGrowthPercent"
        onChange={updateSettings}
      />
      <span>%</span>
    </label>
  );
  const reductionInput = (
    <label className="percent">
      <LabeledField
        type="text"
        style={{ width: 65 }}
        values={modalNetZeroSettings}
        name="targetReductionPercent"
        onChange={updateSettings}
      />
      <span>%</span>
    </label>
  );
  const reductionTargetInput = (
    <LabeledField
      type="text"
      style={{ width: 75 }}
      values={modalNetZeroSettings}
      name="reductionTargetYear"
      onChange={updateSettings}
      placeholder="year"
    />
  );
  const zeroTargetInput = (
    <LabeledField
      type="text"
      style={{ width: 75 }}
      values={modalNetZeroSettings}
      name="netZeroTargetYear"
      onChange={updateSettings}
      placeholder="year"
    />
  );

  const validSettings = isSettingValid(modalNetZeroSettings);

  const settingsModal = (
    <Modal
      title="Edit Targets"
      subtitle="Edit your targets when needed to best reach your net-zero sustainability goals."
      open={settingsModalOpen}
      close={() => setSettingsModalOpen(false)}
      className="settingsModal"
      bodyDom={
        <div>
          <h4>Projected YoY Growth</h4>
          {growthInput}
          <h4>Emissions Reduction Target</h4>
          <div className="together">
            {reductionInput} <h4>by</h4> {reductionTargetInput}
          </div>
          <h4>Carbon Neutral Target Year</h4>
          {zeroTargetInput}
        </div>
      }
      actionsDom={
        <div>
          <input
            type="button"
            value="Save"
            className="positive small"
            onClick={saveModal}
          />
          <input
            type="button"
            value="Cancel"
            className="small"
            onClick={() => setSettingsModalOpen(false)}
          />
        </div>
      }
    />
  );

  const settingsPrompt = (
    <div className="settingsPrompt">
      <header>
        <BarGraphArrowIcon />
        <h3>Welcome to Emissions Planning</h3>
      </header>
      <div className="body2">
        Here you can use your carbon footprint analysis to simulate emissions reduction initiatives 
        while projecting their impact for your business and for the planet.
      </div>
      <div className="body3">
        Before we get started, let’s <b>estimate</b> some targets to visualize your
        future business footprint.
      </div>
      <div className="inlineInputs">
        <div className="body4">
          Our business grows approximately {growthInput} year over year. As
          the size of our business scales up, we will set a goal
        </div>
        <div className="body4">
          of reducing our overall emissions by {reductionInput} and aim to
          complete this by {reductionTargetInput}. In addition to reducing our
          emissions,
        </div>
        <div className="body4">
          we would like to achieve carbon neutrality by {zeroTargetInput}.
        </div>
      </div>
      <div className="action">
        <input
          type="button"
          value="Get Started"
          className="positive"
          disabled={!validSettings}
          onClick={updateUserSettings}
        />
      </div>
    </div>
  );

  return (
    <div className="netZero page">
      <header className="main">
        <div className="title">
          <h2>Emissions Planning</h2>
          {isMobile && <span onClick={openModifyModal}>Modify targets</span>}
        </div>
        {chartIsReady !== null && chartIsReady && (
          <div className="settings">
            <div className="setting">
              <h5>Projected YoY Growth</h5>
              <h4>{netZeroSettings.projectedRevGrowthPercent}%</h4>
            </div>
            <div className="setting">
              <h5>Emissions Reduction Target</h5>
              <h4>
                {netZeroSettings.targetReductionPercent}% by{" "}
                {netZeroSettings.reductionTargetYear}
              </h4>
            </div>
            <div className="setting settingNZ">
              <h5>Carbon Neutral By</h5>
              <h4>{netZeroSettings.netZeroTargetYear}</h4>
            </div>
            {!isMobile && <div className="actions">
              <input
                type="button"
                className="skeleton small"
                value="Modify targets"
                onClick={openModifyModal}
              />
            </div>}
          </div>
        )}
      </header>

      {settingsModal}
      {
        (windowWidth < 767) && mobileChartDisplay
      }
      {chartIsReady !== null && chartIsReady && chart}

      {chartIsReady !== null && !chartIsReady && settingsPrompt}

      {chartIsReady !== null && chartIsReady && (
        <ReductionStrategies updateUserSettings={updateUserSettings} setForecastKgs={setReductionForecastKgs} />
      )}
    </div>
  );
}
