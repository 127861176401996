import React, { useState, useEffect } from 'react'
import { Box, TextField, Button, Alert } from '@mui/material'
import { useAuth } from '../../providers/AuthProvider'
import { useToast } from '../../providers/ToastProvider'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ClimateHoundSVGSrc from '../../assets/ClimateHound_Wordmark_BLK2.svg'
import LabeledField from '../Forms/LabeledField'

export default function Login() {

  const defaultPasswords = {
    password: '',
    confirm_password: ''
  }

  const [ passwords, setPasswords ] = useState(defaultPasswords)

  const toast = useToast()

  const [ searchParams ] = useSearchParams()
  const resetToken = searchParams.get('reset_token')
  

  const [errors, setErrors] = useState({})
  const auth = useAuth()

  const location = useLocation()
  const navigate = useNavigate()
  const fromPath = location.state?.from?.pathname || "/"

  const resetPassword = async (e) => {
    if (e) {
      e.preventDefault()
    }
    console.log('signing in')
    let pendingErrors = {}
    if (!passwords['password']) {
      pendingErrors['password'] = "Password is required."
    }
    if (!passwords['confirm_password']) {
      pendingErrors['confirm_password'] = "Password confirmation is required."
    }
    if (passwords['password'] && passwords['confirm_password']) {
      if (passwords['password'] !== passwords['confirm_password']) {
        pendingErrors['confirm_password'] = "Password confirmation must match."
      } else if (passwords['password'].length < 8) {
        pendingErrors['password'] = "Password must be at least 8 characters."
      }
    }
    if (Object.keys(pendingErrors).length > 0) {
      setErrors(pendingErrors)
    } else {
      setErrors({})
      const res = await fetch(
        process.env.REACT_APP_API_URL + '/api/auth/set_new_password',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            password_reset_token: resetToken,
            password: passwords.confirm_password
          })
        }
      )
      const data = await res.json()
      if (data['status'] === 500) {
        auth.logOut()
        pendingErrors['formError'] = 'Unknown error occurred. Please refresh and try again, or contact an administrator.'
        setErrors(pendingErrors)
      } else if (data['error']) {
        pendingErrors['formError'] = data['error']
        auth.logOut()
        setErrors(pendingErrors)
      } else {
        toast.setToast('Password updated! Please log in.', 3000, 'success')
        navigate('/login', { replace: true })
      }
    }
  }

  const updatePasswords = (e) => {
    const newPasswords = {...passwords}
    newPasswords[e.target.name] = e.target.value
    setPasswords(newPasswords)
  }

  return (
    <>
      <div className="loginBlock">
        <div className="fields">
          <h3>Create new password</h3>
          <form onSubmit={resetPassword}>
            <LabeledField
              name="password"
              type="password"
              label="New Password"
              values={passwords}
              formErrors={errors}
              onChange={updatePasswords}
            />
            <LabeledField
              name="confirm_password"
              type="password"
              label="Confirm New Password"
              values={passwords}
              formErrors={errors}
              onChange={updatePasswords}
            />
            {
              errors && errors['formError'] && (
                <p className="errorMessage">{errors['formError']}</p>
              )
            }
            <input
              type="submit"
              className="positive"
              onClick={resetPassword}
              value="Reset password"
            />
          </form>
        </div>
      </div>
    </>
  )
}
