import axios from 'axios'

import catMap from '../../lib/catMap'
import BadgeSrc from '../../assets/badge.png'

import { ReactComponent as CheckIcon } from '../../assets/SmallCheck.svg'

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function TakeAction(props) {

  const navigate = useNavigate()

  const defaultComparisons = {
    cars: 0.0,
    per_100k: 0.0,
    per_unit_produced: 0.0
  }
  const [ comparisons, setComparisons ] = useState(defaultComparisons)
  const [ total, setTotal ] = useState(0.0)
  const [ totalCostCents, setTotalCostCents ] = useState(0)
  const [ bySection, setBySection ] = useState([])
  const [ calcYear, setCalcYear ] = useState("")
  const [ costPerTonneCents, setCostPerTonneCents ] = useState(0)

  function smallOrLargeNumber(num) {
    if (typeof num === "number") {
      return Math.abs(num) >= 1.0 ? num.toFixed(2) : num.toPrecision(2)
    } else {
      return '...'
    }
  }


  useEffect(() => {
    axios.get('/api/reports/current/summary').then((res) => {
      setBySection(res.data.emissions_by_section)
      setComparisons(res.data.comparisons)
      setTotal(res.data.total_kgco2e)
      setCalcYear(res.data.year)
      const tonnesRoundedUp = roundUpWithinReason(res.data.total_kgco2e * 0.001)
      setCostPerTonneCents(res.data.neutral_cost_cents_per_tonne_co2e)
      setTotalCostCents(tonnesRoundedUp * res.data.neutral_cost_cents_per_tonne_co2e)
    })
  }, [])

  function roundUpWithinReason(num) {
    if (num - parseInt(num) <= 0.01) {
      return Math.floor(num)
    } else {
      return Math.ceil(num)
    }
  }

  const centsToDollars = (cents) => {
    const dollars = cents * 0.01
    return `$${dollars}.00`
  }

  const openPaymentPage = () => {
    // window.open('https://stripe.com', '_blank')
    axios.post('/api/payments/report_redirect', {offset_cents: totalCostCents}).then((res) => {
      window.location = res.data['checkout_url']
      console.log(res)
    })
  }

  const goToDash = () => {
    navigate('/insights/details')
  }

  const selectCats = [ 'distribution-and-travel', 'energy-usage', 'waste-management', 'procurement' ]

  const statsDom = selectCats.map((catSlug) => {
    const results = bySection.find((s) => (s.section_slug === catSlug))
    console.log(results)
    if (!results) {
      return
    }
    const cat = catMap[catSlug]
    const Icon = cat.icon
    return (
      <div key={cat.name} className="stat">
        <header>
          <Icon />
          <h5>{cat.name}</h5>
        </header>
        <figure>
          <h2>{ smallOrLargeNumber(results.total_kgco2e * 0.001) }</h2>
          <label className="body4">
            MTCO2e
          </label>
        </figure>
      </div>
    )
  })

  return (
    <div className="page" id="insights">
      <header className="here">
        <div className="body1">Carbon Footprint Analysis</div>
        <h2>Here’s the results.</h2>
      </header>

      <div className="showcase">
        <div className="footprint">
          <div className="qty">
            <h2>{calcYear} Footprint</h2>
            <h1>
              { roundUpWithinReason(total * 0.001) }
              <label>MTCO2e</label>
            </h1>
            <input type="button" value="View dashboard" className="small" onClick={goToDash} />
          </div>
          <div className="explanation">
            <h3>Here’s what that means:</h3>
            <div className="body1">
              Equivalent to the annual carbon sequestered by { Math.ceil(roundUpWithinReason(total * 0.001) * 1.2) } acres of forest.
            </div>
            <div className="body1">
              Equivalent to the annual emissions of { Math.ceil(roundUpWithinReason(total * 0.001) * 0.223) } gas-powered cars.
            </div>
          </div>
        </div>
        <div className="statsBlock dark">
          { statsDom }
        </div>
      </div>

      <div className="sale">
        <div className="mark">
          <div className="badge" style={{backgroundImage: `url('${BadgeSrc}')`}}></div>
        </div>
        <div className="cta">
          <h2>Go carbon neutral today!</h2>
          <div className="split">
            <div className="reasons">
              <ul>
                <li><CheckIcon /><span>Be a part of the solution!</span></li>
                <li><CheckIcon /><span>Employees look for employers that think and act sustainably.</span></li>
                <li><CheckIcon /><span>Help<a href="https://www.climatehound.io/carbon-offsets" target="_blank">fund projects</a>in clean energy, innovation, and remediation. </span></li>
                <li><CheckIcon /><span>Rep your values with our TTB-conforming certification seal.</span></li>
              </ul>
            </div>
            <div className="totals">
              <div className="lineitem body2 first">
                <div className="name">
                  Total GHGs calculated
                </div>
                <div className="val body1">
                  { roundUpWithinReason(total * 0.001) }
                  <label className="micro">MTCO2e</label>
                </div>
              </div>
              <div className="lineitem body2 last">
                <div className="name">
                  Cost per ton of CO2
                </div>
                <div className="val body1">
                  ${costPerTonneCents / 100}
                </div>
              </div>
              <div className="due lineitem">
                <h3 className="name">Total Due</h3>
                <h2 className="val">{ centsToDollars(totalCostCents) }</h2>
              </div>
              <div className="actions">
                <input type="button" value="View dashboard" className="small" onClick={goToDash} />
                <input type="button" className="positive small" value="Go Carbon Neutral" onClick={openPaymentPage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
