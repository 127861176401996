export default function QuestionFormatter(question_json) {
  const q = question_json
  return {
    bodyText: () => {
      if (q.question_type === "eeio_spend") {
        return q.prompt_text.text
      } else {
        return "unknown question type"
      }
    },
    typeText: () => {
      if (q.question_type === "eeio_spend") {
        return "EEIO Spend"
      } else {
        return "unknown question type"
      }
    }
  }
}
