import React from 'react'

import { Switch } from '@mui/material'
import { ReactComponent as DollarSignIcon } from './DollarSign.svg'
import { ReactComponent as ElectricLeafIcon } from './ElectricLeaf.svg'
import { ReactComponent as PiggyBankIcon } from './PiggyBank.svg'
import reductionStrategyStepsData from "../../data/reductionStrategySteps"
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter'

const tileBody = {
  "null-strategy": "Disposable batteries are alkaline and rechargeable batteries are NiCd Rechargeable batteries can be re-used time and costs times as much as disposable models.",
  "pv-panels": "Installing solar panels reduces your environmental impact by harnessing renewable energy from the sun, reducing reliance on fossil fuels and minimizing greenhouse gas emissions.",
  "eco-driving": "Eco-driving lowers fuel consumption and emissions across your fleet by promoting safer speeds.",
  "work-from-home": "A work from home program reduces employee commutes, lowering emissions and energy usage."
}


export default function ReductionStrategyTile(props) {
  const { rs, toggleReductionInForecast, openStrat, updateActionPlan, setTabValue } = props

  let actionButton = null

  const forecastToggle = !Object.keys(rs.inputs).filter(init => !rs.inputs[init]).length ? 
    <div>
      <Switch 
        checked={rs.is_in_forecast} 
        onClick={(e) => {
          e.stopPropagation()
          toggleReductionInForecast(rs.id)
        }} 
      /> Show in graph
    </div> : <div></div>
  

  if(rs.status === 'NOT_STARTED') {
    actionButton = <input type="button" className="small" value="Simulate this reduction" onClick={openStrat} />
  } else if((rs.status === 'IN_PROGRESS' || rs.status === "STARTED") && !rs.is_in_action_plan) {
    actionButton = (
      <div className="rsTileProgressStarted">
        {forecastToggle}
        <input 
          type="button" 
          className="small positive" 
          value="Add to action plan" 
          onClick={(e) => {
            e.stopPropagation()
            updateActionPlan(rs.id)
            setTabValue(2)
          }} 
        />
      </div>
      )
  } else if((rs.status === 'IN_PROGRESS' || rs.status === "STARTED") && rs.is_in_action_plan) {
    actionButton = (
      <div className="rsTileProgressStarted">
        {forecastToggle}
        <input 
          type="button" 
          className="small" 
          value="Manage Reduction" 
          onClick={(e) => {
            e.stopPropagation()
            openStrat()
          }} 
        />
      </div>
    )
  } else if(rs.status === 'STEPS_COMPLETE' || rs.status === 'COMPLETED') {
    actionButton = (
      <div className="rsTileProgressStarted">
        {forecastToggle}
        <input 
          type="button" 
          className="small" 
          value={rs.status === "COMPLETED" ? "Review Outcome" : "Review Final Values"} 
          onClick={(e) => {
            e.stopPropagation()
            openStrat()
          }} 
        />
      </div>
    )
  }

  let progressSection = null
  if(rs.status === 'NOT_STARTED') {
    progressSection = ""
  } else if((rs.status === 'IN_PROGRESS' || rs.status === 'STARTED') && rs.is_in_action_plan) {
      progressSection = (
        <div className='progressSection'>
          <div className="body4">
            {rs.status === "IN_PROGRESS" ? 'In Progress' : 'Ready to start'}
          </div>
          <div className="micro">
            {rs.completed_steps_mask.filter(
    (x) => x
  ).length}/{reductionStrategyStepsData[rs.slug].length} steps done
          </div>
        </div>
      )
  } else if(rs.status === 'STEPS_COMPLETE') {
    progressSection = (
        <div className='progressSection'>
          <div className="body4">
            In Progress
          </div>
          <div className="micro">
            {rs.completed_steps_mask.filter(
    (x) => x
  ).length}/{reductionStrategyStepsData[rs.slug].length} steps done
          </div>
        </div>
      )
  } else if(rs.status === 'COMPLETED') {
    progressSection = (
        <div className='progressSection'>
          <div className="body4">
            Completed
          </div>
        </div>
      )
  }

  return (
    <div className="reductionStrategyTile" onClick={openStrat}>
      <header>
        <div>
          <h5>{props.rs.name}</h5>
          <div className="categoryTabs">
            <span>{props.rs.category || "Reduction"}</span>
            <span className="difficulty">{capitalizeFirstLetter(props.rs.difficulty)  || "Easy"}</span>
          </div>
        </div>
        {progressSection}
      </header>
      <div className={"rsStats" + (props.rs.status === "COMPLETED" ? " completed" : "")}>
        <div className={"stat " + (props.rs.kgco2e_reduced ? "complete" : "incomplete")}>
          <ElectricLeafIcon/>
          <div className="statLabel">
            <label>Reduction potential</label>
            <span className="body4"> { props.rs.kgco2e_reduced && `${Math.round(props.rs.kgco2e_reduced * 0.001)} mtCO2e`} </span>
          </div>
        </div>
        <div className={"stat " + (props.rs.dollars_saved ? "complete" : "incomplete")}>
          <PiggyBankIcon/>
          <div className="statLabel">
            <label>Dollars saved</label>
            <span className="body4">{ props.rs.dollars_saved && `$${props.rs.dollars_saved.toFixed(2)}` }</span>
          </div>
        </div>
        <div className={"stat " + (props.rs.upfront_cost ? "complete" : "incomplete")}>
          <DollarSignIcon/>
          <div className="statLabel">
            <label>Upfront cost</label>
            <span className="body4">{ props.rs.upfront_cost && `$${props.rs.upfront_cost.toFixed(2)}`}</span>
          </div>
        </div>
      </div>
      { !rs.is_in_action_plan && <div className="rsBody body4">
        {tileBody[props.rs.slug] || "Disposable batteries are alkaline and rechargeable batteries are NiCd Rechargeable batteries can be re-used  time and costs  times as much as disposable models."}
      </div>}
      <div className="rsActions">
        { actionButton }
      </div>
    </div>
  )
}
