import React, { useEffect, createRef, useState } from 'react'
import { interpolateViridis } from 'd3-scale-chromatic'
import Chart from 'chart.js/auto'

export default function ElectricityGridChart(props) {

  const chartRef = createRef()
  let chart = null

  const COLORS = ["#FCE248", "#F8FC48", "#E3FC48", "#AFFF5F", "#C1DC46", "#7CD260", "#1CB587", "#117D96", "#115E96", "#114696", "#1C1196"]

  const [width, setWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(width <= 767)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    setMobile(window.innerWidth <= 767)
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const config =  () => ({
    type: 'bar',
    data: {
        labels: [""],
        datasets: Object.keys(props.entries).map( (label, index) => ({
            data: [props.entries[label]],
            backgroundColor: COLORS[index],
            label
        }) )
      },
    options: {
        indexAxis: 'y',
        layout: {margin: {
          padding: -30
        }},
        responsive: true,
        maintainAspectRatio: false,
        scales: {
        x: {
            display: false,
            stacked: true
        },
        y: {
            display: false,
            stacked: true
        }
        },
        plugins: {
            tooltip: {title: () => ("")},

            legend: {
                display: false
            }
        },
    }
    })

  useEffect(() => {
    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {

    if (chart && chart.destroy) {
      chart.destroy()
    }

    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }

  }, [props.data, mobile])  

  return (
    <div className="canvas">
        <canvas ref={chartRef} />
    </div>
  )
}

