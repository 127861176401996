const returnDeviceType = () => {
    const width = window.innerWidth
    if (width <= 767) {
      return 'mobile'
    }else if(width >=768 &&  width <= 1150){
      return "tablet"
    }else{
        return "desktop"
    }
  }

  export default returnDeviceType
