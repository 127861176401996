import React from 'react'

import Checkbox from '../Forms/CheckBox'

export default function ReductionStrategySteps(props) {
  return (
    <div className={`stepHolder ${props.disabled ? "disabled" : ""}`}>
      {props.steps.map((step, idx) => {
        return (
          <div key={step} className="rsStep">
            <div className="stepCheckbox">
              <Checkbox
                onChange={() => props.disabled ? null : props.toggleStep(idx)}
                errors={{}}
                disabled={props.disabled}
                values={{checked: !!props.completionMask[idx]}}
                name={'checked'}
              />
            </div>
            <span>{step}</span>
          </div>
        );
      })}
    </div>
  );
}
