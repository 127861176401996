import React, { useEffect, useState } from 'react'
import { Route, Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import AdminFrame from '../AdminFrame'

import moment from 'moment'

import axios from 'axios'

export default function Dashboard() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ drafts, setDrafts ] = useState([])

  useEffect(() => {
    axios.get('/api/sus_pages/drafts').then((res) => {

      setDrafts(res.data)
    })
  }, [])

  const approvalHeaders = ['Business Name', 'Submitted Date', 'Actions']

  const preview = (id) => {
    window.open(`${process.env.REACT_APP_ROOT_URL}/profile/${btoa(id)}`, '_blank')
  }

  const approve = (id) => {
    axios.put(`/api/sus_pages/${id}`, {sus_page: {publish_state: 'published'}})
    window.location.reload()
  }

  return(
      <div className="adminPage">

        <header className="pageHeader">
          <h1>Draft Approval Requests</h1>
        </header>

        <div className="table">
          <table>
            <thead>
              <tr>
                { approvalHeaders.map((susHead) => (<td>{susHead}</td>)) }
              </tr>
            </thead>
            <tbody>
              { drafts.map((draft) => (
                <tr>
                  <td>{draft.company}</td>
                  <td>{moment(draft.updated_at).format('MMMM Do YY, h:mm a')}</td>
                  <td>
                    <input type="button" value="preview" onClick={() => {preview(draft.id)}} />
                    <input type="button" value="approve" onClick={() => {approve(draft.id)}} />
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
  )
}
