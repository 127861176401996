import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation, Navigate } from 'react-router-dom'

const ToastContext = createContext(null)

export default function ToastProvider({ children }) {

  const [message, setMessage] = useState(null)
  const [mood, setMood] = useState(null)

  const setToast = (message, time = 5000, mood = 'warning') => {
    setMessage(message)
    setMood(mood)
    if (time && typeof time === typeof 69) {
      setTimeout(() => {
        setMessage(null)
      }, time)
    }
  }

  const value = { setToast, message, mood }

  return (
    <ToastContext.Provider value={value}>
      {children}
    </ToastContext.Provider>
  )
}

export function useToast() {
  return useContext(ToastContext)
}

export function TopToast({children}) {
  const toast = useToast()
  return (
    <>
      { toast.message && (
          <div id="toastMessage" className={`toastType ${toast.mood}`}>
            { toast.message }
          </div>
        )
      }
      { children }
    </>
  )
}
