import React from 'react'
import './styles/Reset.css'
import CssBaseline from '@mui/material/CssBaseline'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '@fontsource/roboto'
import "@fontsource/roboto-condensed"

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Provider as RollbarProvider } from '@rollbar/react'
import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'

import QuestionnaireStart from './components/Questionnaire/QuestionnaireStart'
import SectionReview from './components/Questionnaire/SectionReview'
import Question from './components/Questionnaire/Question'
import QuestionnaireSectionWelcome from './components/Questionnaire/QuestionnaireSectionWelcome'
import Vehicles from './components/Questionnaire/Vehicles'

import PitchSlides from './components/Pitch/Slides'

import LoginFrame from './components/Auth/LoginFrame'
import Login from './components/Auth/Login'
import ResetPassword from './components/Auth/ResetPassword'
import AppFrame from './components/AppFrame'
import StyleGuide from './components/StyleGuide'

import Welcome from './components/Signup/Welcome'
import Signup from './components/Signup/Signup'
import EditProfile from './components/Profile/EditProfile'
import DataReviewPage from './components/DataReview/index'
import ProfileShower from './components/Profile/Shower'
import EditSustainability from './components/Sustainability/EditSustainability'
import Insights from './components/Insights/Insights'
import InsightDetails from './components/Insights/Details'
import TakeAction from './components/Insights/TakeAction'
import ClimateNeutralSuccess from './components/Insights/ClimateNeutralSuccess'
import AcceptInvite from './components/Signup/AcceptInvite'
import NetZero from './components/NetZero/NetZero'
import Locations from './components/Locations/Locations'

import Error from './components/Error'

import ViewOnDesktop from './components/ViewOnDesktop'
import RemainingHelp from './components/RemainingHelp'
import ProcessingData from './components/ProcessingData'

import AdminDashboard from './admin/Dashboard'
import AdminInvites from './admin/Invites/Invites'
import AdminUsers from './admin/Users'
import AdminUserShow from './admin/Users/Show'
import AdminQuestions from './admin/QuestionSources/Index'
import AdminQuestionnaires from './admin/Questionnaires/Index'
import AdminQuestionnaireShow from './admin/Questionnaires/Show'
import AdminSusPageDrafts from './admin/SusPages/Drafts'

// start of some unlisted admin CRUD pages:
import AdminWaste from './admin/Waste/Index'
// ----------------------------------------

import AuthProvider, { RequireAuth, NoAuth } from './providers/AuthProvider'
import ToastProvider, { TopToast } from './providers/ToastProvider'
import LocationProvider from './providers/LocationProvider'

import HomeSwitch from './components/HomeSwitch'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import AdminFrame from './admin/AdminFrame'
import SuppliersTable from './components/Suppliers/SuppliersTable'

const theme = createTheme({
  shape: {
    borderRadius: 0
  }
})

const rollbarEnabled = process.env.NODE_ENV !== "development"

const rollbarConfig = {
  enabled: rollbarEnabled,
  accessToken: process.env.REACT_APP_ROLLBAR_API_KEY,
  environment: process.env.REACT_APP_ROLLBAR_ENV || process.env.NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  server: {
    root: process.env.REACT_APP_ROOT_URL
  },
  code_version: process.env.REACT_APP_VERSION
}

function App() {
  return(
    <RollbarProvider config={rollbarConfig}>
    <RollbarErrorBoundary>
    <BrowserRouter>
      <div className="wrapper">
        <AuthProvider>
          <ToastProvider>
            <LocationProvider>
              <Routes>

                {/*              
                   __        __          __ 
                  |__) |  | |__) |    | /  `
                  |    \__/ |__) |___ | \__,
                */}                       
                <Route path="login" element={<LoginFrame />}>
                  <Route index element={<TopToast><Login /></TopToast>} />
                  <Route path="reset_password" element={<ResetPassword />} />
                </Route>
                <Route path="join" element={<PitchSlides />} />
                <Route path="invite" element={<AcceptInvite />} />
                <Route path="signup" element={<Signup />} />
                <Route path="profile/:id" element={<ProfileShower />} />

                {/*            
                   ___  __              ___      /              ___      
                  |__  |__)  /\   |\/| |__      /      /\  |  |  |  |__| 
                  |    |  \ /~~\  |  | |___    /      /~~\ \__/  |  |  | 
                                                                      
                */}

                <Route element={<AppFrame />} path="/">
                  <Route index element={<RequireAuth><HomeSwitch /></RequireAuth>} />
                  <Route path="error" element={<RequireAuth><Error /></RequireAuth>} />
                  <Route path="insights" element={<RequireAuth><Insights /></RequireAuth>} />
                  <Route path="insights/details" element={<RequireAuth><InsightDetails demo/></RequireAuth>} />
                  <Route path="insights/take-action" element={<RequireAuth><TakeAction /></RequireAuth>} />
                  <Route path="insights/take-action/success" element={<RequireAuth><ClimateNeutralSuccess /></RequireAuth>} />
                  <Route path="locations" element={<RequireAuth><TopToast><Locations /></TopToast></RequireAuth>} />
                  <Route path="profile/edit" element={<RequireAuth><TopToast><EditProfile /></TopToast></RequireAuth>} />
                  <Route path="sustainability/edit" element={<RequireAuth><TopToast><EditSustainability /></TopToast></RequireAuth>} />
                  <Route path="questionnaire/start" element={<RequireAuth><QuestionnaireStart /></RequireAuth>} />
                  <Route path="questionnaire/welcome" element={<RequireAuth><Welcome /></RequireAuth>} />
                  <Route path="questionnaire/:id/data-review" element={<RequireAuth><DataReviewPage /></RequireAuth>} />
                  <Route path="questionnaire/:categorySlug" element={<RequireAuth><SectionReview /></RequireAuth>} />
                  <Route path="questionnaire/:qnnYear/:questionId" element={<RequireAuth><Question /></RequireAuth>} />
                  <Route path="questionnaire/:qnnYear/:categorySlug/start/:firstQuestionId" element={<RequireAuth><QuestionnaireSectionWelcome /></RequireAuth>} />
                  <Route path="data-review" element={<RequireAuth><DataReviewPage /></RequireAuth>} />
                  <Route path="view-on-desktop" element={<ViewOnDesktop />} />
                  <Route path="help-requested" element={<RemainingHelp />} />
                  <Route path="processing-data" element={<ProcessingData />} />
                  <Route path="emissions-planning" element={<RequireAuth><NetZero /></RequireAuth>} />
                </Route>

                {/*
                        __               
                   /\  |  \  |\/| | |\ | 
                  /~~\ |__/  |  | | | \| 

                */}

                <Route path="/admin" element={<AdminFrame />}>
                  <Route index element={<RequireAuth><AdminDashboard /></RequireAuth>} />
                  <Route path="users" element={<RequireAuth><AdminUsers /></RequireAuth>} />
                  <Route path="users/:id" element={<RequireAuth><AdminUserShow /></RequireAuth>} />
                  <Route path="questions" element={<RequireAuth><AdminQuestions /></RequireAuth>} />
                  <Route path="questionnaires" element={<RequireAuth><AdminQuestionnaires /></RequireAuth>} />
                  <Route path="questionnaires/:id" element={<RequireAuth><AdminQuestionnaireShow /></RequireAuth>} />
                  <Route path="questionnaires/:id/data-review" element={<RequireAuth><DataReviewPage /></RequireAuth>} />
                  <Route path="waste" element={<RequireAuth><AdminWaste /></RequireAuth>} />
                  <Route path="invites" element={<RequireAuth><AdminInvites /></RequireAuth>} />
                  <Route path="sus_page_drafts" element={<RequireAuth><AdminSusPageDrafts /></RequireAuth>} />
                  <Route path="suppliers" element={<RequireAuth><SuppliersTable/></RequireAuth>} />
                  <Route path="style_guide" element={<RequireAuth><StyleGuide /></RequireAuth>} />
                </Route>
              </Routes>
            </LocationProvider>
          </ToastProvider>
        </AuthProvider>
      </div>
    </BrowserRouter>
    </RollbarErrorBoundary>
    </RollbarProvider>
  )
}

export default App
