import React from 'react'
import RightImage from '../../assets/Cascades.png'
import { Outlet } from 'react-router-dom'
import ChWordmark from '../../assets/ChWordmark'
export default function Login(props) {

  return (
    <div id="userLogin">
      <div className="mobileHeader">
          <div className="logo">
            <ChWordmark />
          </div>
        </div>
      <div className="left">
        <Outlet />
      </div>
      <div
        className="right"
        style={{backgroundImage: `url('${RightImage}')`}}
      >
      </div>
    </div>
  )
}
