import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CommonLinks from '../CommonLinks'
import LabeledField from '../Forms/LabeledField'
import axios from 'axios'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'

import { useToast } from '../../providers/ToastProvider'

import FileUploader from '../Forms/FileUploader'
import { ReactComponent as StripeIcon } from '../../assets/Stripe.svg'




export default function EditProfile() {

  const toast = useToast()

  const defaultUserInfo = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    bio: '',
    logoUrl: '',
    wordmarkUrl: ''
  }

  const [ searchParams ] = useSearchParams()
  const updatedParam = searchParams.get('updated')

  const [ userInfo, setUserInfo ] = useState(defaultUserInfo)
  const [ formErrors, setFormErrors ] = useState({})
  const [ updated, setUpdated] = useState(updatedParam)

  if(updated) {
    toast.setToast('Payment method updated', 3000, 'success')
    setUpdated(false)
    console.log('heck yea')
  }

  const updateUserInfo = (e) => {
    const newUserInfo = {...userInfo}
    newUserInfo[e.target.name] = e.target.value
    setUserInfo(newUserInfo)
    setFormErrors({})
    console.log(newUserInfo)
  }

  const getUserInfo = (callback) => {
    axios.get('/api/users/me').then((res) => {
      const newUserInfo = {
        firstName: res.data.first_name || '',
        lastName: res.data.last_name || '',
        companyName: res.data.company || '',
        email: res.data.email || '',
        bio: res.data.bio || '',
        logoUrl: res.data.logo_url || '',
        wordmarkUrl: res.data.wordmark_url || ''
      }
      callback(newUserInfo)
    })
  }

  const saveInfo = () => {
    const apiFormatInfo = {
      user: {
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        company: userInfo.companyName,
        email: userInfo.email,
        bio: userInfo.bio
      }
    }
    axios.put('/api/users/me', apiFormatInfo).then((res) => {
      console.log(res)
      toast.setToast('Profile updated 👍', 3000, 'success')
    })
  }

  const updatePaymentInfo = () => {
    return null;
  }

  useEffect(() => {
    getUserInfo((info) => setUserInfo(info))
  }, [])

  return(
    <div className="page">
      <header className='profilePageHeader'>
        <h2>Profile</h2>
      </header>
      <div className="paymentAndAccount">
        <div className='accountAndPaymentContainer'>
            <div className="accountInfo">
              <h4>Account Info</h4>
              <LabeledField label="First Name" name="firstName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
              <LabeledField label="Last Name" name="lastName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
              <LabeledField label="Company Name" name="companyName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
              <LabeledField label="Email" name="email" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
              <input className="positive" type="button" onClick={saveInfo} value="Save" />
            </div>
            
            <div className="paymentMethods">
              <h4>Saved Payment Methods</h4>
              <div style={{display: 'flex'}}><input type="button" onClick={updatePaymentInfo} value={"Edit payment details "} /><StripeIcon /></div>
              <div className="certificationMarks">
              <h4>ClimateHound Certification Marks</h4>
              <div className="body4">
                This contains a zip file of all brand assets your business needs to show its carbon neutral certification with ClimateHound
              </div>
              <input type="button" disabled={true} value="Download" />
            </div>
            </div>

           
        </div> 
        
        <div className='termsAndPrivacyLinks'>
          <a href="https://www.climatehound.io/serviceterms" target="_blank" rel='noreferrer'>Terms of Service</a>
          <a href="https://www.climatehound.io/privacypolicy" target="_blank" rel='noreferrer'>Privacy Policy</a> 
        </div>
      </div>
    </div>
  )
}
