import React from 'react'

export default function ValuesContainer(props) {

  const types = {
    'values': {
      title: "Initiative Values",
      className: "rsValues"
    },
    'confirm-values': {
      title: "Update Final Values",
      className: "rsValues confirm"
    },
    'assumptions': {
      title: "Assumptions",
      className: "rsValues assumptions"
    },
    'completed-values': {
      title: "Final Values",
      className: "rsValues completed"
    },
    'assumptions-final': {
      title: "Previous Assumptions",
      className: "rsValues assumptionsCompleted"
    },
  }

  return (
    <div className={ types[props.name].className }>
      <h5>{ types[props.name].title }</h5>
      <div className="valuesBody">
        { props.children }
      </div>
    </div>
  )
}
