export const STATUSES = {
  NOT_STARTED: "NOT_STARTED",
  STARTED: "STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  STEPS_COMPLETE: "STEPS_COMPLETE",
  COMPLETED: "COMPLETED"
};

export const initiativeHasStarted = (status) => (status !== "NOT_STARTED")
export const intiativeIsInProgress = (status) => (status === "IN_PROGRESS")
export const initiativeIsNotFinished = (status) => (status !== "NOT_STARTED" && status !=="COMPLETED")
export const initiativeIsCompleted = (status) => (status === "STEPS_COMPLETE" || status === "COMPLETED")
export const initiativeIsDone = (status) => (status === 'COMPLETED')