import React, { useState, useEffect } from 'react'
import { Route, Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import { Button, IconButton, Tooltip } from '@mui/material'
import AdminFrame from '../AdminFrame'

import moment from 'moment'

import { AgGridReact } from 'ag-grid-react'

import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import isPrice from '../../utils/isPrice'
import isEmail from '../../utils/isEmail'
import isInteger from '../../utils/isInteger'

import CreateInvite from './CreateInvite'

import axios from 'axios'

export default function Invites() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ industries, setIndustries ] = useState([])

  const [ isCreateOpen, setIsCreateOpen ] = useState(false)
  const defaultInvite = {
    industry_id: '',
    price: '',
    email: '',
    name: '',
    company_name: ''
  }
  const [ invite, setInvite ] = useState(defaultInvite)
  const [ formErrors, setFormErrors ] = useState({})

  useEffect(() => {
    axios.get('/api/industries').then((res) => {
      // console.log(res)
      setIndustries(res.data)
    })
  }, [])

  const updateInvite = (e) => {
    const newInvite = {...invite}
    newInvite[e.target.name] = e.target.value
    setInvite(newInvite)
    setFormErrors({})
  }

  const createInvite = () => {
    const newFormErrors = getFormErrors()
    if (Object.keys(newFormErrors).length === 0) {
      const inviteDataPayload = {
        industry_id:  invite['industry_id'],
        price:        invite['price'],
        email:        invite['email'],
        name:         invite['name'],
        company_name: invite['company_name']
      }
      axios.post('/api/invites', inviteDataPayload).then((res) => {
        if (res.status === 200) {
          console.log('good')
          getAndSetInvites()
          setIsCreateOpen(false)
        } else {
          console.log('no gooooo')
          setFormErrors({server: 'Something went wrong...'})
        }
      }).catch((e) => {
        console.log('this is boo', e)
        setFormErrors({server: 'Server error. Try again or contact admin.'})
      })
    } else {
      setFormErrors(newFormErrors)
    }
  }

  const getFormErrors = () => {
    const newFormErrors = {}

    if (!isEmail(invite['email'])) {
      newFormErrors['email'] = 'Must be valid email.'
    }

    'email name industry_id'.split(' ').forEach((key) => {
      if (invite[key].length === 0) {
        newFormErrors[key] = 'This field is required.'
      }
    })

    return newFormErrors
  }

  const [ inviteData, setInviteData ] = useState([])

  useEffect(getAndSetInvites, [])

  function getAndSetInvites() {
    axios.get('/api/invites').then((res) => {
      console.log(res)
      const formattedData = res.data.map((i, idx) => {
        return ({
          id:   i['id'],
          industry:   i['industry']['name'],
          industry_id:   i['industry']['id'],
          price:   i['price'],
          email:   i['email'],
          name:    i['name'],
          company_name:  i['company_name'],
          invite_link:  i['invite_link'],
          invitor: i['invitor']['first_name'] + " " + i['invitor']['last_name'],
          created_at: moment(i['created_at']).calendar()
        })
      })
      setInviteData(formattedData)
    }).catch(() => {
      alert('something went wrong')
    })
  }

  function copyInviteLink(invite) {
    console.log(invite)
    const inviteLink = process.env.REACT_APP_ROOT_URL + '/signup?key=' + invite.invite_link
    navigator.clipboard.writeText(inviteLink)
    console.log(inviteLink)
  }

  const deleteInvite = (invite) => {
    console.log(invite)
    if (window.confirm(`Delete invite for ${invite['company_name']}?`)) {
      axios.delete('/api/invites/' + invite['id']).then((res) => {
        getAndSetInvites()
      })
    } else {
      return
    }
  }

  const qnnColDefs = [
    {field: "industry", flex: 2},
    {field: "price", flex: 1},
    {field: "email", flex: 4},
    {field: "name", flex: 2},
    {field: "company_name", headerName: "Company Name", flex: 3},
    {field: "invitor", headerName: "Inviting User", flex: 2, valueFormatter: (props) => (
      props.value
    )},
    {field: "created_at", headerName: "Created", flex: 3},
    {field: "id", headerName: "Actions", flex: 2, cellRenderer: (props) => (
      <div>
          <Tooltip title="Delete" PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -15],
                },
              },
            ],
          }}
        >
            <IconButton variant="contained" color="error" onClick={() => deleteInvite(props.data)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy" PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -15],
                },
              },
            ],
          }}
          >
            <IconButton variant="outlined" onClick={() => copyInviteLink(props.data)}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
      </div>
    )},
  ]

  const autoSizeStrategy = {
    type: 'fitGridWidth',
  };

  const defaultColDef = {
    flex: 1,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    cellStyle: {"wordBreak": "normal"},
  };

  return(
      <div className="adminPage admin-table-wrapper">
        <h1>Invites</h1>
        
        {!!inviteData.length && (
          <div className="ag-theme-quartz full-container-table">
            <AgGridReact 
              domLayout='autoHeight'
              suppressDragLeaveHidesColumns 
              rowData={inviteData} 
              columnDefs={qnnColDefs} 
              autoSizeStrategy={autoSizeStrategy}
              defaultColDef={defaultColDef}
              enableCellTextSelection
              ensureDomOrder
            />
          </div>
        )}
        
        <CreateInvite
          isCreateOpen={isCreateOpen}
          setIsCreateOpen={setIsCreateOpen}
          invite={invite}
          industries={industries}
          onChange={updateInvite}
          createInvite={createInvite}
          formErrors={formErrors}
        />

        <div className="admin-fab">
          <Fab color="primary" aria-label="add"  onClick={() => (setIsCreateOpen(true))}>
            <AddIcon />
          </Fab>
        </div>
      </div>
  )
}
