import React from 'react'

import { useAuth } from '../providers/AuthProvider'
import QuestionnaireStart from './Questionnaire/QuestionnaireStart'
import Insights from './Insights/Insights'

export default function HomeSwitch(props) {
  const auth = useAuth()

  const haveReport = auth.userInfo && auth.userInfo.report_available

  return(
    <div>
      { !haveReport && <QuestionnaireStart /> }
      { haveReport && <Insights /> }
    </div>
  )
}
