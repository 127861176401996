import React, { useState, useEffect } from 'react'
import RadioOptionGroup from '../Forms/RadioOptionGroup'
import LabeledField from '../Forms/LabeledField'

import centsToReadable from '../../utils/centsToReadable'
import Accordion from '../Containers/Accordion'

import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js"

import axios from 'axios'

export default function PaymentTaker(props) {

  const stripe = useStripe()
  const elements = useElements()

  const [ message, setMessage ] = useState(null)
  const [ amount, setAmount ] = useState('')
  const [ complete, setComplete ] = useState(false)
  const [ error, setError ] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!props.paymentIntentSecret) {
      return
    }

    stripe.retrievePaymentIntent(props.paymentIntentSecret).then(({ paymentIntent }) => {
      console.log(paymentIntent)
      setAmount(paymentIntent.amount)
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!")
          break
        case "processing":
          setMessage("Your payment is processing.")
          break
        case "requires_payment_method":
          // setMessage("Your payment was not successful, please try again.")
          break
        default:
          setMessage("Something went wrong.")
          break
      }
    })
  }, [stripe])


  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    props.setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_ROOT_URL,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    setError(true)
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message)
    } else {
      setMessage("An unexpected error occurred.")
    }

    props.setIsLoading(false)
  }

  const paymentElementOptions = {
    layout: "tabs"
  }

  const handleChange = (event) => {
    if (event.complete) {
      setComplete(true)
    } else if(complete) {
        setComplete(false)
    }
  }

  const lineItemDom = (
    <tr className="lineItems">
      <td>Footprint Calculation + Full Report</td><td>{ centsToReadable(amount) }</td>
    </tr>
  )

  return (
    <div className="paymentTaker">
      <h4>Order Summary</h4>
      <table>
        <tbody>
          { lineItemDom }
          <tr>
            <td><h3 className="label">Total</h3></td><td><h3 className="value">{ centsToReadable(amount) }</h3></td>
          </tr>
        </tbody>
      </table>

      <div className="accordionSet">
          <PaymentElement id="payment-element" options={paymentElementOptions} onChange={handleChange} />
          {/* Show any error or success messages */}
          {message && <div id={error ? "errorMessage" : "payment-message"}>{message}</div>}
      </div>
      <div className="navButtons">
        <input className="" type="button" value="Back" onClick={() => (props.goToStep(1))} />
        <input className="positive" type="button" value="Make Payment" onClick={handleSubmit} disabled={(props.isLoading || !stripe || !elements || !complete) } />
      </div>
    </div>
  )
}
