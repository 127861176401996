import React, { useState, useEffect } from 'react'
import { Route, Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'
import { Button } from '@mui/material'
import AdminFrame from './AdminFrame'

import axios from 'axios'

export default function Dashboard() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ factors, setFactors ] = useState([])

  useEffect(() => {
    axios.get('/api/epa_industries').then((res) => {
      console.log(res)
      setFactors(res.data['epa_industries'])
    })
  }, [])

  return(
      <div className="adminPage">
        <h1>Admin</h1>
        <table>
          {
            factors.map((factor) => {
              return (
                <tr>
                  <td className="headFactor" key={factor.key}>
                    <span>{ factor.name } ({factor.code})</span>
                    <ul>
                      <li><b>with margins:</b> {factor.with_margins}</li>
                      <li><b>without margins:</b> {factor.without_margins}</li>
                      <li><b>adjusted (/1.2 /1000) with margins:</b> {factor.with_margins_adjusted}</li>
                      <li><b>adjusted (/1.2 /1000) without margins:</b> {factor.without_margins_adjusted}</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {
                        factor.supplier_categories.map((sc) => (<li>{sc}</li>))
                      }
                    </ul>
                  </td>
                </tr>
              )
            })
          }
        </table>
      </div>
  )
}
