import React from 'react'

export default function Selectable(props) {
  return (
    <label className="selectable">
      <input
        type="checkbox"
        name={props.name}
        checked={props.checked}
        onChange={props.onChange || null}
      />
      <span className="selectableText">{props.value}</span>
    </label>
  )
}
