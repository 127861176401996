import React from 'react'

import { useNavigate } from 'react-router-dom'

import { ReactComponent as CheckIcon } from '../../assets/Check.svg'
import SvgIcon from '@mui/material/SvgIcon'
import catMap from '../../lib/catMap'

export default function MiniProgress(props) {

  const catsInOrder = Object.keys(catMap)

  const navigate = useNavigate()

  return (
    <div className="qnnMiniBar">
      <div className="progressHolder">
        <div className="barHolder">

          {
            catsInOrder.map((catName) => {
              const CatIcon = catMap[catName].icon
              if (props.qnnSummary && props.qnnSummary[catName]) {
                const sectionInfo = props.qnnSummary[catName]
                return (

                  <div key={catName} onClick={() => (navigate(`/questionnaire/${catName}`))}>
                    {
                      sectionInfo.progress > 0 && sectionInfo.progress < 100 && (
                        <div className="progressBar">
                          <CatIcon />
                          <div className="status body2">{Math.floor(sectionInfo.progress)}%</div>
                        </div>
                      )
                    }
                    {
                      sectionInfo.progress === 0 && (
                        <div className="progressBar">
                          <CatIcon />
                          <div className="status body2"></div>
                        </div>
                      )
                    }
                    {
                      sectionInfo.progress === 100 && (
                        <div className="progressBar done">
                          <CatIcon />
                          <div className="status"><CheckIcon /></div>
                        </div>
                      )
                    }
                  </div>
                )
              }
            })
          }
        </div>
      </div>
    </div>
  )
}
