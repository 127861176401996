import axios from 'axios'

import MountainSrc from '../../assets/Silvermountain.png'

import { ReactComponent as CheersIcon } from '../../assets/Cheers.svg'

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function TakeAction(props) {

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const key = params.get('key')
    console.log(key)
    axios.post('/api/payments/mark_paid', {key: key}).then((res) => {
      console.log(res)
    })
  }, [])

  const [ calcYear, setCalcYear ] = useState("")


  useEffect(() => {
    axios.get('/api/reports/current/summary').then((res) => {
      setCalcYear(res.data.year)
    })
  }, [])

  const [firstPage, setFirstPage ] = useState(true)

  const navigate = useNavigate()

  const goToDash = () => {
    navigate('/insights/details')
  }

  return (
    <div className="successPay" style={{backgroundImage: `url('${MountainSrc}')`}}>
      {
        firstPage && (
          <div className="message">
            <div className="messageBody">
              <CheersIcon />
              <h5>Sounds like a time for a cheers!</h5>
              <h3>
                You’re officially certified with ClimateHound for {calcYear}.
              </h3>
              <div className="actions">
                <input type="button" value="Next" onClick={() => {setFirstPage(false)}} />
              </div>
            </div>
          </div>
        )
      }
      {
        !firstPage && (
          <div className="message">
            <div className="messageBody">
              <h2>What's Next?</h2>
              <div className="somethingNext">
                <h3>
                  Review your in-depth carbon footprint to learn where you can start in your reduction strategy. 
                </h3>
                <div className="action">
                  <input type="button" value="Go to dashboard" onClick={() => {navigate('/insights/details')}} />
                </div>
              </div>
              <div className="somethingNext">
                <h3>
                  Add your company bio and upload a logo, wordmark, and company photos to your ClimateHound Account.
                </h3>
                <div className="action">
                  <input type="button" value="Upload now" onClick={() => {navigate('/profile/edit')}} />
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
