import React from 'react'

import { ReactComponent as QuestionIcon } from '../assets/Question.svg'
import { useNavigate } from 'react-router-dom'

export default function ViewOnDesktop() {
  const navigate = useNavigate()

  return (
    <div id="remainingHelp">
      <div className="message">
        <div className="icon">
          <QuestionIcon />
        </div>
        <div className="messageBody">
          <h5>You’ve got questions flagged for help.</h5>
          <h3>
            ClimateHound will be in touch soon to help you resolve all questions that were flagged for help.
          </h3>
          <div className="actions">
            <input type="button" value="Next" onClick={() => {navigate('/questionnaire/start')}} />
          </div>
        </div>
      </div>
    </div>
  )
}
