import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CommonLinks from '../CommonLinks'
import { dataYear } from '../../utils/years'

import LabeledField from '../Forms/LabeledField'
import LabeledSelect from '../Forms/LabeledSelect'
import MiniProgress from './MiniProgress'
import { useNavigate } from 'react-router-dom'
import NavButtons from './NavButtons'
import FileUploadComponent from '../Forms/DropUpload'

import axios from 'axios'

export default function Vehicles(props) {

  const [ loading, setLoading ] = useState(false)
  const [ addVehicleOpen, setAddVehicleOpen ] = useState(false)
  const [ addCustomVehicleOpen, setAddCustomVehicleOpen ] = useState(false)
  const [ removeVehicleOpen, setRemoveVehicleOpen ] = useState(false)

  const [ makes, setMakes ] = useState([])
  const [ models, setModels ] = useState([])
  const [ engines, setEngines ] = useState([])

  const [ fuelTypes, setFuelTypes ] = useState([])
  const [ vehicleTypes, setVehicleTypes ] = useState([])
  const [ semiId, setSemiId ] = useState()
  const [ electricId, setElectricId ] = useState()

  const [ vehicles, setVehicles ] = useState([])

  useEffect(() => {
    fetchAndSetVehicles()
  }, [])

  function fetchAndSetVehicles() {
    axios.get('/api/vehicles').then((res) => {
      console.log('vehicles: ', res.data)
      setVehicles(res.data.vehicles)
      setVehicleTypes(res.data.vehicle_types)
      setFuelTypes(res.data.fuel_types)
    })
  }

  useEffect(() => {
    for (let i = 0; i < vehicleTypes.length; i++) {
      if (vehicleTypes[i].name === "Semi truck") {
        setSemiId(vehicleTypes[i].id)
      }
    }
  }, [vehicleTypes])

  useEffect(() => {
    for (let i = 0; i < fuelTypes.length; i++) {
      if (fuelTypes[i].name === "Electric") {
        setElectricId(`${fuelTypes[i].id}`)
      }
    }
  }, [fuelTypes])

  const defaultVehicle = {
    year: '',
    make: '',
    model: '',
    engine: '',
    mileage: '',
    fuelType: '',
    epa_data: {},
    mpg: ''
  }
  const [ vehicle, setVehicle ] = useState(defaultVehicle)
  const [ vehicleErrors, setVehicleErrors ] = useState({})
  const [ customVehicleErrors, setCustomVehicleErrors ] = useState({})

  const defaultCustomVehicle = {
    name: '',
    vehicle_type_id: '',
    year: '',
    fuel_type_id: '',
    mpg: '',
    kWh_per_100_miles: '',
    mileage: ''
  }
  const [ customVehicle, setCustomVehicle ] = useState([])

  const navigate = useNavigate()

  const openAddVehicle = () => {
    setAddVehicleOpen(true)
  }

  const openAddCustomVehicle = (isSemi) => {
    let customVehicle = {...defaultCustomVehicle}
    if (isSemi) {
      customVehicle = {...customVehicle, vehicle_type_id: semiId}
    }
    setCustomVehicle(customVehicle)
    setAddVehicleOpen(false)
    setAddCustomVehicleOpen(true)
  }

  const vehicleChange = (e) => {
    if (e.target.name === "mileage" || e.target.name === "mpg") {
      if (!/^\d*$/.test(e.target.value)) {
        return
      }
    }

    const newVehicle = {...vehicle}
    newVehicle[e.target.name] = e.target.value
    setVehicle(newVehicle)

    if (e.target.name === "year") {
      setModels([])
      setEngines([])
      setLoading(true)
      fetch(
        `https://www.fueleconomy.gov/ws/rest/vehicle/menu/make?year=${e.target.value}`,
        {headers: {Accept: "application/json"}}
        ).then((res) => (res.json())).then((json) => {
          setMakes(optionsFromApiRes(json))
          setLoading(false)
        })
    }

    if (e.target.name === "make") {
      setEngines([])
      setLoading(true)
      fetch(
        `https://www.fueleconomy.gov/ws/rest/vehicle/menu/model?year=${vehicle.year}&make=${e.target.value}`,
        {headers: {Accept: "application/json"}}
        ).then((res) => (res.json())).then((json) => {
          setModels(optionsFromApiRes(json))
          setLoading(false)
        })
    }

    if (e.target.name === "model") {
      setLoading(true)
      fetch(
        `https://www.fueleconomy.gov/ws/rest/vehicle/menu/options?year=${vehicle.year}&make=${vehicle.make}&model=${e.target.value}`,
        {headers: {Accept: "application/json"}}
        ).then((res) => (res.json())).then((json) => {
          setEngines(optionsFromApiRes(json))
          setLoading(false)
        })
    }

    if (e.target.name === "engine") {
      console.log('teehee')
    }
  }

  const customVehicleChange = (e) => {
    if (e.target.name === "mileage" || e.target.name === "mpg" || e.target.name === "kWh_per_100_miles") {
      if (!/^\d*$/.test(e.target.value)) {
        return
      }
    }
    const newCustomVehicle = {...customVehicle}
    newCustomVehicle[e.target.name] = e.target.value
    setCustomVehicle(newCustomVehicle)
  }

  function optionsFromApiRes(vehiclesJSON) {
    // the EPA API returns single items as not wrapped in an array for some reason
    const data = Array.isArray(vehiclesJSON.menuItem) ? vehiclesJSON.menuItem : [vehiclesJSON.menuItem]
    return data.map((d) => ({label: d.text, value: d.value}))
  }

  useEffect(() => {
    if (vehicle.engine && vehicle.engine.length > 0) {
      setLoading(true)
      fetch(
        `https://www.fueleconomy.gov/ws/rest/vehicle/${vehicle.engine}`,
        {headers: {Accept: "application/json"}}
        ).then((res) => (res.json())).then((json) => {
          console.log('vehicle data', json)
          const newVehicle = {
            ...vehicle,
            mpg: json.comb08.toString(),
            fuelType: json.fuelType1,
            epa_data: json
          }
          setVehicle(newVehicle)
          setLoading(false)
        })
    }
  }, [vehicle.engine])

  const years = []

  for (let i = new Date().getFullYear(); i > 1983; i--) {
    years.push({label: i.toString(), value: i.toString()})
  }

  const vehicleName = () => (`${vehicle.year} ${vehicle.make} ${vehicle.model}`)

  const addVehicle = () => {
    setLoading(true)
    const fuelCommonNames = ['Gasol', 'Dies', 'Elec']
    let dbFuelType
    for (let i = 0; i < fuelCommonNames.length; i++) {
      if (vehicle.fuelType.indexOf(fuelCommonNames[i]) > -1) {
        dbFuelType = fuelTypes.find((ft) => (ft.name.indexOf(fuelCommonNames[i]) > -1))
      }
    }
    console.log('adding ride', vehicle)
    const vehicleAPIPayload = {
      name: vehicleName(),
      year: vehicle.year,
      mpg: parseInt(vehicle.mpg),
      epa_data: JSON.stringify(vehicle.epa_data),
      vehicle_type_id: vehicleTypes[0].id,
      fuel_type_id: dbFuelType.id,
      mileage: vehicle.mileage
    }
    axios.post('/api/vehicles', vehicleAPIPayload).then((res) => {
      fetchAndSetVehicles()
      clearAndClose()
    })
  }

  const addCustomVehicle = () => {
    setLoading(true)
    const fuelCommonNames = ['Gasol', 'Dies', 'Elec']
    console.log('adding ride', customVehicle)
    const vehicleAPIPayload = customVehicle
    axios.post('/api/vehicles', vehicleAPIPayload).then((res) => {
      fetchAndSetVehicles()
      clearAndClose()
    })
  }

  const clearAndClose = () => {
    setVehicle(defaultVehicle)
    setCustomVehicle(defaultCustomVehicle)
    setAddVehicleOpen(false)
    setAddCustomVehicleOpen(false)
    setLoading(false)
  }

  function removeVehicle(id) {
    console.log(id)
    setRemoveVehicleOpen(false)
    axios.delete('/api/vehicles/'+id).then((res) => {
      console.log('done')
      fetchAndSetVehicles()
    })
  }

  let [ removalVehicle, setRemovalVehicle ] = useState({})

  const popRemovalModal = (v) => {
    setRemovalVehicle(v)
    setRemoveVehicleOpen(true)
  }

  const vehicleListDom = vehicles.map((vehicle) => {
    return (
      <div className="vehicle" key={vehicle.id}>
        <h4>{vehicle.name}</h4>
        <input type="button" className="skeleton destructive" value="Remove" onClick={() => (popRemovalModal(vehicle))} />
      </div>
    )
  })

  const submit = () => {
    return axios.post(`/api/questions/${props.question.id}/mark_a`)
  }

  function nextEnabled() {
    return vehicles.length > 0
  }

  const isSemi = customVehicle.vehicle_type_id === semiId
  const isElectric = customVehicle.fuel_type_id === electricId

  return (
    <div>
      <div>
        <div className="vehicleList">
          { vehicleListDom }
        </div>
        <div className="questionInputs">
          <div className="vehicles">
            <input type="button" className="small gray" onClick={openAddVehicle} value="Search vehicle type" />
            <input type="button" className="small gray" onClick={() => {openAddCustomVehicle(true)}} value="Add semi-truck" />
            <input type="button" className="small gray" onClick={() => {openAddCustomVehicle(false)}} value="Add custom vehicle" />
          </div>
        </div>
      </div>


      <div className={`modalContainer ${addVehicleOpen ? 'open' : 'closed'}`}>
        <div className="grayOverlayHitzone" onClick={clearAndClose}>
        </div>
        <div className={`${loading ? 'loading' : ''} modal`}>
          <div className="modalLoading">
            <div className="lds-facebook"><div></div><div></div><div></div></div>
          </div>
          <header>
            <h3>Add a vehicle</h3>
            <div className="body3">Please add all details below to calculate your vehicle’s impact.</div>
          </header>
          <div className="vehicleSearchForm">
            <LabeledSelect
              name="year"
              className="small"
              label="Year"
              placeholder="Select a year"
              values={vehicle}
              errors={vehicleErrors}
              onChange={vehicleChange}
              options={years}
            />
            <LabeledSelect
              name="make"
              className="small"
              label="Make"
              placeholder="Select a make"
              values={vehicle}
              errors={vehicleErrors}
              onChange={vehicleChange}
              options={makes}
            />
            <LabeledSelect
              name="model"
              label="Model"
              className="small"
              placeholder="Select a model"
              values={vehicle}
              errors={vehicleErrors}
              onChange={vehicleChange}
              options={models}
            />
            <LabeledSelect
              name="engine"
              label="Engine Size"
              className="small"
              placeholder="Select an engine size"
              values={vehicle}
              errors={vehicleErrors}
              onChange={vehicleChange}
              options={engines}
            />
            <LabeledField
              name="mileage"
              label={"Mileage driven in " + dataYear(props.question)}
              placeholder={`Total ${dataYear(props.question)} miles`}
              values={vehicle}
              errors={vehicleErrors}
              onChange={vehicleChange}
            />
          </div>
          <div className="modalActions">
            <input type="button" onClick={addVehicle} className="positive" value="Add vehicle" />
            <input type="button" onClick={clearAndClose} value="Cancel" />
          </div>
        </div>
      </div>






        <div className={`modalContainer ${addCustomVehicleOpen ? 'open' : 'closed'}`}>
        <div className="grayOverlayHitzone" onClick={clearAndClose}>
        </div>
        <div className={`${loading ? 'loading' : ''} modal`}>
          <div className="modalLoading">
            <div className="lds-facebook"><div></div><div></div><div></div></div>
          </div>
          <header>
            <h3>{isSemi ? "Add semi-truck" : "Add custom vehicle"}</h3>
            <div className="body3">
              { isSemi ? "Please add all details available to calculate your semi-truck impact." : "Please add all details available to calculate your custom vehicle impact." }
            </div>
          </header>
          <div className="vehicleSearchForm">
            <LabeledField
              name="name"
              label="Vehicle name"
              placeholder="Name"
              values={customVehicle}
              errors={customVehicleErrors}
              onChange={customVehicleChange}
            />
            {
              !isSemi && (
                <LabeledSelect
                  name="vehicle_type_id"
                  label="Vehicle Type"
                  className="small"
                  placeholder="Type"
                  values={customVehicle}
                  errors={customVehicleErrors}
                  onChange={customVehicleChange}
                  options={vehicleTypes.filter((vt) => (vt.id !== semiId)).map((vt) => {
                    return (
                      {
                        value: vt.id,
                        label: vt.name
                      }
                    )
                  }) }
                />
              )
            }

            <LabeledSelect
              name="year"
              label="Year"
              className="small"
              placeholder="Select a year"
              values={customVehicle}
              errors={customVehicleErrors}
              onChange={customVehicleChange}
              options={years}
            />
            <LabeledSelect
              name="fuel_type_id"
              label="Fuel Type"
              className="small"
              placeholder="Fuel Type"
              values={customVehicle}
              errors={customVehicleErrors}
              onChange={customVehicleChange}
              options={isSemi ? fuelTypes.filter((ft) => (ft.name.indexOf('Gas') === -1)).map((ft) => {
                return (
                  {
                    value: ft.id,
                    label: ft.name
                  }
                )
              }) : fuelTypes.map((ft) => {
                return (
                  {
                    value: ft.id,
                    label: ft.name
                  }
                )
              })}
            />
            {
              !isElectric && (
                <LabeledField
                  name="mpg"
                  label="Miles per gallon"
                  placeholder={isSemi ? "Value (on average 6mpg)" : "Value"}
                  values={customVehicle}
                  errors={customVehicleErrors}
                  onChange={customVehicleChange}
                />
              )
            }
            {
              isElectric && (
                <LabeledField
                  name="kWh_per_100_miles"
                  label="kWh per 100 miles"
                  placeholder="Value"
                  values={customVehicle}
                  errors={customVehicleErrors}
                  onChange={customVehicleChange}
                />
              )
            }
            <LabeledField
              name="mileage"
              label={"Mileage driven in " + dataYear(props.question)}
              placeholder={`Total ${dataYear(props.question)} miles`}
              values={customVehicle}
              errors={customVehicleErrors}
              onChange={customVehicleChange}
            />
          </div>
          <div className="modalActions">
            <input type="button" onClick={addCustomVehicle} className="positive" value="Add vehicle" />
            <input type="button" onClick={clearAndClose} value="Cancel" />
          </div>
        </div>
      </div>

      <div className={`modalContainer centered ${removeVehicleOpen ? 'open' : 'closed'}`}>
        <div className="grayOverlayHitzone" onClick={() => (setRemoveVehicleOpen(false))}>
        </div>
        <div className={`${loading ? 'loading' : ''} modal`}>
          <div className="modalLoading">
            <div className="lds-facebook"><div></div><div></div><div></div></div>
          </div>
          <header>
            <h3>Remove vehicle?</h3>
            <div className="body3">Are you sure you want to remove {removalVehicle.name}?</div>
          </header>
          <div className="modalActions">
            <input type="button" onClick={() => { removeVehicle(removalVehicle.id)}} className="destructive" value="Remove" />
            <input type="button" onClick={() => ( setRemoveVehicleOpen(false)) } value="Cancel" />
          </div>
        </div>
      </div>
      <FileUploadComponent question={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
      />
    </div>
  )
}
