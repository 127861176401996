const reduction_strategy_steps = {
  'null-strategy': [
    'This is the first step. Try and remember that.',
    'This is the step that is after the first.',
    'We did not expect you to make it this far tbh!'
  ],
  'pv-panels': [
    <span><b>1. Locate solar provider: </b>Find your local solar provider and request an estimate based on the % of your electricity needs you want to meet through solar PV.</span>,
    <span><b>2. Ask about incentives: </b>Your solar provider should be well-versed on rebates and tax-incentives in your city. </span>,
    <span><b>3. Update assumptions: </b>Once you have your estimate, update the assumptions to better represent your actual quote and potential impact.</span>,
    <span><b>4. Finalize installation: </b>Once the install is finished and you have your invoice, set the reduction to completed and include the final cost/watt.</span>
  ],
  'work-from-home': [
    <span><b>Step 1:</b> Identify employees that can work remotely at least part-time.</span>,
    <span><b>Step 2: </b>Encourage employees to do administrative tasks away from the office during business hours.</span>,
    <span><b>Step 3: </b>Start your work from home program.</span>
  ],
  'eco-driving': [
    <span><b>1. Educate employees: </b>Prepare a workshop (even a simple email) explaining eco-driving, its benefits for the environment, and cost savings.</span>,
    <span><b>2. Policy implementation: </b>Encouraging eco-driving in your employee handbook or onboarding materials, including speed limits and fuel efficiency guidelines for company vehicles.</span>,
    <span><b>3. Monitoring system: </b>Consider installing monitoring systems in company vehicles to track speed and fuel efficiency.</span>,
    <span><b>4. Reward system: </b>Consider a reward system for employees who consistently follow eco-driving practices.</span>,
  ],
}

export default reduction_strategy_steps
