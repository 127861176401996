import React from 'react'

import ValuesSection from "./ValuesSection";

export default function PVPanelValues(props) {


  const inputFieldDom = (name, parser, unit) => {
    return (
      <span className={unit === '%' ? 'percentUnit' : unit === '$' ? 'dollarUnit' : ""}>
        <input
          type="text"
          name={name}
          value={props.inputs[name] || ""}
          onChange={(e) => {
            const newVal = parser ? parser(e.target.value) : e.target.value;
            props.updateDraft(name, newVal);
          }}
        />
        {unit && <span>{unit}</span>}
      </span>
    );
  };

  const staticFieldDom = (name, parser, values) => {
    return (
      <span>
        <b>{parser(props.inputs[name])}</b>
      </span>
    );
  };

  const passThru = (x) => x;

  return (
    <>
      <ValuesSection name="values">
        <div className="body4">
          Complete the fields below to simulate how your business would benefit from this initiative.
        </div>
        My company uses {staticFieldDom("kwh_per_year", parseInt)} kilowatt
        hours (kWh) of electricity per year, which produces{" "}
        {staticFieldDom("kg_co2e_per_year", (kg) => parseInt(kg/1000))} metric tonnes of CO2e annually. 
        We will meet {inputFieldDom("pv_percent", parseInt, '%')} of our
        electricity needs by installing solar panels, which costs <b>$</b>
        {staticFieldDom("pv_dollar_cost_per_watt", parseFloat)} per watt of solar
        panel.
      </ValuesSection>
      <ValuesSection name="completed-values">

        My company uses {staticFieldDom("kwh_per_year", parseInt)} kilowatt
        hours (kWh) of electricity per year, which produces{" "}
        {staticFieldDom("kg_co2e_per_year", (kg) => parseInt(kg/1000))} metric tonnes of CO2e per
        year. We met {staticFieldDom("pv_percent", parseInt)}<b>%</b> of our electricity
        needs by installing solar PV panels. It costs <b>$</b>
        {staticFieldDom("pv_dollar_cost_per_watt", parseFloat)} per watt of solar
        panel.
      </ValuesSection>
      <ValuesSection name="confirm-values">
        <div className="body4">
          Review the information below and confirm or update all values to match the final outcome of implementing this 
          initiative. Ensure that all information is accurate before completing this reduction.        
        </div>
        My company uses {staticFieldDom("kwh_per_year", parseInt)} kilowatt
        hours (kWh) of electricity per year, which produces{" "}
        {staticFieldDom("kg_co2e_per_year", (kg) => parseInt(kg/1000))} metric tonnes of CO2e per
        year. We are now meeting {inputFieldDom("pv_percent", parseInt, '%')} of our electricity
        needs with this solar installation which costs 
        {inputFieldDom("pv_dollar_cost_per_watt", parseFloat, '$')} per watt of solar
        panel.
      </ValuesSection>
      <ValuesSection name="assumptions-final">
        <ul>
          <li className="hideUponCompletion">
            Average photovoltaic cost/watt:{" "}
            <span>{staticFieldDom("pv_dollar_cost_per_watt", parseFloat, '$')}</span>
          </li>
          <li >
            Average price of electricity:{" "}
            <span>{staticFieldDom("dollar_cost_per_grid_kwh", parseFloat, '$')} per watt</span>
          </li>
          <li>
            Average lifetime of PV system:{" "}
            <span>{staticFieldDom("lifetime_years", parseInt)} years</span>
          </li>
        </ul>
      </ValuesSection>
      <ValuesSection name="assumptions">
        <ul>
          <li className="hideUponCompletion">
            Average photovoltaic cost/watt:{" "}
            <span>{inputFieldDom("pv_dollar_cost_per_watt", parseFloat, '$')}</span>
          </li>
          <li >
            Average price of electricity:{" "}
            <span>{inputFieldDom("dollar_cost_per_grid_kwh", parseFloat, '$')}{" "}per watt</span>
          </li>
          <li>
            Average lifetime of PV system:{" "}
            <span>{inputFieldDom("lifetime_years", parseInt)} years</span>
          </li>
        </ul>
      </ValuesSection>
    </>
  );
}
