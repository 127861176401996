import React, { useEffect, useState } from 'react'
import { Route, Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import AdminFrame from '../AdminFrame'

import { cloneDeep } from 'lodash'
import moment from 'moment'
import axios from 'axios'

export default function AdminWaste() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ drafts, setDrafts ] = useState([])


  const [ wasteContents, setWasteContents ] = useState({waste_contents: []})
  useEffect(() => {
    axios.get('/api/waste_contents').then((res) => {
      console.log('sdfon')
      console.log(res.data.waste_contents)
      setWasteContents(res.data)
    })
  }, [])

  const [ wasteDisposalMethods, setWasteDisposalMethods ] = useState({waste_disposal_methods: []})
  useEffect(() => {
    axios.get('/api/waste_disposal_methods').then((res) => {
      setWasteDisposalMethods(res.data)
    })
  }, [])

  const [ wasteContentDisposals, setWasteContentDisposals ] = useState({waste_content_disposals: []})
  useEffect(() => {
    axios.get('/api/waste_content_disposals').then((res) => {
      setWasteContentDisposals(res.data)
    })
  }, [])


  const updateWasteContentDisposal = (wcdId) => {
    const wcd = wasteContentDisposals.waste_content_disposals.find((wcd) => (wcd.id === wcdId))
    axios.put(`/api/waste_content_disposals/${wcdId}`, {waste_content_disposal: wcd}).then((res) => {
      console.log('success')
    })
  }

  const updateWcd = (event, wcdId) => {
    const newWasteContentDisposals = cloneDeep(wasteContentDisposals)
    for (const idx in newWasteContentDisposals.waste_content_disposals) {
      if (newWasteContentDisposals.waste_content_disposals[idx].id === wcdId) {
        newWasteContentDisposals.waste_content_disposals[idx][event.target.name] = event.target.value
      }
    }
    setWasteContentDisposals(newWasteContentDisposals)
  }


  return(
      <div className="adminPage">

        <header className="pageHeader">
          <h1>Waste Review</h1>
        </header>

        <div className="table">
          <table>
            <thead>
              <tr>
                <th></th>
                {
                  wasteDisposalMethods.waste_disposal_methods.map((wdm) => {
                    return (
                      <th key={wdm.id}>{wdm.name}</th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              { wasteContents.waste_contents.map((wasteContent) => (
                <tr>
                  <th>{wasteContent.name}</th>
                  {
                    wasteDisposalMethods.waste_disposal_methods.map((wdm) => {
                      const wcd = wasteContentDisposals.waste_content_disposals.find((wcd) => (wcd.waste_content.id === wasteContent.id && wcd.waste_disposal_method.id === wdm.id))
                      return (
                        <td key={wdm.id}>
                          { wcd && (
                            <div>
                              <label>{wcd.waste_disposal_method.name}</label>
                              <label>{wcd.waste_content.name} </label>
                              <input type="text" name="mtco2e_per_short_ton" value={wcd.mtco2e_per_short_ton} onChange={(e) => (updateWcd(e, wcd.id))} />
                              <button onClick={() => {updateWasteContentDisposal(wcd.id)}}>update 🤭</button>
                            </div>
                          )}
                        </td>
                      )
                    })
                  }
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
  )
}
