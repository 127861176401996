import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import LabeledField from '../../Forms/LabeledField'
import Selectable from '../../Forms/Selectable'
import MiniProgress from '../MiniProgress'
import NavButtons from '../NavButtons'
import { useNavigate } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import FileUploadComponent from '../../Forms/DropUpload'

import Toggler from '../../Forms/Toggler'

import axios from 'axios'

export default function CategorySupplierSpends(props) {

  const [ suppliers, setSuppliers ] = useState([])
  const [ amounts, setAmounts ] = useState({})
  const [ errors, setErrors ] = useState({})
  const [ totals, setTotals ] = useState({})
  const [ isPercent, setIsPercent ] = useState(false)

  useEffect(() => {
    axios.get(
      '/api/suppliers/by_question_category', {
        params: {
          question_category_id: props.question.question_category_id
        }}).then((res) => {
      const currentSuppliers = res.data
      console.log(currentSuppliers)

      const dbIsPercent = currentSuppliers[0]?.supplier_category_spends[0]?.is_percent
      setIsPercent(dbIsPercent)

      const currentAmounts = amountsFromSuppliers(currentSuppliers)
      console.log('amounts', currentAmounts)

      const currentTotals = totalsFromAmounts(currentAmounts)
      console.log('totes', currentTotals)

      setTotals(currentTotals)
      setAmounts(currentAmounts)
      setSuppliers(currentSuppliers)
    })
  }, [])

  function amountsFromSuppliers(suppliers) {
    const amounts = {}
    for (let i = 0; i < suppliers.length; i++) {
      const supplier = suppliers[i]
      const spends = supplier.supplier_category_spends
      for (let j = 0; j < spends.length; j++) {
        const amount = spends[j].amount ? parseInt(spends[j].amount) : ''
        amounts[spends[j].id] = {
          supplierId: supplier.id,
          amount: amount
        }
      }
    }
    return amounts
  }

  function totalsFromAmounts(amounts) {
    let currentTotals = {}
    for (const catId in amounts) {
      const supId = amounts[catId].supplierId
      const amount = `${amounts[catId].amount}`.length === 0 ? 0 : parseInt(amounts[catId].amount)
      if (currentTotals[supId]) {
        currentTotals[supId] = currentTotals[supId] + amount
      } else {
        currentTotals[supId] = amount
      }
    }
    return currentTotals
  }

  const updateSpend = (scsId, e) => {
    if (!/^\d+$/.test(e.target.value) && (e.target.value.length !== 0)) {
      return
    }

    const newAmounts = JSON.parse(JSON.stringify(amounts))
    if (e.target.value.length === 0) {
      newAmounts[scsId][e.target.name] = ''
    } else {
      newAmounts[scsId][e.target.name] = parseInt(e.target.value)
    }

    const newTotals = totalsFromAmounts(newAmounts)

    console.log('new totes', newTotals)
    setTotals(newTotals)
    setAmounts(newAmounts)
    console.log(e.target.name)
  }

  const remainingSpendText = (targetSpend, currentSpend) => {
    const ts = isPercent ? 100 : parseInt(targetSpend)
    const cs = parseInt(currentSpend)
    if (ts === cs) {
      if (isPercent) {
        return '0% remaining'
      } else {
        return '$0 remaining'
      }
    }
    if (cs < ts) {
      if (isPercent) {
        return `${ts - cs}% remaining`
      } else {
        return `$${ts - cs} remaining`
      }
    }
    if (cs > ts) {
      if (isPercent) {
        return `${cs - ts}% over`
      } else {
        return `$${cs - ts} over`
      }
    }
  }

  const submit = () => {
    const payload = {
      supplier_spend_amounts: amounts,
      is_percent: isPercent
    }

    if (nextEnabled()) {
      return axios.post('/api/suppliers/set_supplier_spends', payload)
    }
  }

  function nextEnabled() {
    for (let i = 0; i < suppliers.length; i++) {
      const supplier = suppliers[i]
      const spendTarget = isPercent ? 100 : parseInt(supplier.total_spend)
      if ((spendTarget - totals[supplier.id]) !== 0) {
        return false
      }
    }
    return true
  }

  function resetAmounts() {
    const emptyAmounts = JSON.parse(JSON.stringify(amounts))
    for (const cscId in emptyAmounts) {
      emptyAmounts[cscId]['amount'] = ''
    }
    setAmounts(emptyAmounts)
    const newTotals = totalsFromAmounts(emptyAmounts)
    setTotals(newTotals)
  }

  const toggleInputType = () => {
    const totalTotal = Object.values(totals).reduce((running, i) => (running + i), 0)
    if (totalTotal > 0) {
      if (window.confirm("Are you sure? Current values will be lost.")) {
        setIsPercent(!isPercent)
        resetAmounts()
      } else {
        console.log('no toggle')
      }
    } else {
      setIsPercent(!isPercent)
    }
  }

  return (
    <div>
      <div className="questionInputs">
        <div className="inputSwitcher kitchen">
          <Toggler
            checked={isPercent}
            onChange={toggleInputType}
            className='thin'
            labels={['$', '%']}
          />
        </div>
        <div className="productSelections supCatSpends">
          {
            suppliers.map((supplier, idx) => {
              return (
                <div className="productSelector" key={idx}>
                  <header>
                    <div className="body3">{supplier.name}</div>
                    <div className="body3">${supplier.total_spend} total</div>
                  </header>
                  <div className="categories">
                    <div className="spend">
                      <div className="spendLabel body4"></div>
                      <div className="spendAmount">
                        <div className="micro">Amount</div>
                      </div>
                    </div>
                    {
                      supplier.supplier_category_spends.map((scs) => {
                        return (
                          <div className="spend" key={scs.id}>
                            <div className="spendLabel body4">{scs.supplier_category_name}</div>
                            <div className="spendAmount">
                              <LabeledField
                                name={'amount'}
                                formErrors={{}}
                                values={amounts[scs.id]}
                                onChange={(e) => (updateSpend(scs.id, e))}
                                placeholder={isPercent ? '%' : '$'}
                              />
                            </div>
                          </div>
                        )
                      })
                    }
                    <div className="remaining">
                      <div className={`micro ${((isPercent ? 100 : parseInt(supplier.total_spend)) - totals[supplier.id]) !== 0 ? 'error' : ''}`}>
                        { remainingSpendText(supplier.total_spend, totals[supplier.id]) }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <FileUploadComponent question={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
      />
    </div>
  )
}
