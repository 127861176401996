import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import LabeledField from '../../Forms/LabeledField'
import Selectable from '../../Forms/Selectable'
import MiniProgress from '../MiniProgress'
import NavButtons from '../NavButtons'
import { useNavigate } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import FileUploadComponent from '../../Forms/DropUpload'

import { ReactComponent as Xsvg } from '../../../assets/X.svg'

import axios from 'axios'

export default function SupplierSpends(props) {

  const emptySupplier = {id: 'i0', name: '', total_spend: ''}
  const [ suppliers, setSuppliers ] = useState([emptySupplier])
  const [ errors, setErrors ] = useState({})

  useEffect(() => {
    axios.get(
      '/api/suppliers/by_question_category', {
        params: {
          question_category_id: props.question.question_category_id
        }}).then((res) => {
      if (res.data.length > 0) {
        setSuppliers(res.data)
        console.log(res.data)
      }
    })
  }, [])

  const updateSupplier = (id, e) => {
    setErrors({})
    const newSuppliers = cloneDeep(suppliers)
    const idx = newSuppliers.findIndex((s) => (s.id === id))
    const newSupplier = {...newSuppliers[idx]}
    console.log('we have', newSupplier)
    newSupplier[e.target.name] = e.target.value

    newSuppliers.splice(idx, 1, newSupplier)

    setSuppliers(newSuppliers)
  }

  const addSupplier = () => {
    setErrors({})
    const newSupplierArray = cloneDeep(suppliers)
    const newSupplier = {...emptySupplier}

    newSupplierArray.splice(newSupplierArray.length, 0, {...newSupplier, id: "i" + newSupplierArray.length})

    setSuppliers(newSupplierArray)
  }

  const removeSupplier = (id) => {
    let newSupplierArray = cloneDeep(suppliers)

    const idx = newSupplierArray.findIndex((s) => (s.id === id))
    console.log(id)
    console.log(newSupplierArray)

    if (newSupplierArray[idx].supplier_category_spends && newSupplierArray[idx].supplier_category_spends.length > 0) {
      if (!window.confirm(`This supplier has associated spends. Are you sure you want to remove it?`)) {
        return
      }
    }

    if ((`${id}`).indexOf('i') === -1) {
      axios.delete('/api/suppliers/' + id).then((res) => {
        console.log(res)
      })
    }

    newSupplierArray.splice(idx, 1)

    for (let i = 0; i < newSupplierArray.length; i++) {
      if ((`${newSupplierArray[i].id}`).indexOf('i') > -1) {
        newSupplierArray[i].id = "i" + i
      }
    }

    console.log(newSupplierArray)

    setSuppliers(newSupplierArray) 
  }

  const submit = () => {
    console.log(suppliers)
    setErrors({})
    const newErrors = {}
    suppliers.forEach((supplier, idx) => {
      let id = supplier.id ? supplier.id : 'i'+idx
      const supplierErrors = {}

      for (let i = 0; i < idx; i++) {
        if (suppliers[i].name === supplier.name) {
          supplierErrors['name'] = "You’ve already used this supplier name."
        }
      }
      if (supplier.name.length === 0) {
        supplierErrors['name'] = "Required"
      }
      if (!(/^\d+$/.test(supplier.total_spend))) {
        supplierErrors['total_spend'] = "Please enter whole number."
      }
      if (supplier.total_spend.length === 0) {
        supplierErrors['total_spend'] = "Required"
      }
      if (Object.keys(supplierErrors).length > 0) {
        newErrors[id] = supplierErrors
      }
    })

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      console.log('Errors:', newErrors)
    } else {
      console.log('No errors, ready to submit')
      const payload = {
        suppliers: suppliers,
        question_category_id: props.question.question_category_id
      }
      console.log(props.question)
      console.log(payload)
      return axios.post('/api/suppliers', payload)
    }

  }

  function nextEnabled() {
    if (suppliers.length === 0) {
      return false
    }
    for (let i = 0; i < suppliers.length; i++) {
      if (!suppliers[i].name || suppliers[i].name.length === 0) {
        return false
      }
      if (!suppliers[i].total_spend || suppliers[i].total_spend.length === 0) {
        console.log('no spend')
        return false
      }
    }
    return true
  }

  return (
    <div>
      <div className="questionInputs bulkSuppliers">
        <div>
          {
            suppliers.map((supplier) => {
              return (
                <div className="supplier" key={supplier.id}>
                  <div className="fields">
                    <div className="name">
                      <LabeledField
                        name="name"
                        label="Supplier Name"
                        formErrors={errors[supplier.id]}
                        values={supplier}
                        onChange={(e) => {updateSupplier(supplier.id, e)}}
                        placeholder="Name"
                      />
                    </div>
                    <div className="spend">
                      <LabeledField
                        name="total_spend"
                        label="Amount"
                        formErrors={errors[supplier.id]}
                        values={supplier}
                        onChange={(e) => {updateSupplier(supplier.id, e)}}
                        placeholder="$"
                      />
                    </div>
                    <div
                      className="removeButton"
                      onClick={() => {removeSupplier(supplier.id)}}
                    >
                      <Xsvg />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="addButtonHolder">
          <input type="button" className="gray addButton" value="Add another" onClick={addSupplier} />
        </div>

      </div>
      <FileUploadComponent question={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
      />
    </div>
  )
}
