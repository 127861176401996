import React from 'react'

import classnames from 'classnames'

export default function IconButton(props) {
  let className = classnames(props.className, {disabled: props.disabled})
  return (
    <div className={className} onClick={props.disabled ? null : props.onClick}>
      {props.position === 'left' &&
        (
          <div className="icon left">
            {props.icon}
          </div>
        )
      }
      { props.position !== 'solo' && (<input disabled={props.disabled} className="medium" type="button" value={props.value} />) }
      {props.position === 'solo' &&
        (
          <div className="icon">
            {props.icon}
          </div>
          )
      }      {props.position === 'right' &&
        (
          <div className="icon right">
            {props.icon}
          </div>
          )
      }
    </div>   
  )
}
