import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import catMap from '../../lib/catMap'

import { ReactComponent as CalendarIcon } from '../../assets/Calendar.svg'
import { ReactComponent as PageIcon } from '../../assets/Page.svg'
import { ReactComponent as QuestionIcon } from '../../assets/Question.svg'
import { ReactComponent as ClockIcon } from '../../assets/Clock.svg'

import MiniProgress from './MiniProgress'
import ViewOnDesktop from '../ViewOnDesktop'

import axios from 'axios'
import { dataYear } from '../../utils/years'

export default function Question(props) {

  const [ qnnSummary, setQnnSummary ] = useState({})
  const { firstQuestionId, categorySlug } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    axios.get('/api/questionnaires/current/summary').then((res) => {
      setQnnSummary(res.data)
      console.log(res.data)
    })
  }, [])

  const catData = {
    'business-info': {
      subtext: "Some high-level basics to create your baseline.",
      details: `All questions refer to ONLY your ${dataYear(qnnSummary)} activity, facilities, and resources.`,
      data: "Be ready to pull up information on the square footage, employee count, employee time commitment, and gross revenue, which is used to create your emissions baseline.",
      questions: "Most questions require an answer, but you can input “0” or select skip for irrelevant questions. Use the help button for additional info or to ask a question and keep moving. One of our experts will be in touch soon.",
      time: "This section takes about 1 hour, often less, to complete during your first year’s calculation, and less each year moving forward. Your progress will be saved along the way if you need to do one section at a time or come back later."
    },
    'distribution-and-travel': {
      subtext: "This section covers distribution of finished products and employee travel.",
      details: `All questions refer to ONLY your ${dataYear(qnnSummary)} distribution, postal service expenses, company flights, and employee commutes. `,
      data: "Be ready to pull up information on company vehicles, third-party distribution expenses, postal service fees, flight purchases, and approximate employee commute distances.",
      questions: "Most questions require an answer, but you can input “0” or select skip for irrelevant questions. Use the help button for additional info or to ask a question and keep moving. One of our experts will be in touch soon.",
      time: "This section takes about 1 to 2 hours to complete depending on your bookkeeping. Your progress will be saved along the way if you need to do one section at a time or come back later."
    },
    'waste-management': {
      subtext: "Let’s dive into waste so we can figure out how to optimize these channels. ",
      details: `All questions refer to ONLY your ${dataYear(qnnSummary)} waste channels.`,
      data: "Be ready to pull up information on, or estimate approximate, waste that was either landfilled, recycled, or diverted to a material process for reuse.",
      questions: "Most questions require an answer, but you can input “0” or select skip for irrelevant questions. Use the help button for additional info or to ask a question and keep moving. One of our experts will be in touch soon.",
      time: "This section usually takes about 1 to 2 hours to complete. Your progress will be saved along the way if you need to do one section at a time or come back later."
    },
    'energy-usage': {
      subtext: "Let’s look into your utilities consumption and how they are produced.",
      details: `All questions refer to ONLY your ${dataYear(qnnSummary)} utility consumption.`,
      data: "Be ready to pull up information on utility purchases like electricity, natural gas, water, and steam. We’ll also cover solar energy if your produce any on site.",
      questions: "Most questions require an answer, but you can input “0” or select skip for irrelevant questions. Use the help button for additional info or to ask a question and keep moving. One of our experts will be in touch soon.",
      time: "This section usually takes around 1 hour to complete. Your progress will be saved along the way if you need to do one section at a time or come back later."
    },
    'procurement': {
      subtext: "Now we’re getting into the bulk of it. :)",
      details: `All questions refer to ONLY your ${dataYear(qnnSummary)} consumption of purchased goods.`,
      data: "Be ready to pull up purchase totals for the materials and inputs used to create your products and serve your customers.",
      questions: "Most questions require an answer, but you can input “0” or select skip for irrelevant questions. Use the help button for additional info or to ask a question and keep moving. One of our experts will be in touch soon.",
      time: "This section really comes down to your bookkeeping. There are more questions here than in other sections but they are generally straight forward. Your progress will be saved along the way if you need to do one section at a time or come back later."
    },
    'kitchen': {
      subtext: "Last section, let’s go!!! Here we’ll cover materials that stock your kitchen.",
      details: `All questions refer to ONLY your ${dataYear(qnnSummary)} kitchen and cleaning purchases.`,
      data: "You’ll want to be ready to pull up purchase totals and invoices for the suppliers that stock your kitchen, food packaging, and sanitary materials.",
      questions: "Most questions require an answer, but you can input “0” or select skip for irrelevant questions. Use the help button for additional info or to ask a question and keep moving. One of our experts will be in touch soon.",
      time: "This section really comes down to your bookkeeping, too. Even if your invoices aren’t super organized, we have created a process that makes it as easy as possible. Your progress will be saved along the way if you need to do one section at a time or come back later."
    }
  }

  const cat = catData[categorySlug]
  const catName = catMap[categorySlug].name

  return (
    <div>
      <ViewOnDesktop />
      <MiniProgress
        qnnSummary={qnnSummary.progress}
      />

      <div className="sectionWelcome">
        <header>
          <h2>{catName}</h2>
          <h5>{cat.subtext}</h5>
        </header>

        <div className="welcomeBlock">
          <h3>Things to know for {catName}</h3>
          <div className="welcomeRow">
            <div className="info">
              <div>
                <CalendarIcon />
              </div>
              <div className="infoBody">
               <h4>{dataYear(qnnSummary)} details only</h4>
                <div className="body3">{cat.details}</div>
              </div>
            </div>
            <div className="info">
              <div>
                <PageIcon />
              </div>
              <div className="infoBody">
                <h4>Necessary data</h4>
                <div className="body3">{cat.data}</div>
              </div>
            </div>
          </div>
          <div className="welcomeRow">
            <div className="info">
              <div>
                <QuestionIcon />
              </div>
              <div className="infoBody">
                <h4>Questions</h4>
                <div className="body3">{cat.questions}</div>
              </div>
            </div>
            <div className="info">
              <div>
                <ClockIcon />
              </div>
              <div className="infoBody">
                <h4>Time to complete section</h4>
                <div className="body3">{cat.time}</div>
              </div>
            </div>
          </div>

          <div className="actions">
            <input type="button" className="" value="Back" onClick={() => {navigate('/questionnaire/start')}} />
            <input type="button" className="positive" value="Start section" onClick={() => {navigate(`/questionnaire/${qnnSummary.calculation_year}/${firstQuestionId}`)}} />
          </div>
        </div>
      </div>
    </div>
  )
}
