import React from 'react'

export default function Error() {
  const throwError = () => {
    console.log('throwing error')
    window.someUndefinedFunction()
  }

  return (
    <input type="button" onClick={throwError} />
  )
}
