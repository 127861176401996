const defaultSliderPicSrc = "https://images.unsplash.com/photo-1617218310549-40b26376852b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"

const defaultSusPage = {
  sliderPics: [],
  wordmarkUrl: '',
  susStory: 'Sustainability story.',
  companyName: '',
  goneNeutral: false,
  logoUrl: ''
}

export { defaultSusPage }
