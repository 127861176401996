import React from 'react'

export default function GuideExample(props) {
  return (
    <div className="component">
      <div className="componentLabel">
        { props.name }
      </div>
      <div className="componentMarkup">
        { props.children }
      </div>
    </div>
  )
}
