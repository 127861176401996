import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { S3Client, ListObjectsV2Command, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { useAuth } from '../../providers/AuthProvider'
import { ReactComponent as UploadIcon } from '../../assets/Upload.svg'
import { ReactComponent as XIcon } from '../../assets/X.svg'

const S3_BUCKET = 'climatehound-dev'
const REGION = 'us-east-2'

const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_HERE
  }
})

const encodeUserId = (userId) => {
  return btoa(userId);
};


const FileUploadComponent = (props) => {
  const auth = useAuth();
  const encodedUserId = encodeUserId(auth.userInfo.id);
  const [uploadedFiles, setUploadedFiles] = useState([]);


  useEffect(() => {
    fetchUploadedFiles();
  }, []);

   const fetchUploadedFiles = async () => {
    try {
      const data = await s3Client.send(new ListObjectsV2Command({ Bucket: S3_BUCKET, Prefix: `${encodedUserId}/${props.question}` }));
      setUploadedFiles(data.Contents || []);
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleFiles(acceptedFiles);
  }, []);

  const handleFiles = (files) => {
    const fileArray = Array.from(files); // Convert FileList to Array
    fileArray.forEach(async (file) => {
      const params = {
        Bucket: S3_BUCKET,
        Key: `${encodedUserId}/${props.question}/${file.name}`, // Include encoded user-specific prefix
        Body: file,
        ACL: 'public-read'
      };

      try {
        await s3Client.send(new PutObjectCommand(params));
        console.log(`File uploaded successfully: ${file.name}`);
        fetchUploadedFiles(); // Refresh the list of uploaded files
      } catch (err) {
        console.error(err);
      }
    });
  };

  const deleteFile = async (fileKey) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: fileKey
    };

    try {
      await s3Client.send(new DeleteObjectCommand(params));
      console.log(`File deleted successfully: ${fileKey}`);
      fetchUploadedFiles();
    } catch (err) {
      console.error(err);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
 
  return (
    <div className="dropZoneUpload">
      <div>
        <h5 ><UploadIcon/> Upload Supporting Documents </h5>
        <div className="micro">Provide documentation related to each data point above.</div>
      </div>
      <div {...getRootProps()} classname="dropzone" style={styles.dropzone}>
        <input {...getInputProps()} />
        <p className="micro">Drag and drop supporting documents here, or click to select files</p>
      </div>
      {!!uploadedFiles.length && (<div className='uploadedDocs'>
        <div className="body3">Uploaded Documents</div>
        <div >
          {uploadedFiles.map((file) => (
            <div className='body4' key={file.Key}>
              {file.Key.replace(encodedUserId + '/' + props.question + "/",'')} 
              <XIcon onClick={() => deleteFile(file.Key)} />
            </div>
          ))}
        </div>
      </div>)}
    </div>
  );
};

const styles = {
  dropzone: {
    border: '1px dashed rgb(50, 158, 51)',
    padding: 20,
    cursor: 'pointer',
    margin: 16,
  }
};

export default FileUploadComponent;