import React, { useState, useEffect } from 'react'
import { Route, Link, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import AdminFrame from '../AdminFrame'

import axios from 'axios'

export default function Dashboard() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ user, setUser ] = useState({})
  const [ isLoading, setIsLoading ] = useState(false)

  const [ dupUser, setDupUser ] = useState({email: '', password: ''})

  const [ susPage, setSusPage ] = useState({})

  const { id } = useParams()

  useEffect(() => {
    axios.get(`/api/users/${id}`).then((res) => {
      setUser(res.data)
      console.log(res)
    })
  }, [])

  useEffect(() => {
    if (user.current_sus_page_id) {
      axios.get('/api/sus_pages/' + user.current_sus_page_id).then((res) => {
        setSusPage(res.data)
        console.log('its here')
        console.log(res.data)
      })
    }
  }, [user.current_sus_page_id])

  const saveUser = () => {
    if (window.confirm("Save user?")) {
      setIsLoading(true)
      const susPagePayload = {
        sus_page: {
          sus_story: susPage.sus_story,
          reduction_strategies: susPage.reduction_strategies
        }
      }
      axios.put(`/api/sus_pages/${user.current_sus_page_id}`, susPagePayload).then((res) => {
        console.log(res.data)
        setIsLoading(false)
      })
    }
  }

  const duplicateUser = () => {
    const prompt = `Duplicate ${user.company}?\n\nNew email: ${dupUser.email}\nNew pass: ${dupUser.password}`
    if (window.confirm(prompt)) {
      setIsLoading(true)
      axios.post(`/api/users/${user.id}/duplicate`, {duplicate: dupUser}).then((res) => {
        console.log(res)
        setIsLoading(false)
        alert('Duplicated successfully')
      })
    }
  }

  const updateDuplicate = (e) => {
    const newDupUser = {...dupUser}
    newDupUser[e.target.name] = e.target.value
    setDupUser(newDupUser)
  }

  const susPageChange = (e) => {
    const newSusPage = {...susPage}
    newSusPage[e.target.name] = e.target.value
    setSusPage(newSusPage)
  }

  return(
      <div className="adminPage">

        <header className="pageHeader">
          <h2>{ user.company }</h2>
        </header>

        <table>
          <tbody>
            <tr>
              <td>
                Sustainability Story
              </td>
              <td>
                <textarea name="sus_story" onChange={susPageChange} value={susPage.sus_story || ""}>
                </textarea>
              </td>
            </tr>
            <tr>
              <td>
                Reduction Strategies
              </td>
              <td>
                <textarea name="reduction_strategies" onChange={susPageChange} value={susPage.reduction_strategies || ""}>
                </textarea>
              </td>
            </tr>
          </tbody>
        </table>

        <input type="button" className="positive" value="Save" onClick={saveUser} />


        <h3>Duplicate User</h3>
        <table>
          <tbody>
            <tr>
              <td>
                New Email
              </td>
              <td>
                <input type="email" value={dupUser.email} onChange={updateDuplicate} name="email" />
              </td>
            </tr>
            <tr>
              <td>
                New Password
              </td>
              <td>
                <input type="text" value={dupUser.password} onChange={updateDuplicate} name="password" />
              </td>
            </tr>
          </tbody>
        </table>
        <input type="button" className="positive" value="Duplicate" onClick={duplicateUser} />
      </div>
  )
}
