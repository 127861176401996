import React, { useState, useEffect } from 'react'
import catMap from '../../lib/catMap'

import { ReactComponent as HourglassIcon } from '../../assets/Hourglass.svg'

import { ReactComponent as CalendarIcon } from '../../assets/Calendar.svg'
import { ReactComponent as QuestionIcon } from '../../assets/Question.svg'

import MiniProgress from './MiniProgress'
import ViewOnDesktop from '../ViewOnDesktop'

import axios from 'axios'

export default function QuestionnaireWelcome(props) {

  const [userInfo, setUserInfo] = useState({})

  const [ readyToStart, setReadyToStart ] = useState(false)
  
  const getUserInfo = (callback) => {
    axios.get('/api/users/me').then((res) => {
      const newUserInfo = {
        firstName: res.data.first_name || '',
        lastName: res.data.last_name || '',
        companyName: res.data.company || '',
        email: res.data.email || '',
        bio: res.data.bio || '',
        logoUrl: res.data.logo_url || '',
        wordmarkUrl: res.data.wordmark_url || ''
      }
      callback(newUserInfo)
    })

    const params = new URLSearchParams(window.location.search)
    const payment_intent = params.get('payment_intent')
    if (payment_intent) {
      axios.post('/api/payments/validate_payment_intent', {payment_intent}).then((res) => {
        if (res.status === 200) {
          setReadyToStart(true)
        }
      })
    } else {
      setReadyToStart(true)
    }
  }
  
  useEffect(() => {
    getUserInfo((info) => setUserInfo(info))
  }, [])

  const categories = {
    details: "All questions refer to ONLY your last year's activity and resources. We will cover five sections that contribute to emissions: Business Info, Travel and Distribution, Waste Management, Utilities, and Procurement.",
    questions: "Most questions require an answer but, you can input “0” for irrelevant topics. Use the help button to ask a question and one of our experts will follow up with you shortly, or select skip to move on and circle back later.",
  }
  
  return (
    <div>

      <div className="sectionWelcome qnn-welcome">
        <header>
          <h2>Welcome {userInfo.companyName}, congrats on taking the first step to carbon neutrality!</h2>
        </header>

        <div className="welcomeBlock qnn-welcome">
          <h3><HourglassIcon /> <span>Let's get started measuring your footprint.</span></h3>
          <div className='rows-container'>
            <div className="welcomeRow">
                <div className="info">
                <div>
                    <CalendarIcon />
                </div>
                <div className="infoBody">
                <h4>Calculation Overview</h4>
                    <div className="body3">{categories.details}</div>
                </div>
                </div>
            </div>
            <div className="welcomeRow">
                <div className="info">
                <div>
                    <QuestionIcon />
                </div>
                <div className="infoBody">
                    <h4>Questions</h4>
                    <div className="body3">{categories.questions}</div>
                </div>
                </div>
            </div>
          </div>
         

          <div className="actions">
            <input type="button" className="positive" value="Start my calculation" disabled={!readyToStart} onClick={() => window.location = process.env.REACT_APP_ROOT_URL } />
          </div>
        </div>
      </div>
    </div>
  )
}
