import axios from 'axios'

import catMap from '../../lib/catMap'
import BadgeSrc from '../../assets/badge.png'
import StrategiesSoonSrc from '../../assets/StrategiesSoon.svg'
import PoliciesSoonSrc from '../../assets/PoliciesSoon.png'

import { ReactComponent as CheckIcon } from '../../assets/SmallCheck.svg'
import { ReactComponent as ChevronRightBlack } from '../../assets/ChevronRightBlack.svg'
import { ReactComponent as SunIcon } from '../../assets/Sun.svg'
import { ReactComponent as BadgeIcon } from '../../assets/insightsPlaceholderIcon.svg'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../providers/AuthProvider'

import { ReactComponent as ClockIcon } from '../../assets/Clock.svg'
import IconButton from '../Forms/IconButton'

export default function Insights(props) {
  const navigate = useNavigate()

  const [goneNeutral, setGoneNeutral] = useState(true)
  const [user, setUser] = useState({})

  const defaultComparisons = {
    cars: 0.0,
    per_100k: 0.0,
    per_unit_produced: 0.0,
  }
  const [comparisons, setComparisons] = useState(defaultComparisons)
  const [total, setTotal] = useState(0.0)
  const [totalCostCents, setTotalCostCents] = useState(0)
  const [bySection, setBySection] = useState([])

  function smallOrLargeNumber(num) {
    if (typeof num === 'number') {
      return Math.abs(num) >= 1.0 ? num.toFixed(2) : num.toPrecision(2)
    } else {
      return '...'
    }
  }

  const [companyName, setCompanyName] = useState('')

  useEffect(() => {
    axios.get('/api/reports/current/summary').then((res) => {
      setBySection(res.data.emissions_by_section)
      setComparisons(res.data.comparisons)
      setTotal(res.data.total_kgco2e)
      const tonnesRoundedUp = Math.ceil(res.data.total_kgco2e * 0.001)
      const costPerTonneCents = 1200
      setTotalCostCents(tonnesRoundedUp * costPerTonneCents)
    })

    axios.get('/api/users/me').then((res) => {
      setCompanyName(res.data['company'])
      setGoneNeutral(res.data['gone_neutral'])
      setUser(res.data)
      console.log(res.data)
    })
  }, [])

  const centsToDollars = (cents) => {
    const dollars = cents * 0.01
    return `$${dollars}.00`
  }

  const openPaymentPage = () => {
    // window.open('https://stripe.com', '_blank')
    axios
      .post('/api/payments/report_redirect', { offset_cents: totalCostCents })
      .then((res) => {
        window.location = res.data['checkout_url']
      })
  }

  const goToDash = () => {
    navigate('/insights/details')
  }
  const goToPay = () => {
    navigate('/insights/take-action')
  }

  const startNextCalc = () => {
    if (window.confirm('Begin next carbon calculation?')) {
      axios.post(`/api/users/${user.id}/start_next_calculation`).then((res) => {
        console.log(res)
        window.location = '/questionnaire/start'
      })
    } else {
      console.log("don't begin")
    }
  }

  const selectCats = [
    'distribution-and-travel',
    'energy-usage',
    'waste-management',
    'procurement',
  ]

  const statsDom = selectCats.map((catSlug) => {
    const results = bySection.find((s) => s.section_slug === catSlug)
    if (!results) {
      return
    }
    const cat = catMap[catSlug]
    const Icon = cat.icon
    return (
      <div key={cat.name} className='stat'>
        <header>
          <Icon />
          <h5>{cat.name}</h5>
        </header>
        <figure>
          <h2>{smallOrLargeNumber(results.total_kgco2e * 0.001)}</h2>
          <label className='body4'>MTCO2e</label>
        </figure>
      </div>
    )
  })

  const nextCalcYear = user && user.most_recent_calc_year + 1
  const needToDoCurrentYear = user && user.most_recent_calc_year !== new Date().getFullYear()

  return (
    <div className='page' id='insights'>
      <header className='welcome'>
        <div className='welcomeText'>
          <h3>
          Welcome {companyName},<span> here’s your impact analysis overview</span>
          </h3>
        </div>

        <div className='nextCalculation'>
          <input
            type='button'
            disabled={!needToDoCurrentYear}
            value={`Start ${nextCalcYear} Calculation`}
            className='positive small'
            onClick={startNextCalc}
          />
          <div className='date micro'>
            <ClockIcon />
            {needToDoCurrentYear ? (
              <>Next calculation due in {user.calc_due_in_days} days.</>
            ) : (
              <>
                Next calculation available on 1/1/{new Date().getFullYear() + 1}
              </>
            )}
          </div>
        </div>
      </header>
      <div className='statsBlock light'>
        <div className='stat'>
          <header>
            <h4>{user.most_recent_calc_year} Total</h4>
          </header>
          <figure>
            <h1>{smallOrLargeNumber(total * 0.001)}</h1>
            <label className='body3'>MTCO2e</label>
          </figure>
        </div>

        <div className='stat'>
          <header>
            <h4>Business</h4>
          </header>
          <figure>
            <h1>{smallOrLargeNumber(comparisons.per_100k * 0.001)}</h1>
            <label className='body3' >MTCO2e per $100k</label>
          </figure>
        </div>
        {comparisons.per_unit_produced && (
          <div className='stat'>
            <header>
              <h4>Production</h4>
            </header>
            <figure>
              <h1>
                {smallOrLargeNumber(comparisons.per_unit_produced * 0.001)}
              </h1>
              <label className='body3'>MTCO2e per {comparisons.production_unit}</label>
            </figure>
          </div>
        )}
      </div>
      <div className='statsBlock dark'>
        <div className='statHolder'>{statsDom}</div>
        <div className='detailsHolder'>
          <IconButton value="Insights" onClick={goToDash} className="iconButton" position="right" icon={<ChevronRightBlack/>}></IconButton>
        </div>
      </div>

      {!goneNeutral && (
        <div className='goNeutral'>
          <div className='boxContainer'>
              <div className='sun'>
                <SunIcon />
              </div>
              <div className='prompt'>
                <h2>Go carbon neutral today</h2>
                <div className='body4'>
                  One easy payment and we handle the rest.{' '}
                  <a
                    href='https://www.climatehound.io/carbon-offsets'
                    target='_blank'
                  >
                    Learn more about our carbon offsets.
                  </a>
                </div>
              </div>
          </div>
          <div className='payment'>
            <input
              type='button'
              onClick={goToPay}
              value='See the benefits'
              className='positive'
            />
          </div>
        </div>
      )}

      <div className='strategiesAndPolicies'>
        <div className='strategies'>
          <div className='header'>
            <h3>Reduction Strategies</h3>
            <IconButton value="View more" className="iconButton" position="right" icon={<ChevronRightBlack/>}></IconButton>
          </div>
          <div className='subHeading'>
            <label>0 Available</label>
          </div>  

          <div
            className='placeholder'
            style={{ backgroundImage: `url('${StrategiesSoonSrc}')` }}
          >
            <div className='textSection'>
              <div className='icon'>
                <BadgeIcon />
              </div>
              <div className='text'>
               <h4>Carbon reduction strategies are coming soon for ClimateHound partners.</h4>
              </div>
            </div>
          </div>
        </div>
        <div className='policies'>
          <div className='header'>
            <h3>Policies</h3>
            <IconButton value="View more" className="iconButton" position="right" icon={<ChevronRightBlack/>}></IconButton>
          </div>
          <div className='subHeading'>
            <label>0 Available</label>
          </div>  
          <div
            className='placeholder'
            style={{ backgroundImage: `url('${StrategiesSoonSrc}')` }}
          >
            <div className='textSection'>
              <div className='icon'>
                <BadgeIcon />
              </div>
              <div className='text'>
               <h4>Climate policies are coming soon for ClimateHound partners.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
