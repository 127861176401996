import React from 'react'

export default function LoadingQuestion(props) {

  return (
    <div>
      <div className="questionInputs">
        <div className="loader-question">
            <div className="lds-facebook"><div></div><div></div><div></div></div>
        </div>
      </div>
    </div>
  )
}
