import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'

import Bg1Src from '../../assets/Slides/bg1.jpg'
import Main1Src from '../../assets/Slides/main1.png'

import Bg2Src from '../../assets/Slides/bg2.jpg'
import Main2Src from '../../assets/Slides/main2.png'

import Bg3Src from '../../assets/Slides/bg3.jpg'
import Main3Src from '../../assets/Slides/main3.jpg'

import Bg4Src from '../../assets/Slides/bg4.jpg'
import Main4Src from '../../assets/Slides/main4.png'

import Bg5Src from '../../assets/Slides/bg5.jpg'
import Main5Src from '../../assets/Slides/main5.png'

import { ReactComponent as ChevRight } from '../../assets/ChevronRight.svg'
import { ReactComponent as ChevLeft } from '../../assets/ChevronLeft.svg'

export default function PitchSlides(props) {

  const [picsSlider, setPicsSlider] = useState(null)
  const [captionSlider, setCaptionSlider] = useState(null)
  const [autoplay, setAutoplay] = useState(true)

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => {if(current + 1 !== next) {
      picsSlider.slickPause()
      captionSlider.slickPause()
    }}
  }

  const turnOffAutoplay = () => setAutoplay(false)

  const onArrowClick = () => {
    setInterval(picsSlider.slickPause(), 2000)
    setInterval(captionSlider.slickPause(), 2000)
    setInterval(turnOffAutoplay, 2000)
  }

  const slickPictureSettings = {
    ...slickSettings,
    asNavFor: captionSlider,
    arrows: true,
    nextArrow: (<button type="button" class="slick-next"><ChevRight onClick={onArrowClick}/></button>),
    prevArrow: (<button type="button" class="slick-prev"><ChevLeft onClick={onArrowClick}/></button>)
  }
  const slickCaptionSettings = {
    ...slickSettings,
    fade: true,
    asNavFor: picsSlider,
    dots: false
  }

  const slides = [
    {
      bg: Bg1Src,
      main: Main1Src,
      title: 'Calculate your carbon footprint',
      body: 'You already have all the data, let us do the heavy lifting and calculate all three scopes.'
    },
    {
      bg: Bg2Src,
      main: Main2Src,
      title: 'Reduce your impact – and operational costs',
      body: 'ClimateHound’s core mission is the ongoing hunt for decarbonization strategies and making it easy for our partners to take action. You can do better for your business while doing better for the planet.'
    },
    {
      bg: Bg3Src,
      main: Main3Src,
      title: 'Transparent climate action',
      body: 'Employees and customers are looking for companies that think and act sustainably. ClimateHound powers a sustainability webpage for you – simply copy+paste the embed code.'
    },
    {
      bg: Bg4Src,
      main: Main4Src,
      title: 'Action that speaks for itself',
      body: 'Our certifications show your commitment to environmental and social responsibility, validated by a third-party that specializes in your industry (us) AND beverage producers can add our seals to packaging without resubmitting labels to the TTB.'
    },
    {
      bg: Bg5Src,
      main: Main5Src,
      title: 'Go carbon neutral',
      body: 'Companies that are ready to close the gap to full carbon neutral certification can fund ClimateHound-vetted carbon negative projects to accelerate climate action in the industry at large.'
    }
  ]

  const setPicsRef = (ref) => {
    setPicsSlider(ref)
    // console.log(ref)
  }


  const setCaptionRef = (ref) => {
    setCaptionSlider(ref)
    // console.log(ref)
  }

  const pictureSlidesDom = slides.map((slide, i) => {
    return (
      <div className={`slide slide${i+1}`} key={i}>
        <div className="slideContents">
          <div className="slideImage" style={{backgroundImage: `url('${slide.bg}')`}}>
            <div className="mainImage">
              <img src={slide.main} />
            </div>
          </div>  
        </div>
      </div>
    )
  })

  const captionSlidesDom = slides.map((slide, i) => {
    return (
      <div className={`slide slide${i+1}`} key={i}>
        <div className="slideContents">
          <div className="slideCaption">
            <div>
              <h3>{slide.title}</h3>
              <p className="body3">{slide.body}</p>
            </div>        
            <div className="createAccount">
              <Link to="/signup" className="button positive small">Create your account</Link>
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className="pitchSlides">
      <div className="pictureSlider">
        <Slider {...slickPictureSettings} className="slickPics" ref={setPicsRef}>
          { pictureSlidesDom }
        </Slider>
      </div>
      <div className="captionSlider">
        <Slider {...slickCaptionSettings} className="slickCaptions" ref={setCaptionRef}>
          { captionSlidesDom }
        </Slider>      
      </div>     
    </div>
  )
}
