import React from 'react'

export default function DemoQuestion(props) {

    const QuestionComponent = props.questionComponent
    return (
        <div className={`questionBody ${props.question.answer_format} ${props.question.units}`}>
          <header>
            <h2>{ props.question.prompt_text.text }</h2>
            <div className="body2">{ props.question.prompt_text.body }</div>
          </header>
          <QuestionComponent
            question={props.question}
          />
        </div>
    )
}

const address = {
    "id": 1979,
    "order": 11,
    "status": 1,
    "answer": {
      "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "Where are your primary operations located?",
    "units": "address",
    "units_name": "address",
    "prompt_text": {
      "body": null,
      "text": "Where are your primary operations located?"
    },
    "question_type": "other",
    "answer_format": "address-fields--google-maps-api",
    "questionnaire_section_slug": "business-info",
    "questionnaire_section_name": "Business Info",
    "questionnaire_section_id": 1,
    "question_category_id": 1,
    "question_category": "Primary Location"
}

const singleAnswerNonDollar = {
    "id": 1980,
    "order": 12,
    "status": 0,
    "answer": {
        "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "How many facilities does your business operate?",
    "units": "#",
    "units_name": "#",
    "prompt_text": {
        "body": "One facility would be each location/address, not necessarily individual buildings.",
        "text": "How many facilities does your business operate?"
    },
    "question_type": "other",
    "answer_format": "numeric",
    "questionnaire_section_slug": "business-info",
    "questionnaire_section_name": "Business Info",
    "questionnaire_section_id": 1,
    "question_category_id": 2,
    "question_category": "Facility Count"
}

const singleAnswerDollar = {
    "id": 1994,
    "order": 33,
    "status": 1,
    "answer": {
      "text": "5"
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "How much was spent on natural gas?",
    "units": "$",
    "units_name": "$",
    "prompt_text": {
      "body": null,
      "text": "How much was spent on natural gas?"
    },
    "question_type": "eeio_spend",
    "answer_format": "numeric",
    "questionnaire_section_slug": "energy-usage",
    "questionnaire_section_name": "Energy Usage",
    "questionnaire_section_id": 3,
    "question_category_id": 16,
    "question_category": "Natural Gas"
  }

const vehicle = {
    "id": 1985,
    "order": 21,
    "status": 0,
    "answer": {
        "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "List all company vehicles by year, make, model, engine size, fuel/energy-type (gas, diesel, or electric), and approximate 2022 mileage.",
    "units": "vehicle",
    "units_name": "vehicle",
    "prompt_text": {
        "body": "This question is regarding vehicles used for distribution and other company purposes. It does not pertain to employee commutes, which are already addressed by employee count.",
        "text": "List all company vehicles by year, make, model, engine size, fuel/energy-type (gas, diesel, or electric), and approximate 2022 mileage."
    },
    "question_type": "other",
    "answer_format": "multi-field-per-vehicle",
    "questionnaire_section_slug": "distribution-and-travel",
    "questionnaire_section_name": "Distribution and Travel",
    "questionnaire_section_id": 2,
    "question_category_id": 7,
    "question_category": "Company Vehicles"
}

const disposedWaste = {
    "id": 1999,
    "order": 44,
    "status": 0,
    "answer": {
        "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "How much waste do you send to the landfill per MONTH?",
    "units": "landfill",
    "units_name": "Landfill",
    "prompt_text": {
        "body": null,
        "text": "How much waste do you send to the landfill per MONTH?"
    },
    "question_type": "other",
    "answer_format": "disposed-waste",
    "questionnaire_section_slug": "waste-management",
    "questionnaire_section_name": "Waste Management",
    "questionnaire_section_id": 4,
    "question_category_id": 20,
    "question_category": "Waste"
}

const divertedWaste = {
    "id": 2001,
    "order": 48,
    "status": 0,
    "answer": {
        "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "How much material do you divert from the landfill for compost, animal feed, combustion or anaerobic digestion per MONTH?",
    "units": "cubic-yards-or-lbs",
    "units_name": "cubic yards or lbs",
    "prompt_text": {
        "body": "Choose each content-type, approximate the volume or weight, and select the diversion process.",
        "text": "How much material do you divert from the landfill for compost, animal feed, combustion or anaerobic digestion per MONTH?"
    },
    "question_type": "other",
    "answer_format": "diverted-waste",
    "questionnaire_section_slug": "waste-management",
    "questionnaire_section_name": "Waste Management",
    "questionnaire_section_id": 4,
    "question_category_id": 22,
    "question_category": "Diverted Organics"
}

const shortAnswer = {
    "id": 2003,
    "order": 51,
    "status": 0,
    "answer": {
      "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "Do you like short answer questions?",
    "units": "text",
    "units_name": "text",
    "prompt_text": {
      "body": "This question refers to short answer questions. What is the worst nightmare you remember?",
      "text": "Do you remember your scariest dream?"
    },
    "question_type": "other",
    "answer_format": "short-answer",
    "questionnaire_section_slug": "waste-management",
    "questionnaire_section_name": "Waste Management",
    "questionnaire_section_id": 4,
    "question_category_id": 24,
    "question_category": "Wastewater Treatment"
  }

  const supplierSpends = {
    "id": 2023,
    "order": 75,
    "status": 0,
    "answer": {
        "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "Let’s get to know the suppliers that bring you your bulkiest raw inputs, packaging materials, and machinery.",
    "units": "supplier-spends",
    "units_name": "supplier spends",
    "prompt_text": {
        "body": "Add the names, or some memorable identifier, and total 2022 spend with each supplier. Don’t worry, you can always come back to add another supplier without losing your progress.",
        "text": "Let’s get to know the suppliers that bring you your bulkiest raw inputs, packaging materials, and machinery."
    },
    "question_type": "other",
    "answer_format": "shipped-bulk-purchases",
    "questionnaire_section_slug": "procurement",
    "questionnaire_section_name": "Procurement",
    "questionnaire_section_id": 5,
    "question_category_id": 44,
    "question_category": "Purchased Goods"
} 

const categorySupplierAllocations = {
    "id": 2024,
    "order": 76,
    "status": 0,
    "answer": {
      "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "What do these suppliers bring to you, and how do they distribute those products?",
    "units": "shipped-category-supplier-allocations",
    "units_name": "shipped category supplier allocations",
    "prompt_text": {
      "body": "Select the types of products that these suppliers provide, allocate your total 2022 spend per indicated product, and then detail the total 2022 cost per method of shipping that they used.",
      "text": "What do these suppliers bring to you, and how do they distribute those products?"
    },
    "question_type": "other",
    "answer_format": "shipped-bulk-purchases",
    "questionnaire_section_slug": "procurement",
    "questionnaire_section_name": "Procurement",
    "questionnaire_section_id": 5,
    "question_category_id": 44,
    "question_category": "Purchased Goods"
  }

const shippedZeroSpendConfirmation = {
    "id": 2025,
    "order": 77,
    "status": 0,
    "answer": {
      "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "Please review the product types below, which were not assigned to a supplier.",
    "units": "zero-spend-confirmation",
    "units_name": "zero-spend confirmation",
    "prompt_text": {
      "body": "If you did not order any of these product types during 2022, please check the box below to confirm and then continue. If you did, please go back, add however many suppliers are necessary, and indicate your purchases totals and the supplier shipping details.",
      "text": "Please review the product types below, which were not assigned to a supplier."
    },
    "question_type": "other",
    "answer_format": "shipped-bulk-purchases",
    "questionnaire_section_slug": "procurement",
    "questionnaire_section_name": "Procurement",
    "questionnaire_section_id": 5,
    "question_category_id": 44,
    "question_category": "Purchased Goods"
  }

  const kitchenSuppliers = {
    "id": 2130,
    "order": 90,
    "status": 1,
    "answer": {
      "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": true,
    "not_applicable": false,
    "name": "Let’s get to know the suppliers that support your kitchen, bar, and cleaning operations.",
    "units": "supplier-spends",
    "units_name": "supplier spends",
    "prompt_text": {
      "body": "Add the names, or some memorable identifier, and total 2022 spend with each supplier. Don’t worry, you can always come back to add another supplier without losing your progress.",
      "text": "Let’s get to know the suppliers that support your kitchen, bar, and cleaning operations."
    },
    "question_type": "other",
    "answer_format": "bulk-purchases",
    "questionnaire_section_slug": "kitchen",
    "questionnaire_section_name": "Kitchen",
    "questionnaire_section_id": 6,
    "question_category_id": 54,
    "question_category": "Kitchen Purchases"
  }

  const supplierCategories = {
    "id": 2131,
    "order": 91,
    "status": 0,
    "answer": {
      "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "What types of products do these suppliers bring to your business?",
    "units": "supplier-categories",
    "units_name": "supplier categories",
    "prompt_text": {
      "body": null,
      "text": "What types of products do these suppliers bring to your business?"
    },
    "question_type": "other",
    "answer_format": "bulk-purchases",
    "questionnaire_section_slug": "kitchen",
    "questionnaire_section_name": "Kitchen",
    "questionnaire_section_id": 6,
    "question_category_id": 54,
    "question_category": "Kitchen Purchases"
  }

  const categorySupplierSpend = {
    "id": 2132,
    "order": 92,
    "status": 0,
    "answer": {
      "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "Now let’s allocate total spend across the products that you indicated.",
    "units": "category-supplier-spends",
    "units_name": "category supplier spends",
    "prompt_text": {
      "body": null,
      "text": "Now let’s allocate total spend across the products that you indicated."
    },
    "question_type": "other",
    "answer_format": "bulk-purchases",
    "questionnaire_section_slug": "kitchen",
    "questionnaire_section_name": "Kitchen",
    "questionnaire_section_id": 6,
    "question_category_id": 54,
    "question_category": "Kitchen Purchases"
  }

  const zeroSpendConfirmation = {
    "id": 2133,
    "order": 93,
    "status": 0,
    "answer": {
      "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "We noticed you did not select a supplier for these items.",
    "units": "zero-spend-confirmation",
    "units_name": "zero-spend confirmation",
    "prompt_text": {
      "body": "Please indicate a zero or a dollar amount.",
      "text": "We noticed you did not select a supplier for these items."
    },
    "question_type": "other",
    "answer_format": "bulk-purchases",
    "questionnaire_section_slug": "kitchen",
    "questionnaire_section_name": "Kitchen",
    "questionnaire_section_id": 6,
    "question_category_id": 54,
    "question_category": "Kitchen Purchases"
  }

  const locationSingleInputDollar = {
    "id": 2147,
    "order": 33,
    "status": 3,
    "answer": {
      "location_data": [
        {
          "amount": "21",
          "location_id": "11"
        },
        {
          "amount": "53",
          "location_id": "12"
        }
      ]
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": true,
    "not_applicable": false,
    "name": "How much was spent on natural gas?",
    "units": "$",
    "units_name": "$",
    "prompt_text": {
      "body": "Use your gas bills or online portal to determine totals across your locations.",
      "text": "How much was spent on natural gas?"
    },
    "question_type": "other",
    "answer_format": "locations-with-single-input",
    "questionnaire_section_slug": "energy-usage",
    "questionnaire_section_name": "Energy Usage",
    "questionnaire_section_id": 3,
    "question_category_id": 16,
    "question_category": "Natural Gas"
  }

  const locationSingleInputNondollar = {
    "id": 2146,
    "order": 32,
    "status": 3,
    "answer": {
      "location_data": [
        {
          "amount": "23",
          "location_id": "11"
        },
        {
          "amount": "45",
          "location_id": "12"
        }
      ]
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": true,
    "not_applicable": false,
    "name": "How many kilowatt-hours (kWh) of electricity, if any, were captured by an on-site solar array?",
    "units": "kwh",
    "units_name": "kWh",
    "prompt_text": {
      "body": "In some configurations, this means energy used on site directly, and sometimes it means energy fed back to the grid for a credit.",
      "text": "How many kilowatt-hours (kWh) of electricity, if any, were captured by an on-site solar array?"
    },
    "question_type": "other",
    "answer_format": "locations-with-single-input",
    "questionnaire_section_slug": "energy-usage",
    "questionnaire_section_name": "Energy Usage",
    "questionnaire_section_id": 3,
    "question_category_id": 15,
    "question_category": "Solar"
  }

  const locationSelection = {
    "id": 2135,
    "order": 12,
    "status": 1,
    "answer": {
      "text": ""
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "Select a primary location",
    "units": "address",
    "units_name": "address",
    "prompt_text": {
      "body": "This location is where your business and employees primarily operate",
      "text": "Select a primary location"
    },
    "question_type": "other",
    "answer_format": "location-selector",
    "questionnaire_section_slug": "business-info",
    "questionnaire_section_name": "Business Info",
    "questionnaire_section_id": 1,
    "question_category_id": 64,
    "question_category": "Set Primary Location"
  }

  const warehouseSquareFootage = {
    "id": 347,
    "order": 86,
    "status": 1,
    "answer": {
      warehouses: [
        {"id": "i0", name: 'garage', sq_ft: '45'},
        {"id": "i1", name: 'couch', sq_ft: '31'},
        {"id": "i2", name: 'shoes', sq_ft: '500'}
      ]
    },
    "ticket_id": null,
    "help_resolved": null,
    "was_skipped": false,
    "not_applicable": false,
    "name": "What are the sizes of third-party leased or rented storage and warehousing?",
    "name_id": "p29",
    "units": "$",
    "units_name": "$",
    "prompt_text": {
        "body": "This question means third-party warehousing or storage outside of your reported locations.",
        "text": "Do you lease any additional warehouse/storage space?"
    },
    "question_type": "table",
    "answer_format": "numeric",
    "questionnaire_section_slug": "procurement",
    "questionnaire_section_name": "Procurement",
    "questionnaire_section_id": 5,
    "question_category_id": 51,
    "question_category": "Storage And Warehousing"
}

export const questions = {
    address, 
    locationSelection,
    singleAnswerNonDollar, 
    singleAnswerDollar,
    locationSingleInputDollar,
    locationSingleInputNondollar,
    vehicle, 
    disposedWaste, 
    divertedWaste, 
    shortAnswer,
    supplierSpends,
    categorySupplierAllocations,
    shippedZeroSpendConfirmation,
    kitchenSuppliers,
    supplierCategories,
    categorySupplierSpend,
    zeroSpendConfirmation,
    warehouseSquareFootage
}
