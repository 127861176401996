import React from 'react'

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material'
import { Box, Button } from '@mui/material'
import { FormControl, FormControlLabel, InputLabel, Select, MenuItem, TextField, FormGroup, Checkbox } from '@mui/material'
import Typography from '@mui/material/Typography'

import centsToReadable from '../../utils/centsToReadable'

export default function CreateInvite(props) {

  const labels = {
    price: "Price",
    email: "Email",
    name: "Name",
    company_name: "Company Name"
  }

  const fieldDom = 'price email name company_name'.split(' ').map((name) => {
    return (
      <TextField
        name={name}
        key={name}
        value={props.invite[name]}
        label={labels[name]}
        error={!!props.formErrors[name]}
        fullWidth
        onChange={props.onChange}
        helperText={props.formErrors[name]}
      />
    )
  })

  return (
    <Dialog open={props.isCreateOpen} onClose={() => (props.setIsCreateOpen(false))} fullWidth={true} maxWidth="md">
      <DialogTitle>Create Invitation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {
            props.formErrors['server'] ? <Typography mx={{color: 'red'}}>{props.formErrors['server']}</Typography> : <span></span>
          }
        </DialogContentText>
        <Box component="form"
          sx={{
            '& .MuiTextField-root': { mt: 3 },
            '& .MuiFormControl-root': { mt: 3 }
          }}
          noValidate
          autoComplete="off"
        >
          <InputLabel id="demo-simple-select-label">Industry</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.invite['industry_id']}
            label="Age"
            name="industry_id"
            onChange={props.onChange}
            fullWidth
          >
            {
              props.industries.map((ind) => {
                return (
                  <MenuItem key={ind['id']} value={ind['id']}>{ind['name']}</MenuItem>
                )
              })
            }
          </Select>
          { fieldDom }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => (props.setIsCreateOpen(false))}>Cancel</Button>
        <Button variant="contained" onClick={props.createInvite}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}
