import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { defaultSusPage } from '../../data/defaults'

import Profile from './Profile'
import axios from 'axios'

export default function ProfileShower(props) {

  const [ susPage, setSusPage ] = useState(defaultSusPage)
  const [ reportId, setReportId ] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    axios.get('/api/sus_pages/' + atob(id)).then((res) => {
      const apiPage = {
        id: res.data.id,
        susStory: res.data.sus_story,
        reductionStrategies: res.data.reduction_strategies,
        logoUrl: res.data.logo_url,
        wordmarkUrl: res.data.wordmark_url,
        sliderPics: res.data.slider_pics || defaultSusPage.sliderPics,
        goneNeutral: res.data.gone_neutral,
        neutralSinceYear: res.data.neutral_since_year,
        industry: res.data.industry,
        companyName: res.data.company
      }
      setSusPage(apiPage)
      setReportId(res.data.report_id)
    })
  }, [])


  return (
    <Profile draft={susPage} reportId={reportId} />
  )
}
