export default [
  {
    "id": 682,
    "name": "[DEMO] Work From Home",
    "slug": "work-from-home",
    "potential_reduction_kgs": 200000,
    "dollars_saved": null,
    "upfront_cost": null,
    "kgco2e_reduced": null,
    "status": "NOT_STARTED",
    "difficulty": "EASY",
    "is_in_action_plan": false,
    "completed_steps_mask": [],
    "inputs": {
        "number_of_days": null,
        "avg_number_of_miles": 282111,
        "number_of_employees": null,
        "price_of_gasoline": 25,
    },
    "is_in_forecast": false
},
  {
    "id": 330,
    "name": "[DEMO] Eco-driving",
    "slug": "eco-driving",
    "potential_reduction_kgs": 200000,
    "dollars_saved": null,
    "upfront_cost": null,
    "kgco2e_reduced": null,
    "status": "NOT_STARTED",
    "difficulty": "EASY",
    "is_in_action_plan": false,
    "completed_steps_mask": [],
    "inputs": {
        "total_miles_annually": 760000,
        "percentage_of_highway_miles": null,
        "avg_fuel_economy": 25,
        "previous_top_speed": null,
        "encouraged_top_speed": 60,
        "improved_fuel_economy_rate": 0.14,
        "gallons_per_year_saved": 6,
        "price_of_gasoline": 4,
        "percentage_of_highway_miles": 8,
    },
    "is_in_forecast": false
}
]