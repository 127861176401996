import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import LabeledField from '../../Forms/LabeledField'
import LabeledSelect from '../../Forms/LabeledSelect'
import Toggler from '../../Forms/Toggler'
import Selectable from '../../Forms/Selectable'
import MiniProgress from '../MiniProgress'
import NavButtons from '../NavButtons'
import { useNavigate } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import FileUploadComponent from '../../Forms/DropUpload'

import axios from 'axios'
import { dataYear } from '../../../utils/years'

import { ReactComponent as Xsvg } from '../../../assets/X.svg'


export default function ShippedCategorySupplierSpends(props) {

  const [ suppliers, setSuppliers ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ shippingMethods, setShippingMethods ] = useState([])
  const [ catSpends, setCatSpends ] = useState({})
  const [ shippingSpends, setShippingSpends ] = useState({})
  const [ isPercent, setIsPercent ] = useState(false)

  const [ errors, setErrors ] = useState({})
  const [ totals, setTotals ] = useState({})

  const emptyShippingSpend = {id: 'i0', shippingMethodId: '', amount: ''}
  const emptyShippingMethod = {id: '', name: 'Select'}

  useEffect(() => {
    axios.get('/api/supplier_categories/by_industry').then((res) => {
      setCategories(res.data)
    })
  }, [])

  useEffect(() => {
    axios.get('/api/shipping_methods').then((res) => {
      setShippingMethods([emptyShippingMethod, ...res.data])
    })
  }, [])

  useEffect(() => {
    axios.get(
      '/api/suppliers/by_question_category', {
        params: {
          question_category_id: props.question.question_category_id
        }}).then((res) => {

      const currentCatSpends = {}
      const currentSuppliers = res.data
      for (let i = 0; i < currentSuppliers.length; i++) {
        currentCatSpends[currentSuppliers[i].id] = currentSuppliers[i].supplier_category_spends.map((csc) => ({isPercent: csc.is_percent, catId: csc.supplier_category_id, amount: spendOrEmpty(csc.amount)}))
      }
      setCatSpends(currentCatSpends)

      let oneCatSpend = null

      for (const supId in currentCatSpends) {
        for (let i = 0; i < currentCatSpends[supId].length; i++) {
          if (currentCatSpends[supId][i]) {
            oneCatSpend = currentCatSpends[supId][i]
          }
        }
      }

      if (oneCatSpend) {
        setIsPercent(oneCatSpend.isPercent)
      }

      const currentShippingSpends = {}
      for (let i = 0; i < currentSuppliers.length; i++) {
        const shippingSpendsFromDb = currentSuppliers[i].shipping_spends.map((ss) => ({id: ss.id, shippingMethodId: ss.shipping_method_id, amount: spendOrEmpty(ss.amount)}))
        const spendsOrEmpty = shippingSpendsFromDb.length > 0 ? shippingSpendsFromDb : [emptyShippingSpend]
        currentShippingSpends[currentSuppliers[i].id] = spendsOrEmpty
      }

      setShippingSpends(currentShippingSpends)
      setTotals(totalSpends(currentCatSpends, currentShippingSpends))
      setSuppliers(currentSuppliers)
    })
  }, [])

  function totalSpends(catSpends, shippingSpends) {
    const supplierIds = Object.keys(catSpends)
    const supTotalSpends = {}
    for (let i = 0; i < supplierIds.length; i++) {
      const supId = supplierIds[i]
      const catAmounts = catSpends[supId].map((cs) => (cs.amount))
      const shipAmounts = shippingSpends[supId].map((ss) => (ss.amount))
      const supAmounts = [...catAmounts, ...shipAmounts]
      let supTotal = 0
      for (let j = 0; j < supAmounts.length; j++) {
        if (typeof supAmounts[j] === 'string') {
          if (supAmounts[j].length > 0) {
            supTotal += parseInt(supAmounts[j])
          }
        } else if (typeof supAmounts[j] === 'number') {
          supTotal += parseInt(supAmounts[j])
        }
      }
      supTotalSpends[supId] = supTotal
    }
    return supTotalSpends
  }

  const spendOrEmpty = (amt) => {
    if (typeof amt === 'string') {
      if (amt.length === 0) {
        return ''
      } else {
        return parseInt(amt)
      }
    } else if (typeof amt === 'number') {
      return amt
    }
  }

  const resetAmounts = () => {

    const emptyShippingSpends = JSON.parse(JSON.stringify(shippingSpends))
    const emptyCatSpends = JSON.parse(JSON.stringify(catSpends))

    for (let i = 0; i < suppliers.length; i++) {
      const supId = suppliers[i].id
      for (let j = 0; j < emptyCatSpends[supId].length; j++) {
        emptyCatSpends[supId][j]['amount'] = ''
      }

      for (let j = 0; j < emptyShippingSpends[supId].length; j++) {
        emptyShippingSpends[supId][j]['amount'] = ''
      }
    }

    setShippingSpends(emptyShippingSpends)
    setCatSpends(emptyCatSpends)
    setTotals(totalSpends(emptyCatSpends, emptyShippingSpends))
  }

  const toggleInputType = () => {
    const totalTotal = Object.values(totals).reduce((running, i) => (running + i), 0)
    if (totalTotal > 0) {
      if (window.confirm("Are you sure? Current values will be lost.")) {
        setIsPercent(!isPercent)
        resetAmounts()
      } else {
        console.log('no toggle')
      }
    } else {
      setIsPercent(!isPercent)
    }
  }

  const toggleSupplierCategory = (supplierId, e) => {
    const catId = parseInt(e.target.name)
    const newCatSpends = cloneDeep(catSpends)

    if (e.target.checked) {
      const catsOrEmpty = newCatSpends[supplierId] || []
      const emptySpend = {catId: catId, amount: ''}
      newCatSpends[supplierId] = [...catsOrEmpty, emptySpend]
    } else {
      let catIndex = newCatSpends[supplierId].findIndex((cs) => (cs.catId === catId))
      newCatSpends[supplierId].splice(catIndex, 1)
    }
    setCatSpends(newCatSpends)
  }

  const addBlankShippingSpend = (supplierId) => {
    const newShippingSpends = JSON.parse(JSON.stringify(shippingSpends))
    newShippingSpends[supplierId] = [...newShippingSpends[supplierId], {...emptyShippingSpend, id: 'i'+newShippingSpends[supplierId].length}]
    setShippingSpends(newShippingSpends)
  }

  const updateShippingSpend = (supplierId, ssId, e) => {
    if (e.target.name === 'amount') {
      if (!/^\d*$/.test(e.target.value)) {
        return
      }
    }
    const newShippingSpends = JSON.parse(JSON.stringify(shippingSpends))
    for (let i = 0; i < newShippingSpends[supplierId].length; i++) {
      if (newShippingSpends[supplierId][i]['id'] === ssId) {
        newShippingSpends[supplierId][i][e.target.name] = e.target.value
      }
    }
    setTotals(totalSpends(catSpends, newShippingSpends))
    setShippingSpends(newShippingSpends)
  }

  const removeShippingSpend = (supplierId, ssId, e) => {
    const newShippingSpends = JSON.parse(JSON.stringify(shippingSpends))
    newShippingSpends[supplierId] = newShippingSpends[supplierId].slice().filter( shippingInfo => shippingInfo.id !== ssId)
    for (let i = 0; i < newShippingSpends[supplierId].length; i++) {
        newShippingSpends[supplierId][i]['id'] = 'i' + i
    }
    setTotals(totalSpends(catSpends, newShippingSpends))
    setShippingSpends(newShippingSpends)
  }

  const updateCatSpend = (supplierId, catId, e) => {
    if (e.target.name === 'amount') {
      if (!/^\d*$/.test(e.target.value)) {
        return
      }
    }
    const newCatSpends = JSON.parse(JSON.stringify(catSpends))
    for (let i = 0; i < newCatSpends[supplierId].length; i++) {
      if (newCatSpends[supplierId][i]['catId'] === catId) {
        newCatSpends[supplierId][i][e.target.name] = e.target.value
      }
    }
    setTotals(totalSpends(newCatSpends, shippingSpends))
    setCatSpends(newCatSpends)
  }

  const isChecked = (supplierId, categoryId) => {
    for (let i = 0; i < suppliers.length; i++) {
      if (suppliers[i].id === supplierId) {
        if (catSpends[supplierId]) {
          if (catSpends[supplierId].findIndex((cs) => (cs.catId === categoryId)) > -1) {
            return true
          }
        }
      }
    }
    return false
  }

  const isAllowedToAddAnotherShippingSpend = (currentShippingSpends) => {
    if (currentShippingSpends.length >= shippingMethods.length - 1) {
      return false
    }
    for (let i = 0; i < currentShippingSpends.length; i++) {
      if (currentShippingSpends[i].shippingMethodId === '') {
        return false
      }
    }
    return true
  }

  const isMethodUsed = (sm, currentShippingSpends) => {
    for (let i = 0; i < currentShippingSpends.length; i++) {
      if (parseInt(currentShippingSpends[i]['shippingMethodId']) === parseInt(sm.id)) {
        return true
      }
    }
    return false
  }

  const remainingSpendText = (targetSpend, currentSpend) => {
    const ts = isPercent ? 100 : parseInt(targetSpend)
    const cs = parseInt(currentSpend)
    if (ts === cs) {
      if (isPercent) {
        return '0% remaining'
      } else {
        return '$0 remaining'
      }
    }
    if (cs < ts) {
      if (isPercent) {
        return `${ts - cs}% remaining`
      } else {
        return `$${ts - cs} remaining`
      }
    }
    if (cs > ts) {
      if (isPercent) {
        return `${cs - ts}% over`
      } else {
        return `$${cs - ts} over`
      }
    }
  }

  function nextEnabled() {
    for (let i = 0; i < suppliers.length; i++) {
      const supplier = suppliers[i]
      const spendTarget = isPercent ? 100 : parseInt(supplier.total_spend)
      if ((spendTarget - totals[supplier.id]) !== 0) {
        return false
      }

      for (let i = 0; i < shippingSpends[supplier.id].length; i++) {
        const spend = shippingSpends[supplier.id][i]
        if (spend.amount !== '' && spend.shippingMethodId === '') {
          return false
        }
      }
    }
    return true
  }


  const submit = () => {
    const payload = {
      category_spends_by_supplier: catSpends,
      shipping_spends_by_supplier: shippingSpends,
      question_category_id: props.question.question_category_id,
      is_percent: isPercent
    }
    return axios.post('/api/suppliers/shipped_category_spends', payload)
  }

  return (
    <div>
      <div className="questionInputs">
        <div className="inputSwitcher shipped">
          <Toggler
            checked={isPercent}
            onChange={toggleInputType}
            className='thin'
            labels={['$', '%']}
          />
        </div>
        <div className="productSelections">
          {
            suppliers.map((supplier, idx) => {
              return (
                <div className="productSelector" key={idx}>
                  <header>
                    <div className="body3">{supplier.name}</div>
                    <div className="body3">${supplier.total_spend}</div>
                  </header>
                  <div className="micro">
                    Indicate what they supply
                  </div>
                  <div className="categories">
                    {
                      categories.map((c) => {
                        return (
                          <Selectable
                            key={c.id}
                            name={c.id}
                            value={c.name}
                            checked={isChecked(supplier.id, c.id)}
                            onChange={(e) => {toggleSupplierCategory(supplier.id, e)}}
                          />
                        )
                      })
                    }
                  </div>

                  <div className={`inputs ${catSpends[supplier.id].length === 0 ? 'hidden' : ''}`}>
                    <div className={`supCatSpends productSelections`}>
                      <div className="spend">
                        <div className="spendLabel body4"></div>
                        <div className="spendAmount">
                          <div className="micro">Amount</div>
                        </div>
                      </div>
                      {
                        catSpends[supplier.id].map((catSpend) => {
                          return (
                            <div className="spend" key={catSpend.catId}>
                              <div className="spendLabel body4">{categories.length > 0 && categories.find((cat) => (cat.id === catSpend.catId))['name']}</div>
                              <div className="spendAmount">
                                <LabeledField
                                  name="amount"
                                  formErrors={{}}
                                  values={catSpend}
                                  onChange={(e) => (updateCatSpend(supplier.id, catSpend.catId, e))}
                                  placeholder={isPercent ? '%' : '$'}
                                />
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>

                    <div className="micro shipRule">
                      Choose shipping types and cost during {dataYear(props.qnnSummary)}
                    </div>


                    <div className="supCatSpends productSelections shipping">
                      <div className="spend amountLabel">
                        <div className="spendLabel body4">Shipping Type</div>
                        <div className="spendAmount">
                          <div className="micro">Amount</div>
                        </div>
                      </div>
                      {
                        shippingSpends[supplier.id].map((shippingSpend, idx) => {
                          return (
                            <div className="spend" key={shippingSpend.id}>
                              <div className="spendLabel body4">
                                <LabeledSelect
                                  name='shippingMethodId'
                                  value={shippingSpend['shippingMethodId']}
                                  options={ shippingMethods.map((sm) => {
                                    return (
                                      {
                                        value: sm.id,
                                        label: sm.name,
                                        isDisabled: isMethodUsed(sm, shippingSpends[supplier.id])
                                      }
                                    )
                                  })}
                                  onChange={(e) => (updateShippingSpend(supplier.id, shippingSpend.id, e))}
                                />
                              </div>
                              <div className="spendAmount">
                                <LabeledField
                                  name="amount"
                                  label="&nbsp;"
                                  formErrors={{}}
                                  values={shippingSpend}
                                  onChange={(e) => (updateShippingSpend(supplier.id, shippingSpend.id, e))}
                                  placeholder={isPercent ? '%' : '$'}
                                />
                              </div>
                              {                              
                                shippingSpends[supplier.id] && shippingSpends[supplier.id].length > 1 && (
                                <div
                                  className="removeButton"
                                  onClick={(e) => {removeShippingSpend(supplier.id, shippingSpend.id, e)}}
                                >
                                  <Xsvg />
                                </div>
                                )
                              }
                            </div>
                          )
                        })
                      }
                      <div className="addButtonHolder">
                        <input type="button" className="gray addButton" disabled={(!isAllowedToAddAnotherShippingSpend(shippingSpends[supplier.id]))} value="Add another" onClick={() => (addBlankShippingSpend(supplier.id))} />
                      </div>
                    </div>

                    <div className="supCatSpends productSelections">
                      <div className="remaining">
                        <div className={`micro ${((isPercent ? 100 : parseInt(supplier.total_spend)) - totals[supplier.id]) !== 0 ? 'error' : ''}`}>
                          {remainingSpendText(supplier.total_spend, totals[supplier.id])}
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              )
            })
          }
        </div>
      </div>
      <FileUploadComponent question={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={() => (nextEnabled(suppliers, totals))}
      />
    </div>
  )
}
