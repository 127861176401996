import React, { useState, useEffect } from 'react'
import { Route, Link, useNavigate, useOutletContext } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IconButton from '../Forms/IconButton'
import { Tooltip } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import {ReactComponent as MeasureIcon} from '../../assets/Measure.svg'
import BarChartIcon from '@mui/icons-material/BarChart';

import classnames from 'classnames'

import axios from 'axios'

  const downloadCSV = () => {
    window.open('https://climatehound-dev.s3.us-east-2.amazonaws.com/supplier_data.xlsx')
  };

  const downloadAggregateCSV = (data) => {
    window.open('https://climatehound-dev.s3.us-east-2.amazonaws.com/suppliers_export.xlsx')
  };

  

const suppliers = [
  {
    name: "Supplier 1",
    id: 1,
    status: "Completed",
    progress: 100,
    scope_1: '12.5K MTCO2e',
    scope_2: '15.2K MTCO2e',
    scope_3: '86.3K MTCO2e',
    total: '114K MTCO2e',
    report_id: 67,
  },
  {
    name: "Supplier 2",
    id: 2,
    status: "Completed",
    progress: 100,
    scope_1: '1.3K MTCO2e',
    scope_2: '11.4K MTCO2e',
    scope_3: '95.7K MTCO2e',
    total: '108.4K MTCO2e',
    report_id: 67,
  },
  {
    name: "Supplier 3",
    id: 3,
    status: "Completed",
    progress: 100,
    scope_1: '1.2K MTCO2e',
    scope_2: '2.5K MTCO2e',
    scope_3: '12.4K MTCO2e',
    total: '16.1K MTCO2e',
    report_id: 67,
  },
    {
    name: "Supplier 4",
    id: 4,
    status: "Completed",
    progress: 100,
    scope_1: '3.7K MTCO2e',
    scope_2: '7.8K MTCO2e',
    scope_3: '23.4K MTCO2e',
    total: '34.9K MTCO2e',
    report_id: 67,
  },
  {
    name: "Supplier 5",
    id: 5,
    status: "Completed",
    progress: 100,
    scope_1: '145.2K MTCO2e',
    scope_2: '215.7K MTCO2e',
    scope_3: '800.8K MTCO2e',
    total: '1.2M MTCO2e',
    report_id: 67,
  }
]


const maxVal = 97;
const minVal = 0;
const base = 10;
const nonZeroCount = 30;

for (let i = 6; i <= 40; i++) {

  const normalizedI = (i - 6) / (40 - 6) // normalizes i between 0 and 1
  const scale = Math.log10(1 + i * (base - 1) / (nonZeroCount - 1)); // logarithmic scale
  const value = (maxVal - normalizedI * (maxVal - minVal) * scale).toFixed();

  suppliers.push(
    {
      name: `Supplier ${i}`,
      id: i,
      status: value > 0 ? "In Progress" : "Not Started",
      progress: value > 0 ? value : 0,
      scope_1: "",
      scope_2: "",
      scope_3: "",
      total: "",
      report_id: 67,
  }
  )
}

// let csvIsCool = ""
// for (let i = 0; i < suppliers.length; i++) {
//   const cells = [ suppliers[i].name, suppliers[i].status, suppliers[i].progress, suppliers[i].scope_1, suppliers[i].scope_2, suppliers[i].scope_3, suppliers[i].total ]
//   csvIsCool += cells.join(',')
//   csvIsCool += "\n"
// }
// console.log('cs veee')
// console.log(csvIsCool)

export default function SuppliersTable() {
  const auth = useAuth()
  const navigate = useNavigate()
  const [ questionnaires, setQuestionnaires ] = useState([])

  const handleReportClick = () => {
    navigate(`/insights/details?report_id=105`)
  }

  const supplierColDefs = [
    {field: "name", flex: 2, headerName: "Company Name"},
    {field: "status", flex: 2, cellRenderer: (props) => (
      <span className={props.value.toLowerCase().replace(" ","-")}>{props.value}</span>
    )},
    {field: "progress", headerName: "Progress", flex: 1.25, cellRenderer: (props) => (
      <span className={classnames("agGridButtonCell supplierProgress", {disabled: !parseInt(props.value)})}>{props.value}%</span>
    )},
    {field: "scope_1", headerName: "Scope 1", flex: 2, cellRenderer: (props) => (
      <span>{props.value}</span>
    )},
    {field: "scope_2", headerName: "Scope 2", flex: 2, cellRenderer: (props) => (
      <span>{props.value}</span>
    )},
    {field: "scope_3", headerName: "Scope 3", flex: 2, cellRenderer: (props) => (
      <span>{props.value}</span>
    )},
    {field: "total", headerName: "Total", flex: 2, cellRenderer: (props) => (
      <span>{props.value}</span>
    )},
    {field: "report_id", headerName: "Insights", flex: 1, cellRenderer: (props) => {
      return (
        <span className={classnames("agGridButtonCell", {disabled: props.data.status !== "Completed"})}>
          <Tooltip title="See Report">
            <IconButton position="solo" className="iconButton skeleton unbacked" onClick={handleReportClick} icon={<BarChartIcon/>}/>
          </Tooltip>
        </span>
      )
    }},
    {field: "report_id", headerName: "Export", flex: 1, cellRenderer: (props) => (
      <span className={classnames("agGridButtonCell", {disabled: props.data.status !== "Completed"})}>
        <Tooltip title="Export">
          <IconButton position="solo" className="iconButton skeleton unbacked" onClick={() => downloadCSV(props.data)} icon={<UploadFileIcon/>}/>
        </Tooltip>
      </span>
    )}
  ]

const autoSizeStrategy = {
  type: 'fitGridWidth'
}

let totalSuppliers = 0
let totalNotStarted = 0
let totalCompleted = 0
let totalInProgress = 0


suppliers.forEach( supplier => {
  totalSuppliers++
  if(supplier.status === "Not Started") {
    totalNotStarted++
  } else if(supplier.status === "In Progress") {
    totalInProgress++
  }
  else if(supplier.status === "Completed") {
    totalCompleted++
  }
})


  return(
      <div className="page admin-table-wrapper" id="suppliers">

        <header className="pageHeader">
          <h1>Supplier Data</h1>
        </header>




        <div className="statsBlock green">
            <div className="stat">
                <header>
                  <h5><MeasureIcon/> 2023 Total</h5>
                </header>
                <figure>
                  <h4>1,897,861</h4>
                  <label className='body3'>
                    MTCO2e
                  </label>
                </figure>
              </div>

              <div className="stat">
                <header>
                  <h5>Scope 1</h5>
                </header>
                <figure>
                  <h4>172,056</h4>
                  <label className='body3'>
                    MTCO2e
                  </label>
                </figure>
              </div>
   
              
             
                  <div className="stat">
                    <header>
                      <h5>Scope 2</h5>
                    </header>
                    <figure>
                      <h4>257,500</h4>
                      <label className='body3'>
                        MTCO2e
                      </label>
                    </figure>
                  </div>
              
              <div className="stat">
                <header>
                  <h5>Scope 3</h5>
                </header>
                <figure>
                  <h4>1,468,125</h4>
                  <label className='body3'>
                    MTCO2e
                  </label>
                </figure>
          </div>
         
             </div>
   <br/>

   <div className="subRowContainer">
    <div className="subRow">
      <div className="subRowStat">
        <div className='body3'>
          Overall status
        </div>
        <div>
          <h5 style={{display: 'flex'}}><div className='progressIndicator'/>In Progress | 12%</h5>
        </div>
      </div>
      <div className="subRowStat">
        <div className='body3'>
          Suppliers completed
        </div>
        <div>
          <h5>{totalCompleted} of {totalSuppliers}</h5>
        </div>
      </div>
      <div className="subRowStat">
        <div className='body3'>
          Suppliers in progress
        </div>
        <div>
          <h5>{totalInProgress} of {totalSuppliers}</h5>
        </div>
      </div>
      <div className="subRowStat">
        <div className='body3'>
          Suppliers not started
        </div>
        <div>
          <h5>{totalNotStarted} of {totalSuppliers}</h5>
        </div>
      </div>
    </div>
    <IconButton value="Export Spreadsheet" onClick={() => downloadAggregateCSV(suppliers)} className="iconButton positive" position="left" icon={<UploadFileIcon/>}/>


   </div>

   <br/>
          

        {
          <div className="ag-theme-quartz full-container-table">
            <AgGridReact
              domLayout='autoHeight'
              suppressDragLeaveHidesColumns 
              rowData={suppliers} 
              columnDefs={supplierColDefs} 
              autoSizeStrategy={autoSizeStrategy}
              enableCellTextSelection
              ensureDomOrder
              copyHeadersToClipboard
            />
          </div>
        }

      </div>
  )
}
