import React, { useState, useEffect } from 'react'
import './Admin.scss'
import { Route, Link, useNavigate, useLocation, Outlet } from 'react-router-dom'
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { useAuth } from '../providers/AuthProvider'

import '../styles/AppStyles'

import ToastProvider from '../providers/ToastProvider'

import ListItemButton from '@mui/material/ListItemButton'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as HamburgerIcon } from '../assets/Hamburger.svg'
import { ReactComponent as InsightsIcon } from '../assets/Insights.svg'
import { ReactComponent as QuestionsIcon } from '../assets/Questions.svg'
import { ReactComponent as PersonIcon } from '../assets/Person.svg'
import { ReactComponent as MeasureIcon } from '../assets/Measure.svg'
import { ReactComponent as DraftsIcon } from '../assets/Drafts.svg'
import { ReactComponent as InvitesIcon } from '../assets/Invites.svg'
import { ReactComponent as SusIcon } from '../assets/Sus.svg'
import { ReactComponent as LocationsIcon } from '../assets/Locations.svg'



import ChLogo from '../assets/ChLogo'
import ChWordmark from '../assets/ChWordmark'

export default function AdminFrame(props) {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }


  const defaultNav = [
    {
      text: "Dashboard",
      icon: InsightsIcon,
      href: '/admin'
    },
    {
      text: "Users",
      icon: PersonIcon,
      href: '/admin/users'
    },
    {
      text: "Questions",
      icon: QuestionsIcon,
      href: '/admin/questions'
    },
    {
      text: "Questionnaires",
      icon: MeasureIcon,
      href: '/admin/questionnaires'
    },
    {
      text: "Story Drafts",
      icon: DraftsIcon,
      href: '/admin/sus_page_drafts'
    },
    {
      text: "Invites",
      icon: InvitesIcon,
      href: '/admin/invites'
    },
    {
      text: "Suppliers",
      icon: LocationsIcon,
      href: '/admin/suppliers'
    },
    {
      text: "Style Guide",
      icon: SusIcon,
      href: '/admin/style_guide'
    },
  ]

  const [ nav, setNav ] = useState(defaultNav)

  useEffect(() => {
    if (auth.userInfo) {
      if (auth.userInfo.report_available && !auth.userInfo.report_current) {
        const measureTab = { text: "Measure", icon: MeasureIcon, href: '/questionnaire/start'}
        const newNav = [...defaultNav]
        newNav.splice(1, 0, measureTab)
        setNav(newNav)
      }
      console.log('user in')
    }
    console.log('no use')
  }, [auth.userInfo])

  const goToNavItem = (navItem) => {
    if (navItem.text === "Insights" || navItem.text === "Sustainability") {
      if (auth.userInfo && auth.userInfo.report_available) {
        navigate(navItem.href)
      }
    } else {
      navigate(navItem.href)
    }
    setOpen(false)
  }

  return(
    <div>
      <ToastProvider>
        <div className="mobileHeader">
          <div className="hamburger" onClick={handleDrawerOpen}>
            <HamburgerIcon />
          </div>
          <div className="logo">
            <ChWordmark />
          </div>
        </div>

        <div className={`navDrawerCloseTarget ${open ? 'open' : 'notOpen'}`} onClick={handleDrawerClose}>
        </div>

        <div className={`navDrawer ${open ? 'open' : 'notOpen'}`}>
          <header onClick={() => (navigate('/admin/'))}>
            <div className="drawerTarget closed">
              <ChLogo />
            </div>
            <div className="drawerTarget open">
              <ChWordmark />
            </div>
          </header>
          <ul>
            {nav.map((navItem, index) => {
              return (
                <li key={navItem.text} className={`${(navItem.text === 'Insights' || navItem.text === 'Sustainability') && !(auth.userInfo && auth.userInfo.report_available) ? 'disabled' : ''}`}>
                  <ListItemButton onClick={() => {goToNavItem(navItem)}}>
                    <span className={navItem.href === window.location.pathname ? 'icon current' : 'icon'}>
                      <SvgIcon component={navItem.icon} inheritViewBox />
                    </span>
                    <span className="label">{navItem.text}</span>
                  </ListItemButton>
                </li>
              )
            })}
          </ul>
          <div className="logOut">
            <input type="button" value="Logout" className="skeleton" onClick={() => {auth.logOut(); navigate('/');}} />
          </div>
        </div>
        <div id="admin" className={`${isLoading ? 'loading' : ''}`}>
          <div id="content">
            <div className="loader">
              <div className="lds-facebook"><div></div><div></div><div></div></div>
            </div>
            <Outlet context={[isLoading, setIsLoading]}/>
          </div>
        </div>
      </ToastProvider>
    </div>
  )
}
