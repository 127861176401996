import React from 'react'

import { ReactComponent as InfoIcon } from '../assets/Info.svg'

export default function Tooltip(props) {
  return (
    <div className={`tooltip ${props.align || ''}`}>
      <div className="icon">
        <InfoIcon />
      </div>
      <div className="content body3">
        { props.children }
      </div>
    </div>
  )
}
