import React, { useState, useEffect, createRef } from 'react'
import Chart from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);


export default function NetZeroChart(props) {

  const colors = []

  const chartRef = createRef()
  let chart = null

  const AMOUNT_OF_YEARS = 11

  const projectEmissions = (initialKgCO2e, startYear, numYears, targetYear) => {

    const emissionData = []

    let lastYearEmissions = initialKgCO2e
    const netIncrement = 1.0 / (props.netZeroTargetYear - startYear)

    let lastYearReductionForecast = props.reductionForecastKgs

    const numTargetYears = targetYear - startYear
    const targetPercentIncrement = props.targetReductionPercent * 0.01 / (numTargetYears - 1)

    for ( let i = 0; i < numYears; i++) {
      const businessAsUsual = lastYearEmissions + (i ? (lastYearEmissions * (0.01 * props.projectedRevGrowthPercent)) : 0)
      lastYearEmissions = businessAsUsual

      const reductionForecast = lastYearReductionForecast + (i ? (lastYearReductionForecast * (0.01 * props.projectedRevGrowthPercent)) : 0)
      lastYearReductionForecast = reductionForecast

      let finalizedReductionForecast = reductionForecast ? businessAsUsual - reductionForecast : 0
      
      let netEmissions = 0;
      let carbonRemoval = 0;

      if(reductionForecast) {
        netEmissions = finalizedReductionForecast - (finalizedReductionForecast * netIncrement * i) 
        if(netEmissions < 0 || props.goneNeutral ) {
          netEmissions = 0
        }
        if(!i && !props.goneNeutral) {
          netEmissions = businessAsUsual
        }
        carbonRemoval = finalizedReductionForecast - netEmissions
        if(!i && props.goneNeutral) {
          carbonRemoval = businessAsUsual - netEmissions
        }
      } else {
        netEmissions = businessAsUsual - (businessAsUsual * netIncrement * i)
        if(netEmissions < 0 || props.goneNeutral ) {
          netEmissions = 0
        }
        carbonRemoval = businessAsUsual - netEmissions
      }


      const targetPercent = i >= numTargetYears ? (props.targetReductionPercent * 0.01) : i * targetPercentIncrement
      const targetReduced = targetPercent * businessAsUsual

      emissionData.push({
        businessAsUsual: businessAsUsual,
        netEmissions: netEmissions,
        carbonRemoval: carbonRemoval,
        targetEmissions: businessAsUsual - targetReduced,
        reductionForecast: finalizedReductionForecast
      })
    }
    return emissionData
  }
  const projectedEmissions = projectEmissions(
    props.historicalEmissions.kgCO2e,
    props.historicalEmissions.year,
    AMOUNT_OF_YEARS,
    props.reductionTargetYear
  )

  function createYearArray(startYear, targetYear) {
    let years = [];
    for (let year = startYear; year <= targetYear; year++) {
        years.push(year);
    }
    return years;
}

  const generateData = () => {
    return {
      labels: createYearArray(props.historicalEmissions.year, props.historicalEmissions.year + AMOUNT_OF_YEARS - 1),
      datasets: [
        {
          type: 'bar',
          data: projectedEmissions.map(emissions => Math.round(emissions.targetEmissions/1000)),
          label: "Target Emissions",
          borderColor: projectedEmissions.map((emissions, index) => index ? '#CC0000' : "rgba(0, 0, 0, 0)"),
          borderWidth: {left: 0, right: 0, top: 2, bottom: 0},
          barPercentage: 1.01,
          backgroundColor: "rgba(0, 0, 0, 0)",
          xAxisID: 'x1'
        },
        {
          type: 'line',
          data: projectedEmissions.map(emissions => Math.round(emissions.netEmissions/1000)),
          label: "Net Emissions",
          pointBackgroundColor: 'white',
          pointBorderColor: 'white',
          pointRadius: 6,
          borderWidth: 0,
          xAxisID: 'x2'
        },
        {
          type: 'bar',
          data: projectedEmissions.map((emissions, index) => index ? Math.round(emissions.reductionForecast/1000) : 0),
          label: "Forecast w/ initiatives",
          borderColor: '#FBE74D',
          backgroundColor: '#FBE74D',
          stack: 0,
          xAxisID: 'x2',
        },
        {
          type: 'bar',
          data: projectedEmissions.map((emissions, index) => index ? (Math.round(emissions.businessAsUsual/1000)) : 0),
          label: "Business As Usual",
          borderColor: projectedEmissions.map((emissions, index) => index ? '#ABABB5' : 'black'),
          backgroundColor: projectedEmissions.map((emissions, index) => index ? '#ABABB5' : 'black'),
          stack: 0,
          xAxisID: 'x3',
        },
        {
          type: 'bar',
          data: [projectedEmissions.map(emissions => (Math.round(emissions.businessAsUsual/1000)))[0]],
          label: "Historical Emissions",
          borderColor: 'black',
          backgroundColor: 'black',
          stack: 0,
          xAxisID: 'x3',
        },
        {
          type: 'bar',
          data: projectedEmissions.map((emissions, index) => Math.round((emissions.carbonRemoval < 0 ? 0 : emissions.carbonRemoval * -1/1000))),
          label: "Carbon Removal",
          borderColor: '#329E33',
          backgroundColor: '#329E33',
          stack: 0,
          xAxisID: 'x2',
        },
    ]
    }
  }

  const config =  () => ({
    data: generateData(),
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: Math.round(props.historicalEmissions.kgCO2e/1000),
              yMax: Math.round(props.historicalEmissions.kgCO2e/1000),
              borderColor: 'black',
              borderWidth: 2,
              backgroundColor: 'black',
              borderDash: [6, 6],
              label: {
                content: `Baseline Emissions: ${Math.round(props.historicalEmissions.kgCO2e/1000)}`,
                display: false,
              },
              enter({ element }, event) {
                  element.label.options.display = true;
                  return true; // force update
              },
              leave({ element }, event) {
                  element.label.options.display = false;
                  return true;
              }
            }
          }
        },
        legend: {
          display: false
        },
        tooltip:{
          enabled: true,
          mode: 'index',
          callbacks: {
           label: function(tooltipItem) {
                if((tooltipItem.datasetIndex === 1 || tooltipItem.datasetIndex === 3 || tooltipItem.datasetIndex === 4 || tooltipItem.datasetIndex === 6) && !tooltipItem.dataIndex) {
                  return ""
                } else {
                  console.log(tooltipItem)
                  return tooltipItem.yLabel;
                }
           }
        } }
      },
      scales:{
        x1: {
          grid: {
            display: false,
          },
            display: true,
          },
        x2: {
            display: false,
          },
        x3: {
          display: false,
        },
        y:{
          grid:{
            display: true,
          }
        }
      }
    }
  })

  useEffect(() => {
    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {

    if (chart && chart.destroy) {
      chart.destroy()
    }

    chart = new Chart(chartRef.current, config())
    chart.update()
    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }

  }, [props.data, props.reductionTargetYear, props.reductionForecastKgs, props.projectedRevGrowthPercent, props.targetReductionPercent, props.netZeroTargetYear, props.goneNeutral])  

  return (
    <div className={"netZeroChartHolder" + (props.expanded ? " expanded" : "")}>
      <canvas ref={chartRef}/>
    </div>
  )
}
