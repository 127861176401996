import React, { useState } from 'react'
import axios from 'axios'
import { CircularProgress } from '@mui/material'

export default function SubmitButtons(props) {
  const [ isNA, setIsNA ] = useState(false)


  const submitText = props.submitText
  let submitEnabled = false

  const toggleNA = () => {
    setIsNA(!isNA)
  }

  const wrappedSubmit = () => {
    if(!props.onSubmit) {return null;}
    props.onSubmit()
  }

  const completed = props.completed

  return (
    <div className={`questionActions ${props.noTopMargin ? 'noTopMargin' : ''}`}>
      <div >
      </div>      
      <div className="buttons">
        {props.isSaving && (<span className="micro"><span className="savingText">Saving...</span> <CircularProgress size={16} color="inherit" /></span>)}
        <div className={completed ? "completed" : ""}>
          <div className="body3">{props.completionText}</div>
          {completed && <div className='micro'>Contact admin for further modifications</div>}
        </div>
        <input type="button" value="Back" onClick={props.goPrev} />
       { submitText && <input type="button" className="positive" value={submitText} onClick={wrappedSubmit} disabled={!props.submitEnabled || props.dataSent} />}
      </div>
    </div>
  )
}
