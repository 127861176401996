import React from 'react'

import { ReactComponent as LaptopIcon } from '../assets/Laptop.svg'
import { ReactComponent as DesktopIcon } from '../assets/Desktop.svg'

export default function ViewOnDesktop(props) {
  const copyText = props.copy || "carbon calculator"
  return (
    <div id="viewOnDesktop">
      <div className="soz">
        <div className="icons">
          <LaptopIcon /><DesktopIcon />
        </div>
        <h2>ClimateHound’s {copyText} is best used on a laptop or desktop.</h2>
        <div className="body3">
          Please switch to a larger device or make your browser window bigger.
        </div>
      </div>
    </div>
  )
}
