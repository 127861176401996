import React from 'react'

import ValuesSection from "./ValuesSection";

export default function EcoDrivingValues(props) {

  const inputFieldDom = (name, parser, unit) => {
    return (
      <span className={unit === '%' ? 'percentUnit' : unit === '$' ? 'dollarUnit' : ""}>
        <input
          type="text"
          name={name}
          value={props.inputs[name] || ""}
          onChange={(e) => {
            const newVal = parser ? parser(e.target.value) : e.target.value;
            props.updateDraft(name, newVal);
          }}
        />
        {unit && <span>{unit}</span>}
      </span>
    );
  };

  const staticFieldDom = (name, parser, values) => {
    return (
      <span>
        <b>{parser(props.inputs[name])}</b>
      </span>
    );
  };

  const passThru = (x) => x;

  return (
    <>
      <ValuesSection name="values">
        <div className="body4">
          Complete the fields below to simulate how your business would benefit from this initiative.
        </div>
        My company fleet is currently driven about {staticFieldDom("total_miles_annually", parseInt)} miles per year
        and roughly {inputFieldDom("percentage_of_highway_miles", parseFloat, '%')} are highway miles. The average fuel economy of my fleet is{" "} 
        {staticFieldDom("avg_fuel_economy", parseFloat)} <b>mpg</b>. Reducing our top cruising speed from {inputFieldDom("previous_top_speed", parseInt)}{" "}
        to a target cruising speed of {staticFieldDom("encouraged_top_speed", parseInt)} <b>miles per hour</b> will improve our fuel economy by 1% per mph reduction over 60 mph. Reducing all 
        rapid acceleration and braking can improve non-highway fuel economy by 3%. Together these actions will improve my fuel economy to 
        {" "}{staticFieldDom("improved_fuel_economy_rate", parseFloat)} <b>mph</b> saving {staticFieldDom("gallons_per_year_saved", parseFloat)} gallons per year.
      </ValuesSection>
      <ValuesSection name="completed-values">

        My company fleet is currently driven a total of {staticFieldDom("total_miles_annually", parseInt)} miles per year,
        of which {staticFieldDom("percentage_of_highway_miles", parseFloat, '%')} are highway miles. The average fuel economy of my vehicles is 
        {" "}{staticFieldDom("avg_fuel_economy", parseFloat)} <b>mpg</b>. We reduced our top speeds from {staticFieldDom("previous_top_speed", parseInt)} to a target cruising speed of {staticFieldDom("encouraged_top_speed", parseInt)}
        <b> miles per hour</b>, which improved our fuel economy by 1% per mph reduction over 60 mph. 
        Reducing all rapid acceleration and braking can improve non-highway fuel economy by 3%. Together these actions will improve my fuel economy to
        {" "}{staticFieldDom("improved_fuel_economy_rate", parseFloat)} <b>mph</b> saving {staticFieldDom("gallons_per_year_saved", parseFloat)} <b>gallons per year</b>.
      </ValuesSection>
      <ValuesSection name="confirm-values">
        <div className="body4">
          Review the information below and confirm or update all values to match the final outcome of implementing this 
          initiative. Ensure that all information is accurate before completing this reduction.        
        </div>
        My company fleet is currently driven a total of {staticFieldDom("total_miles_annually", parseInt)} miles per year,
        of which {inputFieldDom("percentage_of_highway_miles", parseFloat, '%')} are highway miles. The average fuel economy of my vehicles is 
        {" "}{staticFieldDom("avg_fuel_economy", parseFloat)} <b>mpg</b>. We reduced our top speeds from {inputFieldDom("previous_top_speed", parseInt)} {" "}
        to a target cruising speed of {inputFieldDom("encouraged_top_speed", parseInt)} <b>miles per hour</b>, which improved our fuel economy by 1% per mph reduction over 60 mph. 
        Reducing all rapid acceleration and braking can improve non-highway fuel economy by 3%. Together these actions will improve my fuel economy to
        {" "}{staticFieldDom("improved_fuel_economy_rate", parseFloat)} <b>mph</b> saving {staticFieldDom("gallons_per_year_saved", parseFloat)} <b>gallons per year</b>.
      </ValuesSection>
      <ValuesSection name="assumptions-final">
        <ul>
          <li >
            Target cruising speed:{" "}
            <span>{inputFieldDom("encouraged_top_speed", parseInt)} mph</span>
          </li>
          <li>
            Average cost of gasoline:{" "}
            <span>{inputFieldDom("price_of_gasoline", parseFloat, '$')}</span>
          </li>
        </ul>
      </ValuesSection>
      <ValuesSection name="assumptions">
        <ul>
          <li >
            Target cruising speed:{" "}
            <span>{inputFieldDom("encouraged_top_speed", parseInt)} mph</span>
          </li>
          <li>
            Average cost of gasoline:{" "}
            <span>{inputFieldDom("price_of_gasoline", parseFloat, '$')}</span>
          </li>
        </ul>
      </ValuesSection>
    </>
  );
}
