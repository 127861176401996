import React from 'react'

export default function GuideSection(props) {
  return (
      <div className="guideSection">
        <header className="guideHeader">
          {props.name}
        </header>
        { props.children }
      </div>
  )
}
