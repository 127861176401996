import React, { useState, useEffect, useRef } from 'react'
import NavButtons from './NavButtons'
import axios from 'axios'
import { ReactComponent as PlusIcon } from '../../assets/Plus.svg'
import Modal from '../Containers/Modal'
import LabeledField from '../Forms/LabeledField'
import LabeledSelect from '../Forms/LabeledSelect'
import _ from 'lodash'
import { useLocation } from '../../providers/LocationProvider'
import { useAuth } from '../../providers/AuthProvider'
import FileUploadComponent from '../Forms/DropUpload'

import listOfStateValues from '../../utils/listOfStateValues'

export default function AddressQuestion(props) {
  const defaultLocation = {
    name: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    sq_ft: "",
    employee_count: "",
  }

  const autoCompleteRef = useRef()
  const inputRef = useRef()
  
  const [errors, setErrors] = useState({})
  const [addressModalOpen, setAddressModalOpen] = useState(false)
  const [removalModalOpen, setRemovalModalOpen] = useState(false)
  const [removalAddress, setRemovalAddress] = useState(null)
  const [addressObject, setAddressObject] = useState(defaultLocation)
  const [edit, setEdit] = useState({open: false, index: 0})
  const {locations, setLocations} = useLocation()
  const auth = useAuth()


  const openAddressModal = () => {
    setAddressModalOpen(true)
  }

  const openRemovalModal = (address) => {
    setRemovalModalOpen(true)
    setRemovalAddress(address)
  }

  const onRemovalModalClose = () => {
    setRemovalModalOpen(false)
    setRemovalAddress(null)
  }

  const submit = () => {
    return axios.post(`/api/questions/${props.question.id}/mark_a`)
  }

  function nextEnabled() {
    return locations.length > 0
  }

  const handleChange = (e) => {
    const newAddress = addressObject
    newAddress[e.target.name] = e.target.value
    setAddressObject({...addressObject, ...newAddress})
  }

  useEffect(() => {
    axios.get('/api/locations/').then(
      res => {
        setLocations(res.data)
      }
    ).catch(
      err => console.log(err)
    )
  }, [])

  useEffect(() => {
    const options = {
      componentRestrictions: { country: "usa" },
      fields: ["address_components", "geometry", "icon", "name"]
    }

    const autocompleteInstance = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    )

    const createAddressFromPlace = (place) => {
      const newAddress = {
        name: addressObject.name || place.name,
        street_address: (place.address_components.find(comp => comp.types.includes('street_number'))?.long_name || "") + " " + (place.address_components.find(comp => comp.types.includes('route'))?.long_name || ""),
        city: place.address_components.find(comp => comp.types.includes('locality'))?.long_name,
        state: place.address_components.find(comp => comp.types.includes("administrative_area_level_1"))?.short_name,
        zip_code: place.address_components.find(comp => comp.types.includes("postal_code"))?.long_name,
      }
      if(newAddress.street_address === " ") {
        newAddress.street_address = ""
      }
      return newAddress
    }

    autocompleteInstance.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace()
      console.log(place)
      const newAddressObject = createAddressFromPlace(place)
      setAddressObject({...addressObject, ...newAddressObject})
      inputRef.current.value = newAddressObject.street_address
    })
    autoCompleteRef.current = autocompleteInstance
  }, [addressObject])

  const modalBody = (
    <div className="addressBody">
      <LabeledField
        name='name'
        label='Name of Location'
        formErrors={errors}
        values={addressObject}
        className="small"
        placeholder='Location Name'
        onChange={handleChange}
      />
      <label className={errors['street_address'] ? 'error' : ''}>Address</label>
      <input className={"small" + (errors['street_address'] ? " error" : "")} ref={inputRef} />
      {errors['street_address'] && (<div className="errorMessage">{errors['street_address']}</div>)}
     <LabeledField
        name='city'
        label='City'
        formErrors={errors}
        values={addressObject}
        className="small"
        placeholder='City'
        onChange={handleChange}
      />
      <div className='doubleFields'>
        <LabeledSelect
          name='state'
          label='State'
          formErrors={errors}
          values={addressObject}
          options={listOfStateValues}
          className="small"
          placeholder='State'
          onChange={handleChange}
        />
        <LabeledField
          type='number'
          name='zip_code'
          label='Zip Code'
          formErrors={errors}
          values={addressObject}
          className="small"
          placeholder='Zip Code'
          onChange={handleChange}
        />
      </div>
      <div className='doubleFields'>
        <LabeledField
          type='number'
          name='sq_ft'
          label='Total sq ft'
          formErrors={errors}
          values={addressObject}
          className="small"
          placeholder='Total sq ft'
          onChange={handleChange}
          />
        <LabeledField
          type='number'
          name='employee_count'
          label='Total full time employees'
          formErrors={errors}
          values={addressObject}
          className="small"
          placeholder='Total employees'
          onChange={handleChange}
        />
      </div>
    </div>
  )

  const onLocationAdd = () => {
    const newErrors = {}
    if(!addressObject['name']) {
      newErrors['name'] = "Name is required."
    }
    if(!addressObject['street_address']) {
      newErrors['street_address'] = "Address is required."
    }
    if(!addressObject['city']) {
      newErrors['city'] = "City is required."
    }
    if(!addressObject['state']) {
      newErrors['state'] = "State is required. "
    }
    if(!addressObject['zip_code']) {
      newErrors['zip_code'] = "Zip code is required. "
    }
    if(!addressObject['sq_ft']) {
      newErrors['sq_ft'] = "Square footage is required. "
    }
    if(!addressObject['employee_count']) {
      newErrors['employee_count'] = "Employee count is required. "
    }
    if(edit.open) { 
      setErrors(newErrors)
      if (!Object.keys(newErrors).length) {
        const {name, street_address, id, city, state, zip_code, sq_ft, employee_count} = addressObject
        axios.patch('api/locations/' + id, {
          location: {
            name,
            street_address,
            city,
            state,
            zip_code,
            sq_ft,
            employee_count
          }
        }).then(
          res => {
            const newLocations = [...locations]
            newLocations[edit.index] = res.data
            setLocations([...newLocations])
            onAddressModalClose()
            setEdit({open: false, index: 0})
          }
        )
      }
    } else {
      setErrors(newErrors)
      if (!Object.keys(newErrors).length) {
        axios.post('/api/locations/', {
          location: addressObject
        }).then(
          (res) => {
            const newLocations = [...locations];
            setLocations([...newLocations, res.data])
            onAddressModalClose()
          }
        ).catch(
          err => console.log(err)
        )
      }
    }
  }

  const onLocationRemove = () => {
    axios.delete('/api/locations/' + removalAddress.id).then(
      res => {
        const newLocations = locations.filter( address => !_.isEqual(address, removalAddress) )
        if(removalAddress.id === auth.userInfo.primary_location_id) {
          axios.patch('/api/users/me', {
            primary_location_id: newLocations[0].id
          }).then(res => {
            auth.setUserInfo(res.data)
          })
        }
        setLocations([...newLocations])
        onRemovalModalClose();
      }
    ).catch(
      err => console.log(err)
    )
  }

  const onAddressModalClose = () => {
    setAddressObject(defaultLocation)
    inputRef.current.value = ""
    setErrors({})
    setEdit({open: false, index: 0})
    setAddressModalOpen(false)
  }

  const onEditClick = (addressToEdit) => {
    setEdit({open: true, index: locations.findIndex(address => _.isEqual(addressToEdit, address) )})
    setAddressObject(addressToEdit)
    inputRef.current.value = addressToEdit.street_address
    openAddressModal()
  }

  const removalBody = (
    <div className="addressRow">
      <div>
        <b>{removalAddress?.name || removalAddress?.address}</b>
        <div className="micro">{parseInt(removalAddress?.sq_ft)} sq ft | {parseInt(removalAddress?.employee_count)} employees</div>
      </div>
    </div>
  )

  return (
    <div>
      <Modal
        title="Add a location"
        subtitle="Fill out the details below to add each location."
        size="large"
        open={addressModalOpen}
        close={onAddressModalClose}
        bodyDom={modalBody}
        actionsDom={
          <div className="locationDetailActions">
            <input type="button" value="Cancel" className="small" onClick={onAddressModalClose} />
            <input type="button" value={edit.open ? "Update location" : "Add location"} className="positive small" onClick={onLocationAdd} />
          </div>
        }
      />
      <Modal
        title="Delete Location?"
        size="small"
        open={removalModalOpen}
        close={onRemovalModalClose}
        bodyDom={removalBody}
        actionsDom={
          <div>
            <input type="button" value="Remove" className='destructive' onClick={onLocationRemove} />
            <input type="button" value="Go Back" onClick={onRemovalModalClose} />
          </div>
        }
      />
      <div className="questionInputs">
        <div className="oneField addressField">
        {locations.map( singleAddress => {
            return <div className="addressRow" key={singleAddress.street_address + singleAddress.zip_code}>
              <div>
                <b>{singleAddress.name || singleAddress.street_address}</b>
                <div className="micro">{parseInt(singleAddress.sq_ft)} sq ft | {parseInt(singleAddress.employee_count)} employees</div>
              </div>
                <div className="addressActions">
                  <input onClick={() => onEditClick(singleAddress)} className='skeleton' type='button' value='Edit' />
                  <input
                    onClick={() => openRemovalModal(singleAddress)}
                    className='skeleton destructive'
                    disabled={!(locations.length - 1)}
                    type='button'
                    value='Remove'
                  />
                </div>
              </div>
          })}
        <div className="addLocation">
          <div onClick={openAddressModal} className="iconButton">
            <input className='medium' type='button' value='Add a Location' />
            <div className="icon right"><PlusIcon/></div>
          </div>
        </div>
        </div>
      </div>
      <FileUploadComponent question={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled()}
      />
    </div>
  )
}


