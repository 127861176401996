import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'
import jwt_decode from "jwt-decode"

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common = "application/json"

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token")
  const decoded = token ? jwt_decode(token) : {}
  if (token && (decoded['exp'] * 1000) > Date.now()) {
    config.headers.Authorization = `Bearer ${token}`
  } else {
    localStorage.removeItem('token')
    delete config.headers['Authorization']
  }
  return config
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
