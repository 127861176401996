import React, { useEffect, createRef } from 'react'
import { interpolateViridis } from 'd3-scale-chromatic'
import Chart from 'chart.js/auto'

export default function Bar(props) {

  const colors = []

  for (let i = 0; i < props.data.length; i++) {
    colors.push(interpolateViridis(i / props.data.length))
  }

  const chartRef = createRef()
  let chart = null

  const generateData = (propLabels, propData) => {
    return {
      labels: propLabels,
      datasets: [{
        data: props.kgToTonne ? propData.map(datum => datum/1000) : propData,
        borderWidth: 0,
        backgroundColor: colors,
        hoverOffset: 4
      }]
    }
  }

  const config =  () => ({
    type: 'bar',
    data: generateData(props.labels, props.data),
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip:{
          enabled: true,
          callbacks: {
            label: function(context) {
                let label = context.dataset.data[context.dataIndex]
                if (typeof label === "number") {
                  label = Math.abs(label) >= 1.0 ? label.toFixed(2) : label.toPrecision(2)
                } else {
                  label = 0
                }
                label += ' MTCO2e'
                return label
            }
          }
        }
      },

      scales:{
        x:{
          display: false
        },
        y:{
          grid:{
            display: true,
            color: function(context) {
              if (context.tick.value === 0) {
                return '#414141';
              } 
              return '#ABABB5'
            },
          }
        }
      }
    }
  })

  useEffect(() => {
    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {

    if (chart && chart.destroy) {
      chart.destroy()
    }

    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }

  }, [props.data])  

  return (
    <canvas ref={chartRef} />
  )
}
