import React, { useState, useEffect } from 'react'
import { Box, TextField, Button, Alert } from '@mui/material'
import { useAuth } from '../../providers/AuthProvider'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ClimateHoundSVGSrc from '../../assets/ClimateHound_Wordmark_BLK2.svg'
import LabeledField from '../Forms/LabeledField'
import { useToast } from '../../providers/ToastProvider'
import axios from 'axios'

export default function Login() {



  const defaultLogin = {
    email: '',
    password: ''
  }

  const toast = useToast()

  useEffect(() => {
    if (localStorage.getItem('toast')) {
      toast.setToast(localStorage.getItem('toast'), 3000, 'success')
      localStorage.removeItem('toast')
      console.log('setting tosa')
    }
  }, [])

  const [ login, setLogin ] = useState(defaultLogin)
  const [forgotPassword, setForgotPassword] = useState(false)

  const [errors, setErrors] = useState({})
  const auth = useAuth()

  const location = useLocation()
  const navigate = useNavigate()
  const fromPath = location.state?.from?.pathname || "/"

  const toggleReset = () => setForgotPassword(!forgotPassword)

  const sendResetEmail = async (e) => {
    e?.preventDefault()    
    let pendingErrors = {}
    if (!login['email']) {
      pendingErrors['email'] = "Email is required."
    }
    
    if (Object.keys(pendingErrors).length > 0) {
      setErrors(pendingErrors)
    } else {
      setErrors({})
      axios.post('/api/users/user_request_pw_reset_link', {email: login['email']}).then((res) => {
        localStorage.setItem('toast', 'Password reset link sent to provided email')
        window.location = window.location
      })
    }
  }

  const signIn = async (e) => {
    if (e) {
      e.preventDefault()
    }
    console.log('signing in')
    let pendingErrors = {}
    if (!login['email']) {
      pendingErrors['email'] = "Email is required."
    }
    if (!login['password']) {
      pendingErrors['password'] = "Password is required."
    }
    if (Object.keys(pendingErrors).length > 0) {
      setErrors(pendingErrors)
    } else {
      setErrors({})
      const res = await fetch(
        process.env.REACT_APP_API_URL + '/api/auth/login',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(login)
        }
      )
      const data = await res.json()
      if (data['status'] === 500) {
        auth.logOut()
        pendingErrors['formError'] = 'Unknown error occurred. Please refresh and try again, or contact an administrator.'
        setErrors(pendingErrors)
      } else if (data['error']) {
        pendingErrors['formError'] = data['error']
        auth.logOut()
        setErrors(pendingErrors)
      } else if (data['token']) {
        auth.setToken(data['token'])
        setTimeout(() => {
          navigate(fromPath, { replace: true })
        }, 1000)
      }
    }
  }

  const updateLogin = (e) => {
    const newLogin = {...login}
    newLogin[e.target.name] = e.target.value
    setLogin(newLogin)
  }

  return (
    <div className="loginBlock">
      <header>
        <img src={ClimateHoundSVGSrc} />
      </header>
      <div className="fields">
        <h5>{forgotPassword ? "Enter your user email to receive a password reset link" : "Welcome back!"}</h5>
        <form onSubmit={signIn}>
          <LabeledField
            name="email"
            type="email"
            label="Email"
            values={login}
            formErrors={errors}
            onChange={updateLogin}
          />
          {!forgotPassword && <LabeledField
            name="password"
            type="password"
            label="Password"
            values={login}
            formErrors={errors}
            onChange={updateLogin}
          />}
          <div className="micro" onClick={toggleReset}>{forgotPassword ? "Back to Login" : "Forgot Password?"}</div>
          {
            errors && errors['formError'] && (
              <p className="errorMessage">{errors['formError']}</p>
            )
          }
          <input
            type="submit"
            className="positive"
            onClick={forgotPassword ? sendResetEmail : signIn}
            value={forgotPassword ? "Send reset email" : "Sign in"}
          />
        </form>
      </div>
    </div>
  )
}
