import React, { useState, useEffect } from 'react'
import { Route, Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import { Button } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AdminFrame from '../AdminFrame'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'

import QuestionFormatter from '../../utils/QuestionFormatter'

import axios from 'axios'

export default function QuestionSources() {
  const auth = useAuth()
  const navigate = useNavigate()
  const [ questions, setQuestions ] = useState([])

  useEffect(() => {
    axios.get('/api/question_sources').then((res) => {
      setQuestions(res.data)
    })
  }, [])

  const [openEdit, setOpenEdit] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)


  return(
      <div className="adminPage">

        <header className="pageHeader">
          <h1>Master Questions</h1>
        </header>

        <div className="admin-fab">
          <Fab color="primary" aria-label="add"  onClick={() => (setOpenCreate(true))}>
            <AddIcon />
          </Fab>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>name ID</TableCell>
                <TableCell>Section</TableCell>
                <TableCell>Question</TableCell>
                <TableCell>Info</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((question_source) => {
                console.log(question_source)
                const formatter = QuestionFormatter(question_source)
                return (
                  <TableRow
                    key={question_source.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{question_source.name_id}</TableCell>
                    <TableCell component="th" scope="question_source">
                      {question_source.questionnaire_section_name}
                    </TableCell>
                    <TableCell>{formatter.bodyText()}</TableCell>
                    <TableCell>{question_source.info}</TableCell>
                    <TableCell>{formatter.typeText()}</TableCell>
                    <TableCell>















                      <Button variant="outlined" onClick={() => (setOpenEdit(true))}>
                        Edit
                      </Button>

                      <Dialog open={openCreate} onClose={() => (setOpenCreate(false))} fullWidth={true} maxWidth="md">
                        <DialogTitle>Create New Question</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Add a new question to the master list.
                          </DialogContentText>
                          <Box component="form"
                            sx={{
                              '& .MuiTextField-root': { mt: 3 },
                              '& .MuiFormControl-root': { mt: 3 }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <FormControl fullWidth>
                              <InputLabel>Question Format</InputLabel>
                              <Select
                                label="Question Format"
                              >
                                <MenuItem value={1}>Num - $</MenuItem>
                                <MenuItem value={2}>Num - #</MenuItem>
                                <MenuItem value={3}>Num - sq ft</MenuItem>
                                <MenuItem value={4}>Num - KWh</MenuItem>
                                <MenuItem value={5}>Num - lbs</MenuItem>
                                <MenuItem value={6}>Num - cubic yards</MenuItem>
                                <MenuItem value={7}>Num - Gallons / CCF</MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel>Section</InputLabel>
                              <Select
                                label="Section"
                              >
                                <MenuItem value={1}>Business Info</MenuItem>
                                <MenuItem value={2}>Distribution and Travel</MenuItem>
                                <MenuItem value={3}>Energy Usage</MenuItem>
                                <MenuItem value={4}>Waste Management</MenuItem>
                                <MenuItem value={5}>Procurement</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              id="name"
                              label="Question / Header"
                              fullWidth
                            />
                            <TextField
                              id="name"
                              label="Subtext / Body"
                              fullWidth
                              multiline
                              rows={2}
                            />
                            <TextField
                              id="name"
                              label="Info Text"
                              fullWidth
                              multiline
                              rows={2}
                            />
                            <FormGroup>
                              <FormControlLabel control={<Checkbox defaultChecked />} label="Kitchen" />
                              <FormControlLabel control={<Checkbox />} label="Other Tag" />
                            </FormGroup>
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => (setOpenCreate(false))}>Cancel</Button>
                          <Button variant="contained" onClick={() => (setOpenCreate(false))}>Create</Button>
                        </DialogActions>
                      </Dialog>

                      <Dialog open={openEdit} onClose={() => (setOpenEdit(false))} fullWidth={true} maxWidth="md">
                        <DialogTitle>Edit Question</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            &nbsp;
                          </DialogContentText>
                          <TextField
                            id="name"
                            label="question"
                            fullWidth
                            multiline
                            rows={4}
                            value={question_source.question}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => (setOpenEdit(false))}>Cancel</Button>
                          <Button variant="contained" onClick={() => (setOpenEdit(false))}>Save</Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
              )})}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  )
}
