import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { isEqual } from 'lodash'

import { useAuth } from '../../providers/AuthProvider'

import copy from '../../utils/copy'

import { useToast } from '../../providers/ToastProvider'
import Profile from '../Profile/Profile'
import IconButton from '../Forms/IconButton'
import { ReactComponent as Play } from '../../assets/Play.svg'
import { ReactComponent as Stylus } from '../../assets/Stylus.svg'
import { ReactComponent as Embed } from '../../assets/Embed.svg'

import LabeledTextArea from '../Forms/LabeledTextArea'
import FileUploader from '../Forms/FileUploader'

import Modal from '../Containers/Modal'

import { defaultSusPage } from '../../data/defaults'
import returnDeviceType from '../../lib/returnDeviceType'

export default function EditSustainability(props) {

  const [ submitModalOpen, setSubmitModalOpen ] = useState(false)
  const [ embedModalOpen, setEmbedModalOpen ] = useState(false)
  const [ submitLoading, setSubmitLoading ] = useState(false)

  const [ publishedPageId, setPublishedPageId ] = useState(null)

  const [ changesMade, setChangesMade ] = useState(false)
  const [ isDraft, setIsDraft ] = useState(false)
  const [ isModifyPage, setIsModifyPage ] = useState(true)

  const [ susPage, setSusPage ] = useState(defaultSusPage)
  const [ latestSavedPage, setLatestSavedPage ] = useState(defaultSusPage)

  const auth = useAuth()

  useEffect(() => {
    console.log(auth.userInfo)
    setPublishedPageId(auth.userInfo.published_sus_page_id)
  })

  useEffect(() => {
    axios.get('/api/sus_pages/edit').then((res) => {
      const apiPage = {
        id: res.data.id,
        susStory: res.data.sus_story,
        reductionStrategies: res.data.reduction_strategies,
        logoUrl: res.data.logo_url,
        wordmarkUrl: res.data.wordmark_url,
        sliderPics: res.data.slider_pics || defaultSusPage.sliderPics,
        companyName: res.data.company,
        goneNeutral: res.data.gone_neutral,
        neutralSinceYear: res.data.neutral_since_year,
        industry: res.data.industry
      }
      setLatestSavedPage(apiPage)
      setSusPage(apiPage)
    })
  }, [])

  const updateSusPage = (e) => {
    const newSusPage = {...susPage}
    newSusPage[e.target.name] = e.target.value
    setSusPage(newSusPage)
  }

  function checkForChanges() {
    if (!isEqual(susPage, latestSavedPage) && !changesMade) {
      setChangesMade(true)
    } else if (isEqual(susPage, latestSavedPage) && changesMade) {
      setChangesMade(false)
    }
  }

  useEffect(checkForChanges, [susPage, latestSavedPage])

  const toast = useToast()

  function alertUser(e) {
    e.preventDefault()
    e.returnValue = ""
    return ""
  }

  const addImage = (imageFieldName, imageUrl) => {
    const newSusPage = {...susPage}
    if (typeof newSusPage[imageFieldName] === 'string') {
      newSusPage[imageFieldName] = imageUrl
      setSusPage(newSusPage)
    } else {
      newSusPage[imageFieldName] = [...newSusPage[imageFieldName], imageUrl]
      setSusPage(newSusPage)
    }
  }

  const removeImage = (imageFieldName, idx) => {
    if (!window.confirm('Remove image?')) {
      return
    }
    const newSusPage = {...susPage}
    if (typeof newSusPage[imageFieldName] === 'string') {
      newSusPage[imageFieldName] = ''
      setSusPage(newSusPage)
    } else {
      const collection = [...newSusPage[imageFieldName]]
      collection.splice(idx, 1)
      newSusPage[imageFieldName] = collection
      setSusPage(newSusPage)
    }
  }

  const openSubmitModal = () => {
    setSubmitModalOpen(true)
  }
  const openEmbedModal = () => {
    setEmbedModalOpen(true)
  }

  const formPayload = (susPage) => {
    return {
      logo_url: susPage.logoUrl,
      wordmark_url: susPage.wordmarkUrl,
      sus_story: susPage.susStory,
      slider_pics: susPage.sliderPics,
      reduction_strategies: susPage.reductionStrategies,
    }
  }

  const submit = () => {
    setSubmitLoading(true)
    const susPageId = susPage.id || 'new'
    axios.put(`/api/sus_pages/${susPageId}`, {sus_page: formPayload(susPage)}).then((res) => {
      setSubmitLoading(false)
      setSubmitModalOpen(false)
      toast.setToast('Submission received!', 3000, 'success')
      const apiPage = {
        id: res.data.id,
        susStory: res.data.sus_story,
        reductionStrategies: res.data.reduction_strategies,
        logoUrl: res.data.logo_url,
        wordmarkUrl: res.data.wordmark_url,
        sliderPics: res.data.slider_pics,
        companyName: res.data.company,
        goneNeutral: res.data.gone_neutral,
        neutralSinceYear: res.data.neutral_since_year,
        industry: res.data.industry
      }
      setLatestSavedPage(apiPage)
      setSusPage(apiPage)
    })
  }

  const previewRef = useRef(null)

  const [ previewZoomPercent, setPreviewZoomPercent ] = useState(50)

  function handleResize() {
    const previewPercent = (returnDeviceType() === 'desktop') ? (previewRef.current.clientWidth / 14.0) : 0 // based on fixed inner preview width of 1k px
    setPreviewZoomPercent(previewPercent)
  }

  useEffect(() => { 
    window.addEventListener('resize', handleResize)
    handleResize()
    return (() => {
      window.removeEventListener('resize', handleResize)
    })
  }, [])

  const copyEmbedCode = () => {
    copy(iframeString())
    setEmbedModalOpen(false)
    toast.setToast('iFrame copied!', 3000, 'success')
  }

  const copyPublicLink = () => {
    copy(`${process.env.REACT_APP_ROOT_URL}/profile/${btoa(latestSavedPage.id)}`)
    toast.setToast('Link copied!', 3000, 'success')
  }

  const iframeString = () => {
    return `<iframe src="${process.env.REACT_APP_ROOT_URL}/profile/${btoa(latestSavedPage.id)}" width="100%" height="100%" />`
  }

  const toggleModifyPreviewPage = () => {
    setIsModifyPage(!isModifyPage)
    setTimeout(() => {
      handleResize();
    }, 0)
    
  }

  return(
    <div>
      <Modal
        title="Submit for review?"
        subtitle="Submit this page for review and we'll green light it ASAP. Your embed code and link will be available as soon as your page is approved. Look out for an email!"
        open={submitModalOpen}
        loading={submitLoading}
        close={() => (setSubmitModalOpen(false))}

        actionsDom={
          <div>
            <input type="button" value="Submit for Review" className="positive small" onClick={submit} />
            <input type="button" value="Continue Editing" className="small" onClick={() => (setSubmitModalOpen(false))} />
          </div>
        }
      />

      <Modal
        title="Embed this page"
        subtitle="Copy the following embed code to show off your page on your site."
        open={embedModalOpen}
        close={() => (setEmbedModalOpen(false))}
        bodyDom={
          <pre>
            {iframeString()}
          </pre>
        }
        actionsDom={
          <div>
            <input type="button" value="Copy" className="positive small" onClick={copyEmbedCode} />
            <input type="button" value="Close" className="small" onClick={() => (setEmbedModalOpen(false))} />
          </div>
        }
      />


      <div className="sustainability page">
        <header className="main">
          <h2>Sustainability Page</h2>
          <div className="actions">
            <div className="buttonSection1">
                <IconButton value="Embed iFrame" className="iconButton outlined" position="left" icon={<Embed/> } disabled={!publishedPageId} onClick={openEmbedModal}></IconButton>
                <input type="button" value="Copy URL" disabled={!publishedPageId} onClick={copyPublicLink} />
            </div>
            <div className="buttonSection2">
                <input type="button" className="positive" value="Submit for approval" onClick={openSubmitModal} disabled={!changesMade} />
                <IconButton value={isModifyPage ? 'Preview Page': 'Modify Page'} className="editPreviewToggleButton iconButton outlined" position="left" icon={isModifyPage ? <Play/> : <Stylus/> } onClick={toggleModifyPreviewPage}></IconButton>
            </div>
          </div>
        </header>
        <header className="subs">
          <h4 className={isModifyPage ? "hidden": null}>Page Preview</h4>
          <h4 className={isModifyPage ? null: "hidden"}>Modify Page</h4>
        </header>
        <div className="editAndPreview">
          <div className={"preview "+ (isModifyPage ? 'hidden': null ) } ref={previewRef}>
            <div className="zoomBox" style={{zoom: previewZoomPercent + '%'}}>
              <Profile
                draft={susPage}
                reportId={'current'}
              />
            </div>
          </div>
          <div className={"edit "+ (isModifyPage ? null: 'hidden' ) }>
            <section>
              <header>
                <h5>
                  Photos
                </h5>
                <label className="body3">
                  
                </label>
              </header>
              <label className="body4">Photos of your team, facility, process, or products that you’d like used on your ClimateHound page.</label>
              <FileUploader
                fileUrls={susPage.sliderPics}
                name="sliderPics"
                uploadSuccess={addImage}
                removeImage={removeImage}
              />
            </section>


            <section>
              <header>
                <h5>
                  Logo
                </h5>
                <label className="body3">
                  Required
                </label>
              </header>
              <label className="body4">Your standalone logo or graphic that represents your brand.</label>
              <FileUploader
                fileUrls={susPage.logoUrl.length > 0 ? [susPage.logoUrl] : []}
                name="logoUrl"
                uploadSuccess={addImage}
                removeImage={removeImage}
              />
            </section>

            <section>
              <header>
                <h5>
                  Sustainability Story
                </h5>
                <label className="body3">
                  Required
                </label>
              </header>
              <label className="body4">Describe your story to sustainability</label>
              <LabeledTextArea
                errors={{}}
                name="susStory"
                values={susPage}
                onChange={updateSusPage}
                placeholder="Sustainability story..."
              />
            </section>


            <section>
              <header>
                <h5>
                  Wordmark
                </h5>
                <label className="body3">
                  Required
                </label>
              </header>
              <label className="body4">Your company wordmark to be used on your ClimateHound page.</label>
              <FileUploader
                fileUrls={susPage.wordmarkUrl.length > 0 ? [susPage.wordmarkUrl] : []}
                name="wordmarkUrl"
                uploadSuccess={addImage}
                removeImage={removeImage}
              />
            </section>

          </div>
        </div>
      </div>
    </div>
  )
}
