import React, { useState, useEffect } from 'react'
import { useLocation } from '../../providers/LocationProvider'

import LabeledField from '../Forms/LabeledField'
import NavButtons from './NavButtons'
import FileUploadComponent from '../Forms/DropUpload'
import axios from 'axios'

export default function LocationSingleInputQuestion(props) {

  const { locations, setLocations } = useLocation()
  const defaultAnswer = props.question.answer.location_data.reduce(
    (acc, cur) => {
      acc[cur.location_id] = cur.amount
      return acc
    }, {}
  )
  const [ answer, setAnswer ] = useState(defaultAnswer)
  const [ errors, setErrors ] = useState({})

  useEffect(() => {
    if(!locations.length) {
      axios.get('/api/locations/').then(
        res => {
          setLocations(res.data)
        }
      ).catch(
        err => console.log(err)
      )
    }
  }, [])

  const updateAnswer = (e) => {
    setErrors({})
    const newAnswer = { ...answer }
    newAnswer[e.target.name] = e.target.value
    setAnswer(newAnswer)
  }

  const submit = () => {
    const newErrors = {}
    if (props.question.answer_format === 'numeric') {
      if (!/^\$?\d*$/.test(answer.text)) {
        newErrors['text'] = "Please enter a whole number."
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      return axios.patch('/api/questions/' + props.question.id, {
        answer: {
          location_data: Object.keys(answer).map( location_id => ({location_id, amount: answer[location_id]}) )
        }
      })
    }
  }

  const nextEnabled = () => {
    return Object.keys(answer).length > 0
  }

  return (
    <div>
      <div className="questionInputs">
        <div className={`oneField ${(props.question.units_name === '$' ? "dollarAmt" : "nonDollarAmt")} addressField`}>
          {locations.map( loc => {
              return <div className="addressRow singleInputLocation" key={loc.street_address + loc.zip_code}>
                <div>
                  <b>{loc.name || loc.street_address}</b>
                  <div className="micro">{parseInt(loc.sq_ft)} sq ft | {parseInt(loc.employee_count)} employees</div>
                </div>
                <div>
                <LabeledField
                  name={loc.id}
                  formErrors={errors}
                  values={answer}
                  placeholder="Total"
                  onChange={updateAnswer}
                />
                <span className={("singleInputLocation ") + (props.question.units_name === '$' ? "dollarAmt" : "nonDollarAmt")}>{props.question.units_name}</span>
              </div>
                </div>
            })}
        </div>
        <FileUploadComponent question={props.question.id} />
        <NavButtons
          noTopMargin
          submit={submit}
          question={props.question}
          goPrev={props.goPrev}
          goNext={props.goNext}
          nextEnabled={nextEnabled}
        />
      </div>
    </div>
  )
}
