import React from 'react'

export default function RadioOptionGroup(props) {

  const errors = props.formErrors || {}

  const optionsDom = props.options.map((option) => {
    return (
      <label key={option.value} className="radioLabel">
        <input onChange={props.onChange} type="radio" name={props.name} value={option.value} checked={props.values[props.name] === option.value} />
        { option.label && <span>{option.label}</span> }
      </label>
    )
  })

  return (
    <div className={`radioOptionGroup ${props.inline ? 'inline' : ''}`}>
      <h3>{props.label}</h3>
      {errors[props.name] && (<div className="errorMessage">{errors[props.name]}</div>)}
      { optionsDom }
    </div>
  )
}
