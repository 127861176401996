import { ReactComponent as ShopIcon } from '../assets/Shop.svg'
import { ReactComponent as PlaneIcon } from '../assets/Plane.svg'
import { ReactComponent as TrashIcon } from '../assets/Trash.svg'
import { ReactComponent as TruckIcon } from '../assets/Truck.svg'
import { ReactComponent as PowerIcon } from '../assets/Power.svg'
import { ReactComponent as KitchenIcon } from '../assets/Utensils.svg'

const catMap = {
  'business-info': {
    icon: ShopIcon,
    name: 'Business Info'
  },
  'distribution-and-travel': {
    icon: PlaneIcon,
    name: 'Distribution and Travel'
  },
  'waste-management': {
    icon: TrashIcon,
    name: 'Waste Management'
  },
  'energy-usage': {
    icon: PowerIcon,
    name: 'Utilities'
  },
  'procurement': {
    icon: TruckIcon,
    name: 'Purchased Goods'
  },
  'kitchen': {
    icon: KitchenIcon,
    name: 'Kitchen Supplies'
  }
}

export default catMap
