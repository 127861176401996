import React, { useMemo } from "react"
import Donut from "../Charts/Donut"

export default function DataReviewHeader (props) {

  function donutSuppliers(supplierArray) {
    return [
      {
        name: "Categorized Suppliers",
        value: supplierArray.reduce((acc, cur) => ((props.admin ? cur.classification_step === "categorized" : (cur.client_notes || cur.classification_step === 'categorized')) ? (acc + 1) : acc), 0)
      },
      {
        name: "Non-categorized suppliers",
        value: supplierArray.reduce((acc, cur) => ((props.admin ? cur.classification_step !== "categorized" : !cur.client_notes && cur.classification_step === "client_review") ? (acc + 1) : acc), 0)
      }
    ]
}

  console.log(props.contacts)
  const sortedContacts = donutSuppliers(props.contacts.slice())

  const generateData = () => {
    return {
      labels: sortedContacts.map( supplierGroup => supplierGroup.name),
      datasets: [{
        data: sortedContacts.map( supplierGroup => supplierGroup.value),
        borderWidth: 0,
        backgroundColor: sortedContacts.map( contact => contact.name === "Categorized Suppliers" ? "#329E33" : "gray"),
        hoverOffset: 4
      }]
    }
  }

  function calculateCategorizedPercentage() {
    const totalVendors = props.contacts.length;
    const categorizedVendors = sortedContacts[0].value;
    
    // Calculate percentage
    let percentage = Math.round((totalVendors ? (categorizedVendors / totalVendors) : 1) * 100);
    
    return {percentage, categorizedVendors, totalVendors};
}

  const remainingVendors = calculateCategorizedPercentage().totalVendors - calculateCategorizedPercentage().categorizedVendors

  const donutData = useMemo(() => generateData(), [props.contacts])  

  return (
      <div className={'dataReviewHeader'}>
        <header>
          <div>
            <h2>Data Review</h2>
            <div>
              <div className="body4"> 
                Share some detail about these purchases, expenses, or otherwise. 
                We need to understand what the primary product or service was associated with these transactions.
              </div>
            </div>
          </div>
        </header>
        <div className="donutContainer">
          <div className="donutHolder">
              <div className="donutMeat">
                {<Donut
                  data={donutData}
                  disableTooltip
                />}
                <div className="donutHole">
                  <h5 style={{margin: 0}}>{props.contacts.length && calculateCategorizedPercentage().percentage}%</h5>
                  <div className="micro">
                    {props.admin ? "Categorized" : "Completed"}
                  </div>
                </div>
              </div>
            </div>
            <div className="categorizationText">
            <div>
              <b>
              {props.admin ? 
              `${calculateCategorizedPercentage().categorizedVendors} of ${calculateCategorizedPercentage().totalVendors}` : `${(remainingVendors > 0) ? remainingVendors : 0} of ${props.contacts.length} remaining`
              }
              </b>
              <div className="micro">
                {props.admin ? "Vendors Categorized" : "Vendors Remaining"}
              </div>
            </div>
          </div> 
        </div>
      </div>
  )
}

