import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CommonLinks from '../CommonLinks'

import { useNavigate, useParams, useLocation } from 'react-router-dom'

import MiniProgress from './MiniProgress'
import ViewOnDesktop from '../ViewOnDesktop'
import catMap from '../../lib/catMap'
import completionClasses from '../../lib/completionClasses'

import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as CheckIcon } from '../../assets/Check.svg'

import axios from 'axios'

export default function SectionReview() {

  const { categorySlug } = useParams()
  const defaultQnnSummary = {}
  const [ qnnSummary, setQnnSummary ] = useState(defaultQnnSummary)
  const navigate = useNavigate()
  const [ nextQuestionId, setNextQuestionId ] = useState()
  const [ allDone, setAllDone ] = useState(false)
  const [ totalNeedHelp, setTotalNeedHelp ] = useState(0)

  useEffect(() => {
    axios.get('/api/questionnaires/current/summary').then((res) => {
      setQnnSummary(res.data)
      setNextQuestionId(res.data.progress[categorySlug].next_question_id)
      console.log(res.data)
    })
  }, [categorySlug])

  const navToQuestion = (category) => {
    if (qnnSummary.progress[category].progress === 0) {
      navigate(`/questionnaire/${qnnSummary.calculation_year}/${category}/start/${qnnSummary.progress[category].next_question_id}`)
      console.log('sdf')
    } else {
      navigate(`/questionnaire/${qnnSummary.calculation_year}/${qnnSummary.progress[category].next_question_id}`)
    }
  }

  const catsInOrder = Object.keys(catMap)
  const catName = catMap[categorySlug].name
  const catProgress = qnnSummary.progress ? qnnSummary.progress[categorySlug] : {}

  const [ sectionQuestions, setSectionQuestions ] = useState([])

  useEffect(() => {
    if (nextQuestionId) {
      axios.get(`/api/questions/${nextQuestionId}/with_context`).then((res) => {
        setSectionQuestions(res.data.section_questions)
      })
    }
  }, [nextQuestionId])

  const location = useLocation()
  const fromPath = location.state?.from?.pathname || "/"

  return (
    <div>
      <ViewOnDesktop />
      <MiniProgress
        qnnSummary={qnnSummary.progress}
      />

      <div className="sectionWelcome">
        <header>
          <h2>{catName}</h2>
          <h5 className="success">{catProgress.progress ? Math.floor(catProgress.progress) : '...'}% Complete</h5>
        </header>

        <div className="progressBlock">
          <header>
            <h2>Section Review</h2>
            <div className="body2">
              Please review any questions you skipped or would like to adjust. You can also return later to finish any unanswered questions.
            </div>
          </header>

          <div className="questionStatuses">
            <ul>
              {
                sectionQuestions.map((q) => {
                  const completionClass = completionClasses[q.status]
                  return (
                    <li key={q.id} className={`${completionClass}`}>
                      <div className="checkIcon">
                        <CheckIcon />
                      </div>
                      <Link to={`/questionnaire/${qnnSummary.calculation_year}/${q.id}`}>{ q.name }</Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="actions">
            <input type="button" value="Back" onClick={() => (navigate(fromPath))} />
            <input type="button" className="positive" value="Main Menu" onClick={() => (navigate('/'))} />
          </div>
        </div>
      </div>
    </div>
  )
}
