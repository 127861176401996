const completionClasses = {
  0: 'unanswered',
  1: 'answered',
  2: 'notApplicable',
  3: 'wasSkipped',
  4: 'helpRequested',
  5: 'pending'
}

export default completionClasses
