import React, { createContext, useContext, useState, useEffect } from 'react'

const LocationContext = createContext(null)

export default function LocationProvider({ children }) {

  const [locations, setLocations] = useState([])

  const value = { setLocations, locations }

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  )
}

export function useLocation() {
  return useContext(LocationContext)
}
