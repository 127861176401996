export default function centsToReadable(value) {
  if (parseInt(value) !== value) {
    console.error('cents should be a whole integer, got:', value)
    return
  }
  let fractional = `${ value % 100 }`
  fractional =      fractional.length === 1 ? "0" + fractional : fractional
  fractional =      fractional.length > 2   ? fractional[0] + fractional[1] : fractional
  let dollars =    `${ Math.floor(value / 100) }`

  return "$" + dollars + "." + fractional
}
