import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import axios from 'axios'

import QuestionFrame from './QuestionFrame'

export default function Question(props) {
  const { questionId } = useParams()
  const [ loading, setLoading ] = useState(true)

  const defaultQuestion = {
    question_type: 'other',
    question_category: '',
    units: '',
    answer_format: '',
    prompt_text: {
      text: "",
      body: ""
    }
  }
  const [ question, setQuestion ] = useState(defaultQuestion)
  const [ nextQuestion, setNextQuestion ] = useState(defaultQuestion)
  const [ prevQuestion, setPrevQuestion ] = useState(defaultQuestion)
  const [ sectionQuestions, setSectionQuestions ] = useState([])
  const [ qnnSummary, setQnnSummary ] = useState({})

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/questions/${questionId}/with_context`).then((res) => {
      setQuestion(res.data.question)
      setPrevQuestion(res.data.prev_question)
      setNextQuestion(res.data.next_question)
      setSectionQuestions(res.data.section_questions)
      setLoading(false)
    })
  }, [questionId])


  useEffect(() => {
    axios.get('/api/questionnaires/current/summary').then((res) => {
      setQnnSummary(res.data)
    })
  }, [questionId])

  return (
    <QuestionFrame
      loading={loading}
      question={question}
      categoryHeader={question.questionnaire_section_name}
      nextQuestion={nextQuestion}
      prevQuestion={prevQuestion}
      qnnSummary={qnnSummary}
      sectionQuestions={sectionQuestions}
    />
  )
}
