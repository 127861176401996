import DataReviewHeader from "./DataReviewHeader"

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useState, useEffect } from "react";
import ContactCard from "./ContactCard";
import { useAuth } from '../../providers/AuthProvider'
import ViewOnDesktop from "../ViewOnDesktop";
import SubmitButtons from "./SubmitButtons";
import Modal from "../Containers/Modal";
import Select from "react-select";
import LabeledField from "../Forms/LabeledField";
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'


import { Switch } from "@mui/material";

const ADMIN_ROLE = "admin"

export default function DataReviewPage (props) {
  const auth = useAuth()
  const navigate = useNavigate()
  const { id } = useParams()

  const summaryDefault = {
    name: '',
    progress: [],
    totalProgress: 0
  }


  const [contacts, setContacts] = useState([])
  const [tabValue, setTabValue] = useState(1)
  const [categories, setCategories] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [allExpanded, setAllExpanded] = useState(false)
  const [showUncategorized, setShowUncategorized] = useState(false)
  const [showFlagged, setShowFlagged] = useState(false)
  const [showExcluded, setShowExcluded] = useState(false)
  const [submissionModalOpen, setSubmissionModalOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [filters, setFilters] = useState({ nameFilter: "", selectedCategories: [] })

  const goBackToMeasureTab = () => navigate('/questionnaire/start')
  const goBackToQuestionnairePage = () => navigate('/admin/questionnaires/' + id)

  const [ summary, setSummary ] = useState(summaryDefault)

  useEffect(() => {
    axios.get('/api/questionnaires/' + id + '/summary').then((res) => {
      // console.log(res)
      setSummary(res.data)
    })
  }, [])
  

  const getSupplierData = () => {
    axios.get(`/api/questionnaires/${id}/suppliers`).then((res) => {
      const formattedData = res.data
      setContacts(formattedData)
      setIsLoading(false)
      console.log(formattedData, 'suppliers')
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      }
    )
  }

  const getSupplierCategories = () => {
    axios.get('/api/supplier_categories').then((res) => {
      const formattedData = res.data
      setCategories(formattedData.map( cat => ({label: cat.name, value: cat.id}) ))
      console.log(formattedData, 'categories')
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      }
    )
  }

  useEffect(() => {
    setIsLoading(true)
    getSupplierCategories()
    getSupplierData();
  }, [])
  
  const handleExpansionToggle = () => {
    setAllExpanded(!allExpanded)
  }

  const handleFlaggedToggle = () => {
    setShowFlagged(!showFlagged)
  }

  const handleExcludedToggle = () => {
    setShowExcluded(!showExcluded)
  }

  const handleUncategorizedToggle = () => {
    setShowUncategorized(!showUncategorized)
  }

  const openSubmissionModal = () => {
    setSubmissionModalOpen(true)
  }

  const onSubmissionModalClose = () => {
    setSubmissionModalOpen(false)
  }

  const handleCategorySelect = (e) => {
    setFilters({...filters, selectedCategories: e})
    return;
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const userIsAdmin = auth.userInfo.role === ADMIN_ROLE
  const dataSent = (summary.supplier_review_status === "completed")
  let submitText = ""
  let handleSubmit = () => {return null}

  if(userIsAdmin) {
    if(dataSent) {
      submitText = "Data sent"
    } else if(tabValue === 1) {
      submitText = "Send to client"
    } else if( tabValue === 3 ) {
      submitText = "Complete"
    } else if( tabValue === 4 ) {
      submitText = "Send data"
    }
  } else {
    submitText = "Submit for review"
    handleSubmit = () => openSubmissionModal()
  }


  const sendSuppliersToClient = () => {
    axios.post(`/api/questionnaires/${id}/send_sups_to_client`).then((res) => {
      getSupplierData()
      setTabValue(2)
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      }
    )
  }

  const sendClientNotesforInternalReview = () => {
    axios.post(`/api/questionnaires/${id}/send_sups_to_internal_review`).then((res) => {
      window.location = window.location
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      }
    )
  }

  const sendSuppliersToCategorized = () => {
    axios.post(`/api/questionnaires/${id}/send_sups_to_categorized`).then((res) => {
      getSupplierData()
      setTabValue(4)
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      }
    )
  }

  const concludeSupplierClassification = () => {
    axios.post(`/api/questionnaires/${id}/conclude_supplier_classification`).then((res) => {
      window.location = window.location
      getSupplierData()
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      }
    )
  }

  const showIfUncategorizedToggle = (contact) => {
      return !contact.is_excluded && !(contact.supplier_category_spends && contact.supplier_category_spends[0])
    
  }

  const showIfExcludedToggle = (contact) => {
      return (contact.is_excluded)
    }
  

  const showIfFlaggedToggle = (contact) => {
      return (contact.needs_client_review)
  }


  const handleFilterNameChange = (event) => {
    setFilters({...filters, nameFilter: event.target.value})
  }

  const checkFilters = (contact) => {
    if(filters.nameFilter && !contact.name.toLowerCase().includes(filters.nameFilter.toLowerCase())) {
      return false
    }
    if(filters.selectedCategories.length && !filters.selectedCategories.map(cat => cat.value).includes(contact.supplier_category_spends[0] && contact.supplier_category_spends[0].supplier_category_id)) {
      return false
    }
    else {
      return true
    }
  }

  const submissionModalBody = (
    <div className="body3">
      Select submit if you have completed your notes on the purchased goods and services with each vendor.
    </div>
  )

  let completionText = ""
  let contactsCompleted = 0
  let contactsShown = 0
  let submitButtonEnabled = false
  if(!userIsAdmin) {
      contactsCompleted = contacts.reduce( (acc, cur) => {
      if(cur.classification_step === "client_review") {
        contactsShown++;
      } 
      return acc + ((cur.client_notes && (cur.classification_step === "client_review")) ? 1 : 0)
    }, 0)
    
    completionText = `${contactsCompleted} of ${contactsShown} answered`
    if( contactsShown && (contactsCompleted === contactsShown)) {
      submitButtonEnabled = true
    }
    if( !contactsShown) {
      submitText = "Submitted"
      completionText = ''
    }
  } else if(tabValue === 1) {
    contactsCompleted = contacts.reduce( (acc, cur) => {
      const contactCompleted = cur.supplier_category_spends[0] && cur.supplier_category_spends[0].supplier_category_id && !(cur.is_excluded || cur.needs_client_review) || cur.needs_client_review || (cur.exclusion_reason && cur.is_excluded)
      contactsShown++
      return acc + ( contactCompleted ? 1 : 0)  
    }, 0)
    
    completionText = `${contactsCompleted} of ${contactsShown} completed`
    handleSubmit = sendSuppliersToClient
    if(contactsCompleted === contactsShown) {
      submitButtonEnabled = true
    }
  } else if(tabValue === 2) {
    contactsCompleted = contacts.reduce( (acc, cur) => {
      const contactCompleted = cur.client_notes && cur.classification_step === "client_review"
      if(cur.classification_step === "client_review") {contactsShown++}
      return acc + ( contactCompleted ? 1 : 0)  
    }, 0)
    
    completionText = `${contactsCompleted} of ${contactsShown} completed`
  } else if(tabValue === 3) {
    contactsCompleted = contacts.reduce( (acc, cur) => {
      const contactCompleted = cur.supplier_category_spends[0] && cur.supplier_category_spends[0].supplier_category_id && !cur.is_excluded || (cur.exclusion_reason && cur.is_excluded)
      if(cur.classification_step === "internal_review") {contactsShown++}
      return acc + ( (contactCompleted && cur.classification_step === "internal_review") ? 1 : 0)
    }, 0)

    completionText = `${contactsCompleted} of ${contactsShown} completed`
    if(contactsCompleted === contactsShown) {
      submitButtonEnabled = true
    }
    handleSubmit = sendSuppliersToCategorized
  } else if(tabValue === 4) {
    contactsCompleted = contacts.reduce( (acc, cur) => {
      const contactCompleted = cur.supplier_category_spends[0] && cur.supplier_category_spends[0].supplier_category_id && !cur.is_excluded || (cur.exclusion_reason && cur.is_excluded)
      if(cur.classification_step === "categorized") {contactsShown++}
      return acc + ( (contactCompleted && cur.classification_step === "categorized") ? 1 : 0)
    }, 0)

    completionText = `${contactsCompleted} of ${contactsShown} completed`
    handleSubmit = concludeSupplierClassification
    if(contactsCompleted === contactsShown) {
      submitButtonEnabled = true
    }
  }

  const resetEverything = () => {
    axios.delete(`/api/questionnaires/${id}/reset_supplier_classification`).then(() => (getSupplierData()))
  } 

  const shouldShowSupplier = (contact) => {
    if(!checkFilters(contact)) {
      return false
    } else if(showUncategorized && !showIfUncategorizedToggle(contact)) {
      return false
    } else if(showFlagged && !showIfFlaggedToggle(contact)) {
      return false
    } else if(showExcluded && !showIfExcludedToggle(contact)) {
      return false
    } else {
      return true
    }
  }  

  return (
    <div className="page dataReview">
      <ViewOnDesktop copy="data review platform" />
      <Modal
        title="Are you ready to submit?"
        size="small"
        open={submissionModalOpen}
        close={onSubmissionModalClose}
        bodyDom={submissionModalBody}
        actionsDom={
          <div>
            <input type="button" value="Submit" className='positive' onClick={sendClientNotesforInternalReview} />
            <input type="button" value="Go Back" onClick={onSubmissionModalClose} />
          </div>
        }
      />
      <DataReviewHeader contacts={contacts} admin={userIsAdmin}/>
      {userIsAdmin ? (<div>
        <div className="contactFilters">
          <div className="selectHolder">
            <label className='selectLabel'>Emissions factor</label>
            <div className="labeledSelect">
              <Select classNamePrefix='custom-select' onChange={handleCategorySelect} isMulti={true} placeholder="All" options={categories}/>
            </div>
          </div>
          <div className="nameSearchFilterContainer">
            <LabeledField
              className="nameSearchFilter"
              name="nameFilter"
              values={filters}
              formErrors={{}}
              placeholder="Search"
              onChange={handleFilterNameChange}
            />
          </div>
        </div>
        {true && <button onClick={resetEverything}>RESET EVERYTHING!!!</button>}
        <div className="tabsContainer">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              aria-label="lab API tabs example" 
              textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "green",
                  color: 'black'
                }
              }}
            >
              <Tab 
                className="tabLabel" 
                label={<span style={{ color: tabValue === 1 ? 'black' : 'gray'}}>Uncategorized ({contacts.filter( contact => contact.classification_step === "uncategorized").length})</span>} 
                value={1}
                disableRipple
              />
              <Tab 
                className="tabLabel" 
                label={<span style={{ color: tabValue === 2 ? 'black' : 'gray'}}>Client ({contacts.filter( contact => contact.classification_step === "client_review").length})</span>} 
                value={2} 
                disableRipple
              />
              <Tab 
                className="tabLabel" 
                label={<span style={{ color: tabValue === 3 ? 'black' : 'gray'}}>Review ({contacts.filter( contact => contact.classification_step === "internal_review").length})</span>} 
                value={3} 
                disableRipple
              />
              <Tab 
                className="tabLabel" 
                label={<span style={{ color: tabValue === 4 ? 'black' : 'gray'}}>Categorized ({contacts.filter( contact => contact.classification_step === "categorized").length})</span>} 
                value={4} 
                disableRipple
              />
            </Tabs>
            <div>
              <span><Switch onChange={handleExcludedToggle} value={showExcluded}/> Show excluded</span>
              <span><Switch onChange={handleFlaggedToggle} value={showFlagged}/> Show flagged</span>
              <span><Switch onChange={handleUncategorizedToggle} value={showUncategorized}/> Show uncategorized</span>
              <span><Switch onChange={handleExpansionToggle} value={allExpanded}/> Expand all vendors </span> 
            </div>
          </Box>
        </div>
        <TabPanel value={tabValue} index={1} className='dataReviewTab'>
          <div>
              {contacts.map( (contact, idx) => (contact.classification_step === "uncategorized") && shouldShowSupplier(contact) && (
                <ContactCard 
                  name={contact.name}
                  transactions={contact.transaction_count}
                  categories={categories}
                  flagged={contact.needs_client_review}
                  excluded={contact.is_excluded}
                  exclusionReason={contact.exclusion_reason}
                  status={contact.classification_step}
                  admin={userIsAdmin}
                  description={contact.line_items}
                  totalSpend={contact.total_spend}
                  id={contact.id}
                  getSupplierData={getSupplierData}
                  key={contact.id}
                  category={contact.supplier_category_spends && contact.supplier_category_spends[0]}
                  allExpanded={allExpanded}
                  setIsSaving={setIsSaving}
                  setContacts={setContacts}
                  contactIdx={idx}
                  contacts={contacts}
                  dataSent={dataSent}
                />
              ) )}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2} className='dataReviewTab'>
          <div>
            {contacts.map( (contact, idx) => contact.classification_step === "client_review" && shouldShowSupplier(contact) && (
                <ContactCard 
                  name={contact.name}
                  transactions={contact.transaction_count}
                  categories={categories}
                  flagged={contact.needs_client_review}
                  excluded={contact.is_excluded}
                  exclusionReason={contact.exclusion_reason}
                  clientNotes={contact.client_notes}
                  status={contact.classification_step}
                  admin={userIsAdmin}
                  description={contact.line_items}
                  totalSpend={contact.total_spend}
                  id={contact.id}
                  getSupplierData={getSupplierData}
                  key={contact.id}
                  category={contact.supplier_category_spends && contact.supplier_category_spends[0]}
                  allExpanded={allExpanded}
                  setIsSaving={setIsSaving}
                  setContacts={setContacts}
                  contactIdx={idx}
                  contacts={contacts}
                  dataSent={dataSent}
                />
              ) )}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3} className='dataReviewTab'>
          <div>
            {contacts.map( (contact, idx) => contact.classification_step === "internal_review" && shouldShowSupplier(contact) && (
                <ContactCard 
                  name={contact.name}
                  transactions={contact.transaction_count}
                  categories={categories}
                  flagged={contact.needs_client_review}
                  excluded={contact.is_excluded}
                  exclusionReason={contact.exclusion_reason}
                  status={contact.classification_step}
                  clientNotes={contact.client_notes}
                  admin={userIsAdmin}
                  description={contact.line_items}
                  totalSpend={contact.total_spend}
                  id={contact.id}
                  getSupplierData={getSupplierData}
                  key={contact.id}
                  category={contact.supplier_category_spends && contact.supplier_category_spends[0]}
                  allExpanded={allExpanded}
                  setIsSaving={setIsSaving}
                  setContacts={setContacts}
                  contactIdx={idx}
                  contacts={contacts}
                  dataSent={dataSent}
                />
              ) )}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={4} className='dataReviewTab'>
          <div>
            {contacts.map( (contact, idx) => contact.classification_step === "categorized" && shouldShowSupplier(contact) && (
                <ContactCard 
                  name={contact.name}
                  transactions={contact.transaction_count}
                  categories={categories}
                  flagged={contact.needs_client_review}
                  excluded={contact.is_excluded}
                  exclusionReason={contact.exclusion_reason}
                  status={contact.classification_step}
                  clientNotes={contact.client_notes}
                  admin={userIsAdmin}
                  description={contact.line_items}
                  totalSpend={contact.total_spend}
                  id={contact.id}
                  getSupplierData={getSupplierData}
                  key={contact.id}
                  category={contact.supplier_category_spends && contact.supplier_category_spends[0]}
                  allExpanded={allExpanded}
                  setIsSaving={setIsSaving}
                  setContacts={setContacts}
                  contactIdx={idx}
                  contacts={contacts}
                  dataSent={dataSent}
                />
              ) )}
          </div>
        </TabPanel>
      </div>) : (
        <div className="clientContacts">
          {contacts.map( (contact, idx) => ( ((contact.classification_step === "client_review") || (contact.client_notes) )&& 
                (<ContactCard 
                  name={contact.name}
                  transactions={contact.transaction_count}
                  categories={categories}
                  flagged={contact.needs_client_review}
                  excluded={contact.is_excluded}
                  exclusionReason={contact.exclusion_reason}
                  status={contact.classification_step}
                  clientNotes={contact.client_notes}
                  admin={userIsAdmin}
                  description={contact.line_items}
                  totalSpend={contact.total_spend}
                  id={contact.id}
                  getSupplierData={getSupplierData}
                  key={contact.id}
                  category={contact.supplier_category_spends && contact.supplier_category_spends[0]}
                  allExpanded={allExpanded}
                  setIsSaving={setIsSaving}
                  setContacts={setContacts}
                  contactIdx={idx}
                  contacts={contacts}
                  dataSent={dataSent}
                />)
              ) )}
        </div>
      )}
      <SubmitButtons 
        submitText={submitText} 
        onSubmit={handleSubmit} 
        completionText={completionText} 
        submitEnabled={submitButtonEnabled}
        goPrev={userIsAdmin ? goBackToQuestionnairePage : goBackToMeasureTab}
        isSaving={isSaving}
        dataSent={dataSent}
      />
    </div>
  )
}

