import classnames from "classnames"

import reductionStrategyStepsData from "../../data/reductionStrategySteps"
import ReductionStrategySteps from "./ReductionStrategySteps"

import NullStrategyValues from "./StrategyValues/NullStrategyValues"
import PVPanelValues from "./StrategyValues/PVPanelValues"
import EcoDrivingValues from "./StrategyValues/EcoDrivingValues"
import WorkFromHomeValues from "./StrategyValues/WorkFromHomeValues"

import LabeledSelect from "../Forms/LabeledSelect"
import { ReactComponent as DollarSignIcon } from "./DollarSign.svg"
import { ReactComponent as ElectricLeafIcon } from "./ElectricLeaf.svg"
import { ReactComponent as PiggyBankIcon } from "./PiggyBank.svg"
import { ReactComponent as CheckGreen } from "../../assets/CheckGreen.svg"
import { ReactComponent as CheckStar } from "../../assets/CheckStar.svg"
import processing from "../../assets/Processing.gif"

import Modal from '../Containers/Modal'

import React, { useState } from "react"

import { isEqual } from "lodash"
import { Switch } from "@mui/material"
import { initiativeHasStarted, initiativeIsCompleted, intiativeIsInProgress } from "./reductionStrategyUtils"

export default function ReductionStrategyEditor(props) {
  const { 
    rs, 
    isLoading, 
    toggleReductionInForecast, 
    toggleReductionStep,
    saveDraftInputs, 
    updateStatus, 
    setTabValue, 
    closeEditor, 
    updateActionPlan
  } = props
  const [draftInputs, setDraftInputs] = useState({ ...rs.inputs })
  const [editValues, setEditValues] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [completeModalOpen, setCompleteModalOpen] = useState(false)
  const [closeModalOpen, setCloseModalOpen] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear())

  const steps = reductionStrategyStepsData[rs.slug]
  const completedStepCount = rs.completed_steps_mask.filter(
    (x) => x
  ).length

  const valuesComponentClassMap = {
    "null-strategy": NullStrategyValues,
    "pv-panels": PVPanelValues,
    "eco-driving": EcoDrivingValues,
    "work-from-home": WorkFromHomeValues,
  }
  const ValuesComponent = valuesComponentClassMap[rs.slug]

  const updateDraft = (name, value) => {
    const newDraft = { ...draftInputs }
    newDraft[name] = value
    setDraftInputs(newDraft)
  }

  const removeNullFromObject = (obj) => Object.fromEntries(
        Object.entries(obj)
            .filter(([key, value]) => value !== null && value !== '')
    );

  const draftIsChanged = !isEqual(rs.inputs, draftInputs)

  const handleSave = () => {
    saveDraftInputs(rs.id, removeNullFromObject({...draftInputs}))
    if(rs.status === "NOT_STARTED") {
      toggleReductionInForecast(rs.id)
    }
    setEditValues(false)
  }

  const statusClassMap = {
    NOT_STARTED: "notStarted",
    STARTED: "started",
    IN_PROGRESS: "inProgress",
    STEPS_COMPLETE: "stepsComplete",
    COMPLETED: 'completed',
  }

  const statusCopy = {
    ACTION_PLAN_REQUIRED: "Add the initiative to your action plan in order to update the initiative status and begin checking off steps below.",
    STARTED: "Set the initiative status to In-Progress and begin checking off steps below.",
    IN_PROGRESS: "Now that the initiative is in progress, mark the checkboxes below as you implement each action step."
  }

  const areStepsComplete = parseInt(completedStepCount / steps.length) === 1

  const className = classnames(
    "reductionStrategyEditor",
    statusClassMap[rs.status],
    { loading: isLoading }
  )

  const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? data.color
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
      }
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

function consecutiveYearsArray(size) {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];
    for (let i = 0; i < size; i++) {
        yearsArray.push(currentYear + i);
    }
    return yearsArray;
}

  return (
    <div className="rsEditorHolder">
      <Modal
        title="Update Status"
        open={updateModalOpen}
        close={() => setUpdateModalOpen(false)}
        bodyDom={
          <div className="body3">
            Once you set an initiative as <b>in progress</b>, you cannot change the status unless approved by the ClimateHound team. Initiatives estimated to be completed this year will show up on your sustainability page.
          </div>
        }
        actionsDom={
          <div>
            <input
              type="button"
              value="Mark as In Progress"
              className="positive small"
              onClick={() => {updateStatus(rs.id, "IN_PROGRESS"); setUpdateModalOpen(false)}}
            />
            <input
              type="button"
              value="Go back"
              className="small"
              onClick={() => setUpdateModalOpen(false)}
            />
          </div>
        }
      />
      <Modal
        title="Complete Initiative"
        open={completeModalOpen}
        className="completionModal"
        close={() => setCompleteModalOpen(false)}
        bodyDom={
          <div className="body3">
          Ready to submit your final values? The ClimateHound team will verify the data and the impact will be 
          reflected in your emissions projections and on your sustainability page.
          </div>
        }
        actionsDom={
          <div>
            <input
              type="button"
              value="Mark as Complete"
              className="positive small"
              onClick={() => {
                handleSave()
                updateStatus(rs.id, "COMPLETED")
                setTabValue(3)
                closeEditor()
              }}
            />
            <input
              type="button"
              value="Go back"
              className="small"
              onClick={() => setCompleteModalOpen(false)}
            />
          </div>
        }
      />
      <Modal
          title="Unsaved changes"
          open={closeModalOpen}
          close={() => setCloseModalOpen(false)}
          bodyDom={
            <div className="body3">
              Do you want to continue without saving changes within this initiative?          
            </div>
          }
          actionsDom={
            <div>
              <input
                type="button"
                value="Save changes"
                className="positive small"
                onClick={() => {
                  handleSave()
                  setCloseModalOpen(false)
                  closeEditor()}}
              />
              <input
                type="button"
                value="Exit without saving"
                className="small"
                onClick={closeEditor}
              />
              <input
                type="button"
                value="Go Back"
                className="small"
                onClick={() => setCloseModalOpen(false)}
              />
            </div>
          }
        />
      <div className="grayOverlayHitzone" 
        onClick={() => (
          (!initiativeHasStarted(rs.status) 
            && !Object.keys(rs.inputs).filter(init => !draftInputs[init]).length) 
            || (initiativeHasStarted(rs.status) && draftIsChanged)) 
            ? setCloseModalOpen(true) : closeEditor()
        }
      >
      </div>
      <div className={className}>
        <div className="editorBody">
          <header>
            <h4><div className={"progressIndicator" + (!initiativeHasStarted(rs.status) ? " " : " started")}/>{rs.name}</h4>
          </header>
          <div className={"rsStats editor" + ((initiativeIsCompleted(rs.status) || intiativeIsInProgress(rs.status)) ? " started" : "")}>
            <header>
              <div className="body4">Lifetime Impact</div>
            </header>
            <div className="stat reductionPotential">
              <label className="micro">
                <ElectricLeafIcon />
              </label>
              <div className="body4">
                <span>Reduction potential</span>
                <span>{ rs.kgco2e_reduced && `${Math.round(rs.kgco2e_reduced * 0.001)} mtCO2e`} </span>
              </div>
            </div>
            <div className="stat">
              <label className="micro">
                <PiggyBankIcon />
              </label>
              <div className="body4">
                <span>Dollars saved</span>
                <span>
                  { rs.dollars_saved && `$${rs.dollars_saved.toFixed(2)}` }
                </span>
              </div>
            </div>
            <div className="stat">
              <label className="micro">
                <DollarSignIcon />
              </label>
              <div className="body4">
                <span>Upfront cost</span>
                <span>
                  { rs.upfront_cost && `$${rs.upfront_cost.toFixed(2)}`}
                </span>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="loaderGif">
              <img src={processing} />
            </div>
          )}
          <div className="rsValuesHolder">
            {editValues || initiativeIsCompleted(rs.status) || (!rs.is_in_action_plan && rs.status !== "STARTED")? <ValuesComponent
              rs={rs}
              inputs={draftInputs}
              updateDraft={updateDraft}
            /> : (
              <div className="valuesEdit">
                <CheckGreen /> 
                <div>
                  <h5>Initiative Values</h5> 
                  <span className="body4" onClick={() => setEditValues(true)}>EDIT</span>
                </div>
              </div>
            )}
          </div>
          {!initiativeIsCompleted(rs.status) && <div className="completionYearSelectContainer">
            <div className="completionYearSelectComponent">
              <LabeledSelect 
                label="Estimated completion year"
                placeholder="Select Year"
                options={consecutiveYearsArray(10).map( yr => ({value: yr, label: yr}))}
                    formErrors={{}}
                    value={year}
                    onChange={(e) => {
                      setYear(e.target.value)
                  }}
                />
            </div>
          </div>}
          
          {!Object.keys(rs.inputs).filter(init => !rs.inputs[init]).length && (
            <div className="rsStatus">
              <div className={"micro"}>
                {!rs.is_in_action_plan ? statusCopy["ACTION_PLAN_REQUIRED"] : statusCopy[rs.status]}
              </div>

              <div className="selectHolder">
                <LabeledSelect 
                  label="Initiative Status"
                  options={[
                      {
                        value: "STARTED",
                        label: "Not Started",
                        disabled: rs.status === "IN_PROGRESS",
                        color: rs.is_in_action_plan ? 'gray' : '#EBEBF0'
                      },
                      {
                        value: "IN_PROGRESS",
                        label: "In Progress",
                        color: 'green'
                      },
                    ]}
                    formErrors={{}}
                    value={rs.status}
                    onChange={(e) => {
                      if(e.target.value !== rs.status) {
                        setUpdateModalOpen(true)
                      }
                  }}
                    styles={colourStyles}
                    isOptionDisabled={(opt) => opt.disabled}
                    isDisabled={!rs.is_in_action_plan}
                />
              </div>
                
            </div>
          )}

          {initiativeHasStarted(rs.status) && <div className="rsSteps">
            <header>
              <h5>Take Action</h5>
              <span className={"body4" + (areStepsComplete ? " stepsCompleted" : "")}>
                {parseInt((completedStepCount / steps.length) * 100)}% Complete
              </span>
            </header>
            <ReductionStrategySteps
              steps={steps}
              completionMask={rs.completed_steps_mask}
              toggleStep={(stepIdx) =>
                toggleReductionStep(rs.id, stepIdx)
              }
              disabled={rs.status === "STARTED"}
            />
          </div>}
          {
            areStepsComplete && rs.status !== "STEPS_COMPLETE" && (
              <div className="stepsCompleted">
                <CheckStar /> <span className="body5">You’ve completed the steps to implement this initiative! Click "Next" and fill in the final outcome.</span>
              </div>
            )
          }
        </div>
        <div className={"actions" + (!rs.is_in_action_plan ? " separate" : "")}>
          <div className="graphToggle">
            <label className="micro">Show in graph</label>
            <Switch disabled={!!Object.keys(rs.inputs).filter(init => !rs.inputs[init]).length} onClick={() => toggleReductionInForecast(rs.id)} checked={rs.is_in_forecast}/>
          </div>
          <div className="buttons">
            <input
              type="button"
              className="small"
              value="Close"
              onClick={() => ((!initiativeHasStarted(rs.status) && !Object.keys(rs.inputs).filter(init => !draftInputs[init]).length) || (initiativeHasStarted(rs.status) && draftIsChanged)) ? setCloseModalOpen(true) : closeEditor()}
            />
            <input
              type="button"
              className="positive small"
              value="Save"
              disabled={!!Object.keys(rs.inputs).filter(init => !draftInputs[init]).length || !draftIsChanged}
              onClick={() => handleSave()}
            />
            {areStepsComplete && intiativeIsInProgress(rs.status) && <input
              type="button"
              className="positive small"
              value="Next"
              onClick={() => updateStatus(rs.id, "STEPS_COMPLETE")}
            />}
            {rs.status === "STEPS_COMPLETE" && <input
              type="button"
              className="positive"
              value="Complete Initiative"
              onClick={() => setCompleteModalOpen(true)}
            />}
            {!rs.is_in_action_plan && <input
              type="button"
              className="positive small"
              value="Add to Action Plan"
              disabled={!!Object.keys(rs.inputs).filter(init => !draftInputs[init]).length}
              onClick={() => {handleSave(); updateActionPlan(rs.id); setTabValue(2)}}
            />}
          </div>
        </div>
      </div>
    </div>
  )
}
