import React, {useState, useEffect} from 'react'
import { interpolateViridis } from 'd3-scale-chromatic'
import returnDeviceType from '../../lib/returnDeviceType'


export default function ElectricityGridTable(props) {
    const [ deviceType , setDeviceType ] = useState()

    const gridColors = []

    for (let i = 0; i < props.electricity.length; i++) {
        gridColors.push(interpolateViridis(i / props.electricity.length))
    }

    const handleTableResize = () => {
        setDeviceType(returnDeviceType()) 
    }
  
    useEffect(() => {
      window.addEventListener('resize', handleTableResize)
      handleTableResize()
      return (() => {
        window.removeEventListener('resize', handleTableResize)
      })
    }, [])

  const gridTableRows = (numberOfTables, tableNumber) =>
   props.electricity.map((d, idx) => {
    return (
        idx >= (tableNumber * Math.ceil(props.electricity.length / numberOfTables)) && (idx < (tableNumber + 1) * Math.ceil(props.electricity.length / numberOfTables))  && 
        (
            <tr key={d.name}>
                <td>
                <span className='labelContainer'>
                <span className="keySwatch" style={{backgroundColor: gridColors[idx]}}></span>
                <span className='label'>{d.name}</span>
                </span>
                </td>
                <td>{(d.percent)}%</td>
            </tr>
      )
    )
  })



  return (
    <div className="legend-container">
        {Array(deviceType === 'tablet' ? 2 : 1).fill('0').map((i,idx) => {
            return (
                <table key={idx} className="legend">
                <thead>
                <tr>
                    <td>Type</td><td colSpan="2">Amount</td>
                </tr>
                </thead>
                <tbody>
                    {gridTableRows(deviceType === 'tablet' ? 2 : 1,idx)}
                </tbody>
            </table>
        )}) }
    </div>
  )
}

