import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../styles/Signup.scss'
import ChLogo from '../assets/ChLogo'

import GuideSection from './StyleGuide/GuideSection'
import GuideExample from './StyleGuide/GuideExample'

import LabeledField from './Forms/LabeledField'
import RadioOptionGroup from './Forms/RadioOptionGroup'
import Toggler from './Forms/Toggler'
import Selectable from './Forms/Selectable'
import { ReactComponent as ChevronRightBlack } from '../assets/ChevronRightBlack.svg'
import { ReactComponent as ChevronLeftBlack } from '../assets/ChevronLeftBlack.svg'
import Accordion from './Containers/Accordion'
import Tooltip from './Tooltip'
import IconButton from './Forms/IconButton'
import LabeledSelect from './Forms/LabeledSelect'
import CheckBox from './Forms/CheckBox'
import AddressQuestion from './Questionnaire/AddressQuestion'
import AddressPrimaryLocationQuestion from './Questionnaire/AddressPrimaryLocationQuestion'
import DemoQuestion, {questions} from './StyleGuide/DemoQuestions'
import ShortAnswerQuestion from './Questionnaire/ShortAnswerQuestion'
import SingleAnswerQuestion from './Questionnaire/SingleAnswerQuestion'
import LocationSingleInputQuestion from './Questionnaire/LocationSingleInputQuestion'
import WarehousingQuestion from './Questionnaire/WarehousingQuestion'
import Vehicles from './Questionnaire/Vehicles'
import DisposedWaste from './Questionnaire/Waste/DisposedWaste'
import OrganicDiversion from './Questionnaire/Waste/OrganicDiversion'
import SupplierSpends from './Questionnaire/BulkSuppliers/SupplierSpends'
import SupplierCategories from './Questionnaire/BulkSuppliers/SupplierCategories'
import ZeroSpendConfirmation from './Questionnaire/BulkSuppliers/ZeroSpendConfirmation'
import ShippedCategorySupplierSpends from './Questionnaire/BulkSuppliers/ShippedCategorySupplierSpends'
import ShippedZeroSpendConfirmation from './Questionnaire/BulkSuppliers/ShippedZeroSpendConfirmation'
import CategorySupplierSpends from './Questionnaire/BulkSuppliers/CategorySupplierSpends'

import UploadFileIcon from '@mui/icons-material/UploadFile'


// Generate section headers:
// https://patorjk.com/software/taag/#p=display&f=Stick%20Letters&t=Headers

export default function StyleGuide() {

  const [ toggleVal, setToggleVal ] = useState('Tons')
  const [ accordionState, setAccordionState ] = useState(false)

  return(
    <div id="styleGuide">

{/*    
      ___       __   ___  __   __  
|__| |__   /\  |  \ |__  |__) /__` 
|  | |___ /~~\ |__/ |___ |  \ .__/ 

*/}
      <GuideSection name='Headers'>
        <GuideExample name='Heading 1'>
          <h1>Header Text</h1>
        </GuideExample>

        <GuideExample name='Heading 2'>
          <h2>Header Text</h2>
        </GuideExample>

        <GuideExample name='Heading 3'>
          <h3>Header Text</h3>
        </GuideExample>

        <GuideExample name='Heading 4'>
          <h4>Header Text</h4>
        </GuideExample>

        <GuideExample name='Heading 4 (special)'>
          <h4 className='special'>Header Text</h4>
        </GuideExample>
      </GuideSection>

      {/*
 ___  __   __         __  
|__  /  \ |__)  |\/| /__` 
|    \__/ |  \  |  | .__/ 

*/}
      <GuideSection name='Forms'>
        <GuideExample name='Labeled Text Input'>
          <form>
            <LabeledField
              name='labeledInput'
              label='Labeled Input'
              formErrors={{}}
              values={{}}
              placeholder='Placeholder text'
            />
          </form>
        </GuideExample>

        <GuideExample name='Labeled Input (error state)'>
          <form>
            <LabeledField
              name='labeledInput'
              label='Labeled Input'
              formErrors={{ labeledInput: 'This field is required.' }}
              values={{}}
              placeholder='Placeholder text'
            />
          </form>
        </GuideExample>

        <GuideExample name='Radio Select - Labeled'>
          <form>
            <RadioOptionGroup
              label='Example question text which is a little long?'
              name='selectedRadio'
              values={{ selectedRadio: 'yes' }}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </form>
        </GuideExample>

        <GuideExample name='Radio Select - Inline'>
          <form>
            <RadioOptionGroup
              name='selectedRadio'
              inline
              values={{ selectedRadio: 'yes' }}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </form>
        </GuideExample>

        <GuideExample name='Radio Select - Label & Errors'>
          <form>
            <RadioOptionGroup
              name='errorRadio'
              label='Example question text which is a little long?'
              formErrors={{ errorRadio: 'Must choose value, bro' }}
              values={{}}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </form>
        </GuideExample>

        <GuideExample name='Checkbox'>
          <CheckBox
            label='Label'
            name='checkbox'
            values={{}}
          ></CheckBox>
        </GuideExample>

        <GuideExample name='Checkbox (error state)'>
          <CheckBox
            label='Label'
            name='errorCheckbox'
            values={{}}
            formErrors={{ errorCheckbox: 'This field is required' }}
          ></CheckBox>
        </GuideExample>

        <GuideExample name='Dropdown Small'>
          <form>
            <LabeledSelect
              name='labeledSelect'
              label='Label'
              options={[
                { value: '1', label: 'one' },
                { value: '2', label: 'two' },
                { value: '3', label: 'one' },
                { value: '4', label: 'two' },
                { value: '5', label: 'one' },
                { value: '6', label: 'two' },
              ]}
              className='small'
              formErrors={{}}
            />
          </form>
        </GuideExample>
        <GuideExample name='Dropdown Medium'>
          <form>
            <LabeledSelect
              name='selected'
              label='Label'
              options={[
                {
                  value: '1',
                  label: 'this is a long string that will wrap around',
                },
                { value: '2', label: 'two' },
                { value: '3', label: 'one' },
                { value: '4', label: 'two' },
                { value: '5', label: 'one' },
                { value: '6', label: 'two' },
              ]}
              formErrors={{}}
              values={{}}
            />
          </form>
        </GuideExample>
        <GuideExample name='Dropdown (error state)'>
          <form>
            <LabeledSelect
              name='labeledSelect'
              label='Label'
              options={[
                { value: '1', label: 'one' },
                { value: '2', label: 'two' },
                { value: '3', label: 'one' },
                { value: '4', label: 'two' },
                { value: '5', label: 'one' },
                { value: '6', label: 'two' },
              ]}
              formErrors={{ labeledSelect: 'This field is required.' }}
              values={{}}
            />
          </form>
        </GuideExample>
      </GuideSection>

      {/*
 __       ___ ___  __        __  
|__) |  |  |   |  /  \ |\ | /__` 
|__) \__/  |   |  \__/ | \| .__/ 

*/}
      <GuideSection name='Buttons'>
        <GuideExample name='Toggler'>
          <form>
            <Toggler currently={toggleVal} toggle={false} />
          </form>
        </GuideExample>

        <GuideExample name='Selectable'>
          <form>
            <Selectable name='sample_name' value='Sample Selectable' />
          </form>
        </GuideExample>

        <GuideExample name='Positive Button - Medium'>
          <form>
            <input className='positive' type='button' value='Next' />
            <input className='positive' disabled type='button' value='Next' />
          </form>
        </GuideExample>

        <GuideExample name='Positive Button - Small'>
          <form>
            <input className='positive small' type='button' value='Next' />
            <input
              className='positive small'
              disabled
              type='button'
              value='Next'
            />
          </form>
        </GuideExample>

        <GuideExample name='Destructive Button - Medium'>
          <form>
            <input className='destructive' type='button' value='Next' />
            <input
              className='destructive'
              disabled
              type='button'
              value='Next'
            />
          </form>
        </GuideExample>

        <GuideExample name='Gray Button'>
          <form>
            <input className='gray' type='button' value='Next' />
            <input className='gray' disabled type='button' value='Next' />
          </form>
        </GuideExample>

        <GuideExample name='Gray Button - small'>
          <form>
            <input className='gray small' type='button' value='Next' />
            <input className='gray small' disabled type='button' value='Next' />
          </form>
        </GuideExample>

        <GuideExample name='Button - Medium'>
          <form>
            <input className='medium' type='button' value='Next' />
            <input className='medium' disabled type='button' value='Next' />
          </form>
        </GuideExample>

        <GuideExample name='Button - Small'>
          <form>
            <input className='small' type='button' value='Next' />
            <input className='small' disabled type='button' value='Next' />
          </form>
        </GuideExample>

        <GuideExample name='Button - Skeleton'>
          <form>
            <input className='skeleton' type='button' value='Next' />
            <input className='skeleton' disabled type='button' value='Next' />
          </form>
        </GuideExample>

        <GuideExample name='Button - Skeleton Destuctive'>
          <form>
            <input
              className='skeleton destructive'
              type='button'
              value='Next'
            />
            <input
              className='skeleton destructive'
              disabled
              type='button'
              value='Next'
            />
          </form>
        </GuideExample>

        <GuideExample name="IconButton using a Material Icon">
          <form>
            <IconButton className="iconButton skeleton" position="solo" icon={<UploadFileIcon/>}></IconButton>
          </form>
        </GuideExample>

        <GuideExample name="IconButton / Material / No Background">
          <form>
            <IconButton className="iconButton skeleton unbacked" position="solo" icon={<UploadFileIcon/>}></IconButton>
          </form>
        </GuideExample>

        <GuideExample name="IconButton Medium">
          <form>
            <IconButton value="Icon Button" className="iconButton" position="right" icon={<ChevronRightBlack/>}></IconButton>
          </form>
        </GuideExample>
        <GuideExample name="IconButton Small">
          <form>
            <IconButton value="Icon Button" className="iconButton small" position="right" icon={<ChevronRightBlack/>}></IconButton>
          </form>
        </GuideExample>
        <GuideExample name="IconButton Medium Left">
          <form>
            <IconButton value="Icon Button" className="iconButton" position="left" icon={<ChevronLeftBlack/>}></IconButton>
          </form>
        </GuideExample>

      </GuideSection>

      {/*
 __   __       ___              ___  __   __  
/  ` /  \ |\ |  |   /\  | |\ | |__  |__) /__` 
\__, \__/ | \|  |  /~~\ | | \| |___ |  \ .__/ 
                                              
*/}


      <GuideSection name="Containers">

        <GuideExample name="Accordion - (Closed)">
          <Accordion
            isOpen={accordionState}
            hand
            header={(<div>New Accordion</div>)}
            iconPosition="left"
            onAccordionHeadingClick={() => setAccordionState(!accordionState)}>
             <div>
                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </Accordion>
        </GuideExample>

        {/* <GuideExample name="Accordion - (Closed)">
          <Accordion
            status="closed"
            header="Example Accordion Header">
          </Accordion>
        </GuideExample>

        <GuideExample name='Accordion - Open'>
          <Accordion status='open' header='Example Accordion Header'>
            <div>This is whatever content goes in it!</div>
          </Accordion>
        </GuideExample>

        <GuideExample name='Accordion - Finished'>
          <Accordion
            status='finished'
            header='Example Accordion Header'
            coverText={`This is some example cover text.\nOver a few lines.`}
          >
            <div>This is whatever content goes in it!</div>
          </Accordion>
        </GuideExample>

        <GuideExample name='Accordion - Set'>
          <div className='accordionSet'>
            <Accordion
              status='finished'
              header='Example Accordion Header'
              coverText={`This is some example cover text.\nOver a few lines.`}
            >
              <div>This is whatever content goes in it!</div>
            </Accordion>

            <Accordion status='open' header='Example Accordion Header'>
              <div>This is whatever content goes in it!</div>
            </Accordion>
            <Accordion status='closed' header='Example Accordion Header'>
              <div>This is whatever content goes in it!</div>
            </Accordion>
          </div>
        </GuideExample> */}
      </GuideSection>

            {/*
________                          __  .__                            .__                
\_____  \  __ __   ____   _______/  |_|__| ____   ____   ____ _____  |__|______   ____  
 /  / \  \|  |  \_/ __ \ /  ___/\   __\  |/  _ \ /    \ /    \\__  \ |  \_  __ \_/ __ \ 
/   \_/.  \  |  /\  ___/ \___ \  |  | |  (  <_> )   |  \   |  \/ __ \|  ||  | \/\  ___/ 
\_____\ \_/____/  \___  >____  > |__| |__|\____/|___|  /___|  (____  /__||__|    \___  >
       \__>           \/     \/                      \/     \/     \/                \/                                              
*/}

      <GuideSection name='Questionnaire Questions'>

        <GuideExample name='Warehouse Question'>
          <DemoQuestion question={questions.warehouseSquareFootage} questionComponent={WarehousingQuestion} />
        </GuideExample>

        <GuideExample name='Address Question'>
          <DemoQuestion question={questions.address} questionComponent={AddressQuestion} />
        </GuideExample>

        <GuideExample name='Address Primary Location Question'>
          <DemoQuestion question={questions.locationSelection} questionComponent={AddressPrimaryLocationQuestion} />
        </GuideExample>

        <GuideExample name='Single Answer Question (non dollar)'>
          <DemoQuestion question={questions.singleAnswerNonDollar} questionComponent={SingleAnswerQuestion} />
        </GuideExample>

        <GuideExample name='Single Answer Question (dollar)'>
          <DemoQuestion question={questions.singleAnswerDollar} questionComponent={SingleAnswerQuestion} />
        </GuideExample>

        <GuideExample name='Single Answer Question (non dollar)'>
          <DemoQuestion question={questions.locationSingleInputNondollar} questionComponent={LocationSingleInputQuestion} />
        </GuideExample>

        <GuideExample name='Single Answer Question (dollar)'>
          <DemoQuestion question={questions.locationSingleInputDollar} questionComponent={LocationSingleInputQuestion} />
        </GuideExample>

        <GuideExample name='Short Answer Question'>
        <DemoQuestion question={questions.shortAnswer} questionComponent={ShortAnswerQuestion} />
        </GuideExample>

        <GuideExample name='Vehicle Question'>
          <DemoQuestion question={questions.vehicle} questionComponent={Vehicles} />
        </GuideExample>

        <GuideExample name='Disposed Waste Question'>
          <DemoQuestion question={questions.disposedWaste} questionComponent={DisposedWaste} />
        </GuideExample>

        <GuideExample name='Diverted Waste Question'>
          <DemoQuestion question={questions.divertedWaste} questionComponent={OrganicDiversion} />
        </GuideExample>

        <GuideExample name='Shipped Bulk Purchase Question'>
          <DemoQuestion question={questions.supplierSpends} questionComponent={SupplierSpends} />
        </GuideExample>

        <GuideExample name='Shipped Category Supplier Question'>
          <DemoQuestion question={questions.categorySupplierAllocations} questionComponent={ShippedCategorySupplierSpends} />
        </GuideExample>

        <GuideExample name='Zero Spend Confirmation Question'>
          <DemoQuestion question={questions.shippedZeroSpendConfirmation} questionComponent={ShippedZeroSpendConfirmation} />
        </GuideExample>
      </GuideSection>

      <GuideSection name='Kitchen-specific Questions'>
        <GuideExample name='Supplier Question'>
          <DemoQuestion question={questions.kitchenSuppliers} questionComponent={SupplierSpends} />
        </GuideExample>
        
        <GuideExample name='Supplier Categories Question'>
          <DemoQuestion question={questions.supplierCategories} questionComponent={SupplierCategories} />
        </GuideExample>

        <GuideExample name='Category Supplier Spend Question'>
          <DemoQuestion question={questions.categorySupplierSpend} questionComponent={CategorySupplierSpends} />
        </GuideExample>

        <GuideExample name='Zero Spend Confirmation Question'>
          <DemoQuestion question={questions.zeroSpendConfirmation} questionComponent={ZeroSpendConfirmation} />
        </GuideExample>
      </GuideSection>

      {/*
         __   __  
 |\/| | /__` /  ` 
 |  | | .__/ \__, 
                                                                
*/}

      <GuideSection name='Misc'>
        <GuideExample name='Info Tooltip'>
          <Tooltip>
            This is some tooltip text. It defaults to displaying off to the
            right.
          </Tooltip>
        </GuideExample>

        <GuideExample name='Info Tooltip (left align)'>
          <div style={{ paddingLeft: '18rem' }}>
            <Tooltip align='left'>
              This is tooltip text that we assume is going to be close to the
              right side of the screen.
            </Tooltip>
          </div>
        </GuideExample>
      </GuideSection>
    </div>
  )
}
