import React from 'react'
import Select from 'react-select'
import classnames from 'classnames'

export default function Dropdown(props) {
  const errors = props.formErrors || {}
  const className = classnames("labeledSelect", props.className, {error: errors[props.name]})
  const placeholderDefault = "-- select --"

  if (props.value && props.values) {
    console.error("Please use values or value prop but not both.")
  }


  // accepts value for absolue value, or values for taking an object and a key (name)
  const value = props.values ? props.values[props.name] : props.value

  // accepts array of strings if label / value are the same, or an array of objects
  const options = isStringArray(props.options) ? stringArrayToOptions(props.options) : props.options
  const placeholder = props.placeholder ? props.placeholder : placeholderDefault

  let chosenOption = options.find(
    (option) => (option.value === value) || (option.value.toString() === value)
  ) 

  if(!chosenOption) chosenOption = ""

  function stringArrayToOptions(strAry) {
    return strAry.map((s) => ({label: s, value: s}))
  }
  function isStringArray(options) {
    return options && options.length > 0 && typeof options[0] === 'string'
  }

  return (
    <div className={className}>
      {props.label && ( <label className='selectLabel'>{props.label}</label>)}
      <Select
        options={options}
        placeholder={placeholder}
        onChange={
          (event) => {
            let eventObject = {
              target: {
                name: props.name,
                value: event ? event.value : null
              }
            }
            props.onChange(eventObject)
          }
        }
        classNamePrefix='custom-select'
        value={chosenOption}
        isOptionDisabled={props.isOptionDisabled}
        isDisabled={props.isDisabled}
        styles={props.styles}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
      />
      {errors[props.name] && (
        <div className='errorMessage'>{errors[props.name]}</div>
      )}
    </div>
  )
}
