import React from 'react'

import classnames from 'classnames'

export default function CheckBox(props) {
  const label = props.label
  const errors = props.formErrors || {}
  let className = classnames('checkBox', props.className, {error: errors[props.name], disabled: props.disabled})
  return (
    <div className={className}>
      <label className='checkBoxLabel'>
        <input
          type='checkbox'
          name={props.name}
          checked={props.values[props.name]}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <div className='labelText'>
          <span className='body3'>{label}</span>
        </div>
      </label>
      {errors[props.name] && (
        <div className='errorMessage'>{errors[props.name]}</div>
      )}
    </div>
  )
}
