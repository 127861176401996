import { useMergeLink } from "@mergeapi/react-merge-link"
import React, { useCallback, useState, useRef } from 'react'
import axios from 'axios'


const FileUploadText = (props) => {
  const fileInputRef = useRef(null);

  const handleTextClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log(`Selected file: ${file.name}`);
      // Handle the file as needed
      props.successCallback && props.successCallback()
    }
  };

  return (
    <div className="apiUploadLink">
      <span
        className="body4"
        onClick={handleTextClick}
      >
        Upload manually
      </span>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default function MergeIntegrations(props) {

  const onSuccess = useCallback((publicToken) => {
    axios.patch('/api/users/me/add_token', {token: publicToken}).then(() => {
      window.location = window.location
    })
  }, [])

  const { open, isReady } = useMergeLink({
    linkToken: props.linkToken,
    onSuccess
  })

  const onUtilitySuccess = useCallback(() => {
    axios.patch('/api/users/me', {utility_api_token: 777}).then((res) => {
      window.location = window.location
    })
  })

  let integrationCount = 0
  if (props.mergeDone) {
    integrationCount++
  }
  if (props.utilityMergeDone) {
    integrationCount++
  }

  return (
    <div className="integrationsContainer">
      <div>
        <h5>Simplify your calculation:</h5>
        <span className="body3 integrationsTitle"><h3>{integrationCount} of 2</h3><span>(optional)</span></span>
      </div>
      
      <div className={`integrationMerge ${props.mergeDone ? 'integrated' : 'notIntegrated'}`}>
        <h4>Bookkeeping</h4>
        <input type="button" className="login" value="Login" disabled={!isReady} onClick={open} />
        <input type="button" className="active positive" value="Active" disabled={!isReady} onClick={open} />
        {!props.mergeDone && <FileUploadText />}
        <p className="body4">
          Automate 40+ spend-based questions
        </p>
      </div>
      
      <div className={`integrationMerge ${props.utilityMergeDone ? 'integrated' : 'notIntegrated'}`}>
        <h4>Utilities</h4>
        <input type="button" className="login" value="Login" disabled={false} onClick={() => {}} />
        <input type="button" className="active positive" value="Active" disabled={props.utilityMergeDone} onClick={open} />
        {!props.utilityMergeDone && <FileUploadText successCallback={onUtilitySuccess} />}
        <p className="body4">
          Actively track your electricity and natural gas usage
        </p>
      </div>
    </div>
  )
}
