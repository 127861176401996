import React, {useState, useEffect} from 'react'
import LabeledSelect from '../Forms/LabeledSelect'
import LocationDetail from './LocationDetail'
import axios from 'axios'
import { useLocation } from '../../providers/LocationProvider'
import { useNavigate } from 'react-router-dom'

export default function Locations() {

    const {locations, setLocations} = useLocation()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        axios.get('/api/locations/').then(
          res => {
            setLocations(res.data)
            setLoading(false)
          }
        ).catch(
          err => console.log(err)
        )
      }, [])

    const locationSortOptions = [
        { value: 'alphabetical', label: 'Locations A-Z' },
        { value: '-alphabetical', label: 'Locations Z-A' },
        { value: 'energy', label: 'kWh/hr (ascending)' },
        { value: '-energy', label: 'kWh/hr (descending)' },
        { value: 'employees', label: 'Employee count (ascending)' },
        { value: '-employees', label: 'Employee count (descending)' },
        { value: 'commute', label: 'Average commute (ascending)' },
        { value: '-commute', label: 'Average commute (descending)' },
    ]

    const [locationSortType, setLocationSortType] = useState(locationSortOptions[0].value)

    const locationSortingAlgorithm = (a, b) => {
        const sortOrder = locationSortType.includes('-') ? -1 : 1
        let sortType = ''
        if(locationSortType.includes('alphabetical')) {
            sortType = 'name'
        } else if(locationSortType.includes('energy')) {
            sortType = 'kwh_per_sqft'
        } else if(locationSortType.includes('employee')) {
            sortType = 'employee_count'
        } else if(locationSortType.includes('commute')) {
            sortType = 'avg_commute_distance'
        }
        if (a[sortType] > b[sortType]) {
          return (sortOrder * 1)
        }
        if (a[sortType] < b[sortType]) {
          return (sortOrder * -1)
        }
        return 0
      }

    const onLocationSortTypeChange = (e) => {
        setLocationSortType(e.target.value)
    }
    
    return(
    <div className="page locations">
        <header>
            <div className="main">
                <h2>Locations</h2>
                <div className="body-3">{locations.length || ""} total</div>
            </div>
            <div className="location-sort">
                <LabeledSelect
                  name='location-sort'
                  label='Sort by'
                  onChange = {value => onLocationSortTypeChange(value)}
                  value={locationSortType}
                  options={locationSortOptions}
                  className='small'
                  formErrors={{}}
                />
            </div>
        </header>
        {locations.length ?
           locations.sort(locationSortingAlgorithm).map( loc => <LocationDetail key={loc.id} location={loc}/> ) 
          : 
          <div>
            <h3 className="noLocationsFound">
              Complete the business info section of your questionnaire to begin tracking insights per location.
              <input className='positive' onClick={() => {navigate("/questionnaire/start")}} type='button' value='Visit Business Info' />
            </h3>
          </div>
         }
    </div>
    )
}
