import React, { useState, useEffect } from 'react'

import '../styles/AppStyles.js'

import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'
import ToastProvider from '../providers/ToastProvider'

import ListItemButton from '@mui/material/ListItemButton'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as HomeIcon } from '../assets/Home.svg'
import { ReactComponent as HamburgerIcon } from '../assets/Hamburger.svg'
import { ReactComponent as InsightsIcon } from '../assets/Insights.svg'
import { ReactComponent as SusIcon } from '../assets/Sus.svg'
import { ReactComponent as PersonIcon } from '../assets/Person.svg'
import { ReactComponent as MeasureIcon } from '../assets/Measure.svg'
import { ReactComponent as BarGraphArrowIcon } from '../assets/BarGraphArrow.svg'
import { ReactComponent as LocationsIcon } from '../assets/Locations.svg'


import ChLogo from '../assets/ChLogo'
import ChWordmark from '../assets/ChWordmark'


export default function AppFrame({ children }) {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const defaultNav = [
    {
      text: "Home",
      icon: HomeIcon,
      href: '/'
    },
    // {
    //   text: "Locations",
    //   icon: LocationsIcon,
    //   href: '/locations'
    // },
    // {
    //   text: "Insights",
    //   icon: InsightsIcon,
    //   href: '/insights/details'
    // },
    // {
    //   text: "Planning",
    //   icon: BarGraphArrowIcon,
    //   href: '/emissions-planning'
    // },
    // {
    //   text: "Sustainability",
    //   icon: SusIcon,
    //   href: '/sustainability/edit'
    // },
    {
      text: "Profile",
      icon: PersonIcon,
      href: '/profile/edit'
    },
  ]

  const [ nav, setNav ] = useState(defaultNav)

  useEffect(() => {
    if (auth.userInfo) {
      if (auth.userInfo.report_available && !auth.userInfo.report_current) {
        const measureTab = { text: "Measure", icon: MeasureIcon, href: '/questionnaire/start'}
        const newNav = [...defaultNav]
        newNav.splice(1, 0, measureTab)
        setNav(newNav)
      }
    }
  }, [auth.userInfo])

  const goToNavItem = (navItem) => {
    if (navItem.text === "Insights" || navItem.text === "Sustainability" || navItem.text === "Planning") {
      if (auth.userInfo && auth.userInfo.report_available) {
        navigate(navItem.href)
      }
    } else {
      navigate(navItem.href)
    }
    setOpen(false)
  }

  return(
    <div>
      <ToastProvider>
        <div className="mobileHeader">
          <div className="hamburger" onClick={handleDrawerOpen}>
            <HamburgerIcon />
          </div>
          <div className="logo">
            <ChWordmark />
          </div>
        </div>

        <div className={`navDrawerCloseTarget ${open ? 'open' : 'notOpen'}`} onClick={handleDrawerClose}>
        </div>

        <div className={`navDrawer ${open ? 'open' : 'notOpen'}`}>
          <header onClick={() => (navigate('/'))}>
            <div className="drawerTarget closed">
              <ChLogo />
            </div>
            <div className="drawerTarget open">
              <ChWordmark />
            </div>
          </header>
          <ul>
            {nav.map((navItem, index) => {
              return (
                <li key={navItem.text} className={`${(navItem.text === 'Insights' || navItem.text === 'Sustainability' || navItem.text === 'Planning') && !(auth.userInfo && auth.userInfo.report_available) ? 'disabled' : ''}`}>
                  <ListItemButton onClick={() => {goToNavItem(navItem)}}>
                    <span className={navItem.href === window.location.pathname ? 'icon current' : 'icon'}>
                      {auth.userInfo?.supplier_review_status === "client" && navItem.text === "Measure" && <div className='noti'></div>}
                      <SvgIcon component={navItem.icon} inheritViewBox />
                    </span>
                    <span className="label">{navItem.text}</span>
                  </ListItemButton>
                </li>
              )
            })}
          </ul>
          <div className="logOut">
            <input type="button" value="Logout" className="skeleton" onClick={() => {auth.logOut(); navigate('/');}} />
          </div>
        </div>

        <div id="main">
          <Outlet />
        </div>
      </ToastProvider>
    </div>
  )
}
