import React, { useState, useEffect } from 'react'

import MiniProgress from './MiniProgress'
import { useParams, useNavigate, Link } from 'react-router-dom'
import QuestionSwitch from './QuestionSwitch'
import ViewOnDesktop from '../ViewOnDesktop'
import NavButtons from './NavButtons'


import completionClasses from '../../lib/completionClasses'

import { ReactComponent as CheckIcon } from '../../assets/SmallCheck.svg'
import { ReactComponent as ListIcon } from '../../assets/List.svg'

import axios from 'axios'
import { replaceYearDynamic } from '../../utils/years'

export default function QuestionFrame(props) {

  const [ listOpen, setListOpen ] = useState(false)

  const [ helpOpen, setHelpOpen ] = useState(false)
  const [ helpLoading, setHelpLoading ] = useState(false)

  const hasTicketId = /^\d+$/.test(`${props.question.ticket_id}`)
  const [ helpRequested, setHelpRequested ] = useState(false)

  const defaultHelp = {
    text: ''
  }
  const [ help, setHelp ] = useState(defaultHelp)

  const navigate = useNavigate()
  const { questionId } = useParams()

  let questionBody

  if (props.loading) {
    questionBody = (
      <div>
        <div className="questionInputs">
          <NavButtons
            submit={() => {}}
            question={{}}
            goPrev={() => {}}
            goNext={() => {}}
            nextEnabled={false}
          />
        </div>
      </div>)
  } else {
    questionBody = (<QuestionSwitch
      question={props.question}
      qnnSummary={props.qnnSummary}
      nextQuestion={props.nextQuestion}
      prevQuestion={props.prevQuestion}
      setListOpen={setListOpen}
      sectionQuestions={props.sectionQuestions}
    />)
  }

  useEffect(() => {
    setListOpen(false)
    setHelpOpen(false)
    setHelp(defaultHelp)
  }, [questionId])

  let sectionSummary = {}

  if (props.qnnSummary?.progress) {
    sectionSummary = Object.values(props.qnnSummary?.progress).find((p) => (p.questionnaire_section_id === props.question.questionnaire_section_id))
  }

  const openHelp = () => {
    setHelpOpen(true)
  }

  const closeHelp = () => {
    setHelpOpen(false)
  }

  const requestHelp = () => {
    console.log('helpppp')
    const payload = help
    console.log('payload', payload)
    setHelpLoading(true)
    axios.post(`/api/questions/${props.question.id}/request_help`, payload).then((res) => {
      console.log('requested!')
      console.log(res)
      setHelpLoading(false)
      setHelpRequested(true)
    })
  }

  const updateHelp = (e) => {
    const newHelp = { ...help, text: e.target.value }
    setHelp(newHelp)
  }









  // abstract - HELP copy switch



  let helpCopy = null
  if (props.question.answer_format === "bulk-purchases") {

    switch(props.question.units) {
    case 'supplier-spends':
      helpCopy = null
      break

    case 'supplier-categories':
      helpCopy = null
      break

    case 'category-supplier-spends':
      helpCopy = (
        <p>
          The best possible way to address supplier purchase categories is by exact dollars spent but this can be difficult depending on your bookkeepping.
          <br /><br />
          <b>Calculation Options:</b> We have given you the option to fill either dollars spent or to approximate percentages (by dollars spent) per category.
          <br /><br />
          <b>How to Approximate by Dollars:</b> One way to approximate your annual spend on each category is to pull a busy month of invoices per supplier. Total your spend per category, per supplier, and multiply that by the total number of months your kitchen or bar was active. In most cases, clients usually multiply by 12 for each month of the year.
          <br /><br />
          <b>How to Approximate by Percentages:</b> A method similar to above can be used. Take your monthly total per category, multiply by 12 (months), divide by your total spend with that supplier, and multiply by 100. This is your percentage per category of total spend with that supplier.
          <br /><br />
          <b>Important Note:</b> Your calculation will be as accurate as the data that is given!!
          <br /><br />
          <b>CATEGORY DETAILS:</b>
          <br /><br />
          <b>Botanicals:</b> herbs, roots, spices, barks, etc
          <br /><br />
          <b>Butter, Milk, + Dairy-Substitute:</b> this question does not include cheese unless it is non-dairy
          <br /><br />
          <b>Seafood:</b> this means all seafood, from shelfish to finfish and everything in-between.
          <br /><br />
          <b>Snack Food:</b> prepared and packaged snacks that arrive ready for sale.
          <br /><br />
          <b>Canned Sodas + Ice:</b> We're going to assume you know the canned sodas part... Ice refers to purchased ice, such as big block ice for the bar, not the ice you make on-site in your machine or freezer.
          <br /><br />
          <b>Sanitary Paper Products:</b> this means toilet paper, paper towels, napkins, etc
        </p>
      )
      break

    case 'zero-spend-confirmation':
      helpCopy = null
      break

    default:
      helpCopy = null
    }

  } else if (props.question.answer_format === "shipped-bulk-purchases") {

    switch(props.question.units) {
    case 'supplier-spends':
      helpCopy = null
      break

    case 'shipped-category-supplier-allocations':
      helpCopy = (
        <p>
          <b>Shipping Methods:</b> The more specific you can be the better. Environmental impact varies widely from one method to another. Trucking and postal services remain to be the most broadly utilized. However, when materials come from overseas there are likely cargo ships involved and, if access to this data is available, including those details would be best.
          <br /><br />
          <b>Heavy Machinery:</b> a forklift, distribution vehicle, presses, pumps, etc
          <br /><br />
          <b>Heavy-Gauge Metal Containers:</b> kegs, fermentation tanks, etc
          <br /><br />
          <b>Packaging Machinery:</b> canning line, labeling machines, etc
          <br /><br />
          <b>Wood Products:</b> barrels, foudres, pallets, etc
        </p>
      )
      break

    case 'zero-spend-confirmation':
      helpCopy = null
      break

    default:
      console.log('no bulk purchase flow for question: ', props.question)
      helpCopy = null
    }

  } else if (props.question.answer_format === "disposed-waste") {
    if (props.question.units === "landfill") {
      helpCopy = (
        <p>
          Use the provided visual aid to approximate the volume of your waste and we'll take it from there. If you have a measured weight of landfilled materials then that's even better!
          <br /><br />
          Don't worry, we are fully aware that landfilling certain materials, like branches and stumps, actually sequesters carbon! Did you know that?
          <br /><br />
          <b>Food Waste:</b> all food waste, including fruit, vegetables, and grain, unless those three items are source separated such as after the brewing, distilling, or winemaking process. If so, use one of the other content-types to be more exact.
          <br /><br />
          <b>Corrugated Containers:</b> cardboard boxes
          <br /><br />
          <b>Mixed Paper (Offices):</b> an approximated mixture of office paper, magazines, mail, newspaper, and corrugated containers.
          <br /><br />
          <b>Mixed Recyclables:</b> an approximated mixture of corrugated containers, paper, magazines, mail, lumber, glass, plastic, aluminum cans and steel cans. (Wait a minute, why are you landfilling these? Let's talk.)
        </p>
      )
    } else if (props.question.units === "recycling") {
      helpCopy = (
        <p>
          Use the provided visual aid to approximate the volume of your recycling and we'll take it from there. If you have a measured weight of recycled materials then that's even better!
          <br /><br />
          Why are there fewer content-types here? Well, not all material is created equal, some things can't really be recycled.
          <br /><br />
          <b>Corrugated Containers:</b> cardboard boxes!
          <br /><br />
          <b>Mixed Paper (Offices):</b> an approximated mixture of office paper, magazines, mail, newspaper, and corrugated containers.
          <br /><br />
          <b>Mixed Recyclables:</b> an approximated mixture of corrugated containers, paper, magazines, mail, lumber, glass, plastic, aluminum cans and steel cans.
        </p>
      )
    }
  } else if (props.question.answer_format === "diverted-waste") {
    helpCopy = (
      <p>
        Use the provided visual aid to approximate the volume of your diverted material and we'll take it from there. If you have a measured weight of diverted materials then that's even better!
        <br /><br />
        So, you CAN combust (burn) pretty much anything but in many cases you really shouldn't. For example, please don't send your plastics to an incinerator! Burning plastic is over six times worse for the environment than even just landfilling them. Recycling, or eliminating plastic from your supply chain, should always be the goal if possible.
        <br /><br />
        Only certain content-types can be composted or sent to an anaerobic digester, and even fewer can be turned into animal feed.
        <br /><br />
        <b>Food Waste:</b> all food waste, including fruit, vegetables, and grain, unless those three items are source separated such as after the brewing, distilling, or winemaking process. If so, use one of the other content-types to be more exact.
        <br /><br />
        <b>Corrugated Containers:</b> cardboard boxes
        <br /><br />
        <b>Mixed Paper (Offices):</b> an approximated mixture of office paper, magazines, mail, newspaper, and corrugated containers.
        <br /><br />
        <b>Mixed Recyclables:</b> an approximated mixture of corrugated containers, paper, magazines, mail, lumber, glass, plastic, aluminum cans and steel cans.
      </p>
    )
  } else if (props.question.answer_format === "multi-field-per-vehicle") {
    helpCopy = null

  } else {
    helpCopy = null
  }

  // abstract - HELP copy switch

  let questionnaireSectionProgress = 0;

  if(props.qnnSummary?.progress && props.question) {
    questionnaireSectionProgress = props.qnnSummary?.progress[props.question.questionnaire_section_slug] && props.qnnSummary?.progress[props.question.questionnaire_section_slug].progress
  }

  return (
    <div>
      <ViewOnDesktop />
      <div className='page questionnairePage' style={{
        borderImage: `linear-gradient(to right, #329e33 ${questionnaireSectionProgress}%, transparent 0%) 100% 1`}}
      >
        <header className="mainHeader">
          <div className="titleContainer">
            <h2>{ props.categoryHeader }</h2>
            <div className="actions">
              <input type="button" value="Help" onClick={openHelp} />
              <input type="button" value="View questions" onClick={() => setListOpen(true)} />
              <input type="button" value="Main menu" onClick={() => (navigate('/questionnaire/start'))} />
            </div>
          </div>
         {!!questionnaireSectionProgress && (<span className="body3">{Math.round(questionnaireSectionProgress)}% complete</span>)}
        </header>

        <div className={`questionBody ${props.question.answer_format} ${props.question.units}`}>
          <header>
            <h2>{ replaceYearDynamic(props.question.prompt_text.text, props.qnnSummary)}</h2>
            <div className="body2">{ replaceYearDynamic(props.question.prompt_text.body, props.qnnSummary) }</div>
          </header>
          
          { questionBody }
          
        </div>



        <div className={`modalContainer helpModal centered ${helpOpen ? 'open' : 'closed'}`}>
          <div className="grayOverlayHitzone" onClick={closeHelp}>
          </div>
          <div className={`modal ${helpLoading ? 'loading' : ''}`}>
            <div className="modalLoading">
              <div className="lds-facebook"><div></div><div></div><div></div></div>
            </div>
            {
              (hasTicketId || helpRequested) && (
                <div>
                  <header className="helpRequested">
                    <h3>Help Requested!</h3>
                    <div className="body3">We will be in touch shortly.</div>
                  </header>
                  <div className="modalActions">
                    <input type="button" onClick={closeHelp} value="Close" />
                  </div>
                </div>
              )
            }
            {
              !(hasTicketId || helpRequested) && (
                <div>
                  {
                    helpCopy && (
                      <header>
                        <h3>More info</h3>
                        <div className="body3">{helpCopy}</div>
                      </header>
                    )
                  }
                  <header>
                    <h3>Request Help</h3>
                    <div className="body3">Please provide details on how we can help in answering this question.</div>
                  </header>
                  <div className="helpFields">
                    <label>Describe your question</label>
                    <textarea
                      placeholder="Details"
                      name="text"
                      value={help.text}
                      onChange={updateHelp}
                    >
                    </textarea>
                  </div>
                  <div className="modalActions">
                    <input type="button" onClick={requestHelp} className="positive" value="Send" />
                    <input type="button" onClick={closeHelp} value="Cancel" />
                  </div>
                </div>
              )
            }
          </div>
        </div>





        <div className={`qnnList ${listOpen ? 'open' : 'closed'}`}>
          <div className="grayOverlayHitzone" onClick={() => (setListOpen(false))}>
          </div>
          <div className="list">
            <header>
              <h4>
                <ListIcon />
                Questions
              </h4>
              <p className="body4">{sectionSummary ? sectionSummary.total_in_section : '...'} questions total</p>
              <p className="body4 progress">{sectionSummary ? Math.floor(sectionSummary.progress) : '...  '}% Complete of section</p>
            </header>
            <div className="questionStatuses">
              <ul>
                {
                  props.sectionQuestions.map((q) => {
                    const completionClass = completionClasses[q.status]
                    return (
                      <li key={q.id} className={`${completionClass}`}>
                        <div className="checkIcon">
                          <CheckIcon />
                        </div>
                        <Link to={`/questionnaire/${props.qnnSummary.calculation_year}/${q.id}`}>{ replaceYearDynamic(q.name, props.qnnSummary) }</Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>

        <MiniProgress
          qnnSummary={props.qnnSummary?.progress}
        />
      </div>
    </div>
  )
}
